import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Location.css';
import defaultMosque from "../../assets/images/mosque.png";

const Location = () => {
  const [selectedAmount, setSelectedAmount] = useState("");
  const [mosqueImage, setMosqueImage] = useState(defaultMosque);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMosqueImage = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const imageUrl = "https://example.com/placeholder-mosque-image.jpg";
        const img = new Image();
        img.onload = () => setMosqueImage(imageUrl);
        img.onerror = () => console.error("Error loading mosque image");
        img.src = imageUrl;
      } catch (error) {
        console.error("Error fetching mosque image:", error);
      }
    };

    fetchMosqueImage();
  }, []);

  const handleAmountSelect = (amount) => {
    setSelectedAmount(amount);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const amountValue = selectedAmount.replace('₼', ''); 
    navigate("/add-card-donation", { state: { amount: amountValue } });
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className="mosque-selector-container">
      <div className="mosque-selecter-container-forX">
      <button className="close-button" onClick={handleClose}>
          X
        </button>
      <div className="mosque-selector">
        
        <div className="image-container">
          <img src={mosqueImage} alt="Mosque" />
        </div>
        <div className="form-container">
          <h2>Məbləğ seçin:</h2>
          <form onSubmit={handleSubmit}>
            <div className="amount-buttons">
              {["1₼", "10₼", "50₼", "100₼", "200₼", "500₼"].map((amount) => (
                <button
                  key={amount}
                  type="button"
                  className={selectedAmount === amount ? "selected" : ""}
                  onClick={() => handleAmountSelect(amount)}
                >
                  {amount}
                </button>
              ))}
            </div>
            <input
              className="input"
              type="text"
              placeholder="Məbləğ daxil edin"
              value={selectedAmount}
              onChange={(e) => setSelectedAmount(e.target.value)}
            />
            <button type="submit" className="submit-button-location">
              İANƏ ET
            </button>
          </form>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Location;
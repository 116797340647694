import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import { useContextApp } from "../context/AppContext";
import Cookies from "js-cookie";

function ProfileForm() {
  const { isLoggedIn, getUserProfile } = useContextApp();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: ""
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (isLoggedIn) {
        try {
          const userData = await getUserProfile();
          if (userData) {
            setProfileData({
              firstName: userData.firstName || "",
              lastName: userData.lastName || "",
              email: userData.email || "",
              phoneNumber: userData.phoneNumber || "",
              address: userData.address || ""
            });
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [isLoggedIn, getUserProfile, navigate]);



  const handleSave = async(e) => {
    e.preventDefault();
    const accessToken = Cookies.get("accessToken");
    const requestData = {
      firstName: profileData.firstName ,
      lastName: profileData.lastName ,
      email: profileData.email ,
      phoneNumber: profileData.phoneNumber ,
      address: profileData.address 
    };

    try {
      const response = await fetch(
        `https://api.jumma.app/api/Profile/UpdateProfile`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update profile");
      }
      const data = await response.json();
      console.log(data);
      alert("Profile updated successfully!");
    } catch (error) {
      console.log(error);
      alert("Error updating profile");
    }

  };

  return (
    <div className="p-4 rounded-md border border-[#747474]">
  <h2 className="font-bold text-lg mb-4">Profil</h2>
  <form className="space-y-4" method="post">
    <fieldset className="w-full border border-[#DFDFDF] rounded-md p-4">
      <input
        type="text"
        value={profileData.firstName}
        onChange={(e) => setProfileData({ ...profileData, firstName: e.target.value })}
        className="w-full bg-transparent outline-none text-base"
      />
      <legend className="block text-[#BABABA] text-xs">Ad</legend>
    </fieldset>

    <fieldset className="w-full border border-[#DFDFDF] rounded-md p-4">
      <input
        type="text"
        value={profileData.lastName}
        onChange={(e) => setProfileData({ ...profileData, lastName: e.target.value })}
        className="w-full bg-transparent outline-none text-base"
      />
      <legend className="block text-[#BABABA] text-xs">Soyad</legend>
    </fieldset>

    <fieldset className="w-full border border-[#DFDFDF] rounded-md p-4">
      <input
        type="email"
        value={profileData.email}
        onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
        className="w-full bg-transparent outline-none text-base"
      />
      <legend className="block text-[#BABABA] text-xs">Email</legend>
    </fieldset>

    <fieldset className="w-full border border-[#DFDFDF] rounded-md p-4">
      <input
        type="tel"
        value={profileData.phoneNumber}
        onChange={(e) => setProfileData({ ...profileData, phoneNumber: e.target.value })}
        className="w-full bg-transparent outline-none text-base"
      />
      <legend className="block text-[#BABABA] text-xs">Telefon nömrəsi</legend>
    </fieldset>

    <fieldset className="w-full border border-[#DFDFDF] rounded-md p-4">
      <input
        type="address"
        value={profileData.address}
        onChange={(e) => setProfileData({ ...profileData, address: e.target.value })}
        className="w-full bg-transparent outline-none text-base"
      />
      <legend className="block text-[#BABABA] text-xs">Ünvan</legend>
    </fieldset>

    <div className="mt-6">
      <button
        type="button"
        onClick={handleSave} 
        className="w-full bg-[#C7C7C7] text-[#898989] py-3 h-12 text-lg text-center rounded-md font-bold"
      >
        Yadda saxla
      </button>
    </div>
  </form>
</div>

  );
}

export default ProfileForm;

// Import necessary assets and components
import img from "../assets/images/boxProduct.png";
import leftarrow from "../assets/images/leftarrow.svg";
import rightarrow from "../assets/images/rightarrow.svg";
import ProductCard from "./ProductCard";
import Title from "./Title";

// Define the AllProducts component
const YouMayLike = () => {
  return (
    <div className="flex flex-col mx-auto mt-[82px]">
      {/* Title with underline */}
      <Title
        name={
          <span style={{ position: "relative" }}>
            Bunları bəyənə bilərsiniz
            <span
              style={{
                position: "absolute",
                bottom: "-15px",
                left: 0,
                width: "285px",
                height: "3px",
                backgroundColor: "#2aae21",
              }}
            />
          </span>
        }
      />

      <div className="relative flex justify-between w-full mt-[29px] mb-[41px] ">
        {/* ARROWS */}
        <div className="absolute z-30 flex justify-between w-full top-1/2 transform -translate-y-1/2 ">
          <div className=" bg-[#D2D2D2] size-[31px] rounded-full border  border-[#A1A1A1] flex justify-center items-center">
            <div className="size-[18px] ">
              <img src={leftarrow} alt="left arrow" className="w-full h-full" />
            </div>
          </div>

          <div className=" bg-[#D2D2D2] size-[31px] rounded-full border  border-[#A1A1A1] flex justify-center items-center">
            <div className="size-[18px] ">
              <img
                src={rightarrow}
                alt="left arrow"
                className="w-full h-full"
              />
            </div>
          </div>
        </div>

        {/* PRODUCTS GRID */}
        <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 ">
          <ProductCard
            title="Gülmarka Natural Rose water 250 ML"
            price="17 ₼"
            imgSrc={img}
          />
          <ProductCard
            title="Gülmarka Natural Rose water 250 ML"
            price="17 ₼"
            imgSrc={img}
          />
          <ProductCard
            title="Gülmarka Natural Rose water 250 ML"
            price="17 ₼"
            imgSrc={img}
          />
          <ProductCard
            title="Gülmarka Natural Rose water 250 ML"
            price="17 ₼"
            imgSrc={img}
          />
        </div>
      </div>
    </div>
  );
};

export default YouMayLike;

import { createContext, useContext, useEffect, useState } from "react";
import { notification } from "../utils/helper";
import Cookies from "js-cookie";
const AdminContext = createContext();

const reviews = [
  {
    id: 1,
    email: "mansar.hasanadegh@gmail.com",
    date: "Dec 15 2024",
    rating: 5,
    review: "I liked it, super nice and worth its price",
    likes: 10,
  },
  {
    id: 2,
    email: "john.doe@example.com",
    date: "Dec 14 2024",
    rating: 4,
    review: "Good quality but a bit pricey.",
    likes: 5,
  },
  {
    id: 3,
    email: "jane.smith@example.com",
    date: "Dec 13 2024",
    rating: 3,
    review: "It was okay, nothing special.",
    likes: 8,
  },
  {
    id: 4,
    email: "alice.jones@example.com",
    date: "Dec 12 2024",
    rating: 5,
    review: "Excellent product, highly recommended!",
    likes: 12,
  },
  {
    id: 5,
    email: "bob.brown@example.com",
    date: "Dec 11 2024",
    rating: 2,
    review: "Not what I expected, quite disappointed.",
    likes: 3,
  },
  {
    id: 6,
    email: "carol.white@example.com",
    date: "Dec 10 2024",
    rating: 4,
    review: "Pretty good, but there is room for improvement.",
    likes: 7,
  },
  {
    id: 7,
    email: "david.black@example.com",
    date: "Dec 09 2024",
    rating: 3,
    review: "Average quality, decent value for money.",
    likes: 6,
  },
  {
    id: 8,
    email: "emily.green@example.com",
    date: "Dec 08 2024",
    rating: 5,
    review: "Fantastic! Will buy again.",
    likes: 15,
  },
];

const orders = [
  {
    id: "111111",
    customerName: "Jane Cooper",
    customerEmail: "janewinning@gmail.com",
    customerPhone: "+994 50 111 11 11",
    items: [
      {
        name: "Product 1",
        price: "10",
        discountedPrice: "8",
        originalPrice: "10",
      },
      {
        name: "Product 2",
        price: "7",
        discountedPrice: "5",
        originalPrice: "7",
      },
    ],
    totalPrice: "17 ₴",
    date: "12 Jan 2024",
    status: "Completed",
    price: "17 ₴",
  },
  {
    id: "222222",
    customerName: "John Doe",
    customerEmail: "johndoe@example.com",
    customerPhone: "+994 50 222 22 22",
    items: [
      {
        name: "Product A",
        price: "15",
        quantity: 8,
        discountedPrice: "12",
        originalPrice: "15",
      },
      {
        name: "Product B",
        price: "10",
        quantity: 2,
        discountedPrice: "8",
        originalPrice: "10",
      },
    ],
    totalPrice: "25 ₴",
    date: "13 Jan 2024",
    status: "Processing",
    price: "25 ₴",
  },
  {
    id: "333333",
    customerName: "Emily Clark",
    customerEmail: "emilyclark@example.com",
    customerPhone: "+994 50 333 33 33",
    items: [
      {
        name: "Product X",
        price: "5",
        quantity: 6,
        discountedPrice: "4",
        originalPrice: "5",
      },
      {
        name: "Product Y",
        price: "5",
        quantity: 4,
        discountedPrice: "4",
        originalPrice: "5",
      },
    ],
    totalPrice: "10 ₴",
    date: "14 Jan 2024",
    status: "New",
    price: "10 ₴",
  },
  {
    id: "444444",
    customerName: "Michael Brown",
    customerEmail: "michaelbrown@example.com",
    customerPhone: "+994 50 444 44 44",
    items: [
      {
        name: "Product 5",
        price: "20",
        quantity: 2,
        discountedPrice: "18",
        originalPrice: "20",
      },
      {
        name: "Product 6",
        price: "10",
        quantity: 6,
        discountedPrice: "8",
        originalPrice: "10",
      },
    ],
    totalPrice: "30 ₴",
    date: "15 Jan 2024",
    status: "Preparing",
    price: "30 ₴",
  },
  {
    id: "555555",
    customerName: "Sarah Johnson",
    customerEmail: "sarahjohnson@example.com",
    customerPhone: "+994 50 555 55 55",
    items: [
      {
        name: "Product 7",
        price: "5",
        quantity: 5,
        discountedPrice: "4",
        originalPrice: "5",
      },
    ],
    totalPrice: "5 ₴",
    date: "16 Jan 2024",
    status: "Delivered",
    price: "5 ₴",
  },
  {
    id: "555557",
    customerName: "Sarah Johnson",
    customerEmail: "sarahjohnson@example.com",
    customerPhone: "+994 50 555 55 55",
    items: [
      {
        name: "Product 7",
        price: "5",
        quantity: 3,
        discountedPrice: "4",
        originalPrice: "5",
      },
    ],
    totalPrice: "5 ₴",
    date: "16 Jan 2024",
    status: "Delivered",
    price: "5 ₴",
  },
];

export const AdminContextProvider = ({ children }) => {
  const [adminProducts, setAdminProducts] = useState([]);
  const [productCategory, setProductCategory] = useState("");
  // const [subCategory, setSubCategory] = useState("");
  const [Name, setName] = useState("");
  const [Description, setDescription] = useState("");
  const [Price, setPrice] = useState("");
  const [Width, setWidth] = useState("");
  const [Height, setHeight] = useState("");
  const [Weight, setWeight] = useState("");
  const [StockCount, setStockCount] = useState("");
  const [FormFile, setFormFile] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [SubCategoryId, setSubCategoryId] = useState("");
  // const [adminReviews, setAdminReviews] = useState(reviews);
  const [adminReviews] = useState(reviews);
  const [adminOrders, setAdminOrders] = useState(orders);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState("");
  function searchFilterProducts() {
    const newProducts = Array.isArray(adminProducts?.products)
    ? adminProducts.products
    : [];

    return newProducts.filter((item) => 
      item.name.toLowerCase().includes(query.toLowerCase())
    );
  }
  useEffect(() => {
    if (selectedCategory) {
      fetchSubCategories(selectedCategory);
    }
  }, [selectedCategory]);

  // FETCH ALL CATEGORIES
  const fetchCategories = async () => {
    const accessToken = Cookies.get("accessToken");
    console.log("Fetching categories with token:", accessToken);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        "https://api.jumma.app/api/Product/AllCategories",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched categories:", data);
        setCategories(data.activeCategories);
      } else {
        console.error("Failed to fetch categories");
        setError("Failed to fetch categories");
      }
    } catch (error) {
      console.error("An error occurred while fetching categories:", error);
      setError("An error occurred while fetching categories");
    } finally {
      setIsLoading(false);
    }
  };

  // FETCH SUBCATEGORIES
  const fetchSubCategories = async (categoryId) => {
    const accessToken = Cookies.get("accessToken");
    console.log("Fetching subcategories for category:", categoryId);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://api.jumma.app/api/Product/Category/${categoryId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched subcategories:", data);
        setSubCategories(data.subCategories);
      } else {
        console.error("Failed to fetch subcategories");
        setError("Failed to fetch subcategories");
      }
    } catch (error) {
      console.error("An error occurred while fetching subcategories:", error);
      setError("An error occurred while fetching subcategories");
    } finally {
      setIsLoading(false);
    }
  };

  // POST METHOD
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Name", Name);
    formData.append("Description", Description);
    formData.append("Price", Price);
    formData.append("SubCategoryId", SubCategoryId);
    formData.append("Width", Width);
    formData.append("Height", Height);
    formData.append("Weight", Weight);
    formData.append("StockCount", StockCount);
    if (FormFile) {
      formData.append("FormFile", FormFile);
    }

    const accessToken = Cookies.get("accessToken");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://api.jumma.app/api/Product`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Added product:", data);
        setAdminProducts((prev) => {
          if (Array.isArray(prev)) {
            return [...prev, data];
          } else {
            console.warn("adminProducts is not an array, initializing it");
            return [data];
          }
        });
        fetchAllProducts();
        notification("Product added successfully");
        // Reset form fields
        setName("");
        setFormFile(null);
        setSubCategoryId("");
        setDescription("");
        setPrice("");
        setWidth("");
        setHeight("");
        setWeight("");
        setStockCount("");
      } else {
        alert("Məhsul əlavə etməzdən qabaq hesabınıza ünvan əlavə etməlisiniz.")
        const errorData = await response.json();
        console.error("Failed to add product:", errorData);
        setError("Failed to add product: " + JSON.stringify(errorData));
      }
    } catch (error) {
      console.error("An error occurred while adding product:", error);
      setError("An error occurred while adding product: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  //  GET METHOD
  const fetchAllProducts = async () => {
    const accessToken = Cookies.get("accessToken");
    console.log("Fetching all products with token:", accessToken);
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://api.jumma.app/api/Product/AllProducts`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      console.log("Response status:", response.status);
      if (response.ok) {
        const data = await response.json();
        console.log("Fetched products:", data);
        setAdminProducts(data);
      } else {
        console.error("Failed to fetch products");
        setError("Failed to fetch products");
      }
    } catch (error) {
      console.error("An error occurred while fetching products:", error);
      setError("An error occurred while fetching products");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllProducts();
    fetchCategories();
  }, []);

  // DELETE METHOD
  const handleDeleteProduct = async (id) => {
    console.log("Attempting to delete product with id:", id);
    const products = Array.isArray(adminProducts?.products)
      ? adminProducts.products
      : [];
    const product = products.find((product) => product.subCategoryId === id);

    if (product) {
      console.log("Product found:", product);
      setIsLoading(true);
      setError(null);
      try {
        const accessToken = Cookies.get("accessToken");
        console.log("Access token:", accessToken);
        const response = await fetch(
          `https://api.jumma.app/api/Product/${id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        console.log("API response status:", response.status);
        if (response.ok) {
          setAdminProducts((prevState) => ({
            ...prevState,
            products: prevState.products.filter((p) => p.subCategoryId !== id),
          }));
          notification(`${product.name} adlı məhsul silindi`);
          console.log("Product deleted successfully");
          return { success: true, message: "Product deleted successfully" };
        } else if (response.status === 404) {
          console.error("Product not found on the server");
          setError("Product not found on the server");
          // Remove the product from the local state if it doesn't exist on the server
          setAdminProducts((prevState) => ({
            ...prevState,
            products: prevState.products.filter((p) => p.subCategoryId !== id),
          }));
          return { success: false, message: "Product not found on the server" };
        } else {
          console.error("Failed to delete product");
          setError("Failed to delete product");
          return { success: false, message: "Failed to delete product" };
        }
      } catch (error) {
        console.error("An error occurred while deleting product:", error);
        setError("An error occurred while deleting product");
        return {
          success: false,
          message: "An error occurred while deleting product",
        };
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log("Product not found in local state");
      notification("Məhsul tapılmadı.");
      return { success: false, message: "Product not found in local state" };
    }
  };
  // PUT METHOD
  const handleSaveProduct = async (updatedProduct) => {
    const accessToken = Cookies.get("accessToken");
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `https://api.jumma.app/api/Product/${updatedProduct.subCategoryId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(updatedProduct),
        }
      );

      if (response.ok) {
        setAdminProducts((prevState) => ({
          ...prevState,
          products: prevState.products.map((product) =>
            product.subCategoryId === updatedProduct.subCategoryId
              ? updatedProduct
              : product
          ),
        }));
        notification(`${updatedProduct.name} adlı məhsul yeniləndi`);
      } else {
        console.error("Failed to update product");
        setError("Failed to update product");
      }
    } catch (error) {
      console.error("An error occurred while updating product:", error);
      setError("An error occurred while updating product");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteOrder = (orderId) => {
    const updatedOrders = adminOrders.filter((order) => order.id !== orderId);
    setAdminOrders(updatedOrders);
  };

  const values = {
    handleSubmit,
    adminProducts,
    setAdminProducts,
    Name,
    setName,
    Description,
    setDescription,
    Price,
    setPrice,
    StockCount,
    setStockCount,
    Width,
    setWidth,
    Height,
    setHeight,
    Weight,
    setWeight,
    FormFile,
    setFormFile,
    productCategory,
    setProductCategory,
    SubCategoryId,
    setSubCategoryId,
    handleDeleteProduct,
    adminReviews,
    adminOrders,
    categories,
    subCategories,
    handleDeleteOrder,
    handleSaveProduct,
    selectedCategory,
    setSelectedCategory,
    isLoading,
    error,
    fetchAllProducts,
    query,
    setQuery,
    searchFilterProducts,
    fetchSubCategories
  };

  return (
    <AdminContext.Provider value={values}>{children}</AdminContext.Provider>
  );
};

export default AdminContext;

export const useContextAdmin = () => useContext(AdminContext);

import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

function UserProfileForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    const accessToken = Cookies.get('accessToken');
    try {
      const response = await fetch('https://api.jumma.app/api/Profile/GetProfile', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Accept': '*/*',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch profile: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      setFormData({
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        email: data.email || '',
        phoneNumber: data.phoneNumber || '',
        address: data.address || '',
      });
    } catch (error) {
      console.error('Error fetching profile:', error);
      setErrorMessage(`Error fetching profile: ${error.message}`);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage(''); // Reset success message
    const accessToken = Cookies.get('accessToken');
    const requestData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      address: formData.address,
    };

    try {
      const response = await fetch(
        'https://api.jumma.app/api/Profile/UpdateProfile',
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      const responseText = await response.text();
      let responseData;
      try {
        responseData = JSON.parse(responseText);
      } catch (e) {
        // If response is not JSON, use the text as is
        responseData = responseText;
      }

      if (!response.ok) {
        throw new Error(`Failed to update profile: ${response.status} ${response.statusText}\n${responseData}`);
      }

      console.log('Profile updated successfully:', responseData);
      setSuccessMessage('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      setErrorMessage(`Error updating profile: ${error.message}`);
    }
  };

  return (
    <div className="pt-[16px] pb-[31px] px-[34px] rounded-md border border-[#747474]">
      <h2 className="font-bold text-lg mb-[12px]">Profili</h2>

      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}

      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Success: </strong>
          <span className="block sm:inline">{successMessage}</span>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-1" method="post">
        <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px]">
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="bg-transparent outline-none w-full"
          />
          <legend className="block text-[#BABABA] text-[11px]">Ad</legend>
        </fieldset>

        <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px]">
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="bg-transparent outline-none w-full"
          />
          <legend className="block text-[#BABABA] text-[11px]">Soyad</legend>
        </fieldset>

        <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px]">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="bg-transparent outline-none w-full"
          />
          <legend className="block text-[#BABABA] text-[11px]">Email</legend>
        </fieldset>

        <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px]">
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="bg-transparent outline-none w-full"
          />
          <legend className="block text-[#BABABA] text-[11px]">Nömrə</legend>
        </fieldset>

        <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px]">
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="bg-transparent outline-none w-full"
          />
          <legend className="block text-[#BABABA] text-[11px]">Adres</legend>
        </fieldset>

        <div className="mt-[19px]">
          <button
            type="submit"
            className="w-full bg-[#C7C7C7] text-[#898989] py-2 h-[52px] mt-[19px] text-[20px] text-center rounded-md font-bold"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default UserProfileForm;

import React from "react";
import Login from "../../components/Login";

const LoginPage = () => {
  return (
    <div className="w-full max-w-screen-xl mt-4 space-y-8">
      <Login></Login>

    </div>
  );
};

export default LoginPage;

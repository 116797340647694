import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

function ProductReviews() {
  const { productId } = useParams();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('ProductReviews component mounted');
    console.log('Current productId:', productId);

    const fetchReviews = async () => {
      try {
        console.log('Fetching reviews for productId:', productId);
        const accessToken = Cookies.get('accessToken');
        if (!accessToken) {
          throw new Error('No access token found');
        }
        console.log('Access token found:', accessToken.substring(0, 10) + '...');

        const response = await fetch(`https://api.jumma.app/api/Review/Product/${productId}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        console.log('API response status:', response.status);
        if (!response.ok) {
          throw new Error('Failed to fetch product reviews');
        }

        const data = await response.json();
        console.log('Received reviews data:', JSON.stringify(data, null, 2));

        if (Array.isArray(data.reviews)) {
          console.log('Setting reviews state with:', data.reviews);
          setReviews(data.reviews);
        } else if (Array.isArray(data)) {
          console.log('Setting reviews state with:', data);
          setReviews(data);
        } else {
          console.error('Unexpected data structure:', data);
          setError('Unexpected data structure received from the server');
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchReviews();
  }, [productId]);

  console.log('Current component state:', { loading, error, reviews });

  if (loading) {
    return <div className="text-center py-4">Loading reviews...</div>;
  }

  if (error) {
    return <div className="text-center py-4 text-red-500">Error: {error}</div>;
  }

  return (
    <div className=" w-[371px] h-[100px]  px-4 py-8 ">
      {reviews.length > 0 ? (
        <div className="space-y-4">
          {reviews.map((review, index) => (
            <div key={index} className="flex flex-start flex-col border border-[#DFDFDF] rounded-md p-[20px]">
            <div style={{display:"flex",width:"271px", gap:"20px", justifyContent:"center",alignItems:"center"}}>
              <p className="font-semibold ">{review.email}</p>
              <p className="text-sm text-gray-500 mt-2">
                {new Date(review.createdAt).toLocaleDateString()}
              </p>
            </div>
              <div className="flex items-center mb-2 h-[17px]  ">
                <span className="text-[#FF0000]  mr-1">
                  {'★'.repeat(review.rating)}
                </span>
                <span className="text-gray-400 ">
                  {'☆'.repeat(5 - review.rating)}
                </span>
              </div>
              <p className="text-gray-700">{review.content}</p>
              
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center py-4">No reviews yet for this product.</p>
      )}
    </div>
  );
}

export default ProductReviews;
import React, { useState } from "react";
import Cookies from "js-cookie";

function ChangePasswordForm() {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    reNewPassword: ""
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (formData.newPassword !== formData.reNewPassword) {
      setError("New passwords do not match");
      return;
    }

    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      setError("No access token found. Please log in again.");
      return;
    }

    const requestData = {
      currentPassword: formData.currentPassword,
      newPassword: formData.newPassword,
      reNewPassword: formData.reNewPassword,
    };

    try {
      console.log("Sending request to update password...");
      const response = await fetch(
        `https://api.jumma.app/api/Profile/UpdatePassword`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      console.log("Response status:", response.status);
      console.log("Response headers:", response.headers);

      const responseText = await response.text();
      console.log("Response text:", responseText);

      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        console.error("Response is not valid JSON:", responseText);
        data = responseText;
      }

      if (!response.ok) {
        throw new Error(data.message || data || "Failed to update password");
      }

      console.log("Password update successful:", data);
      setSuccess("Password updated successfully!");
      setFormData({ currentPassword: "", newPassword: "", reNewPassword: "" });
    } catch (error) {
      console.error("Error updating password:", error);
      setError(error.message || "Error updating password");
    }
  };

  return (
    <div className="pt-[16px] pb-[31px] p-4 rounded-md border border-[#747474] flex flex-col">
      <h2 className="font-bold text-lg mb-[19px]">Parol Deyis</h2>
      {error && <p className="text-red-500 mb-2">{error}</p>}
      {success && <p className="bg-green-100 text-green-700 border border-green-500 rounded-md px-4 py-3 mb-4">{success}</p>}
      <form onSubmit={handleSubmit} className="space-y-4 flex flex-col flex-grow">
        <div className="border border-[#DFDFDF] rounded-md">
          <input
            type="password"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleChange}
            placeholder="Current password"
            className="w-full bg-transparent outline-none pl-[21px] py-[15px]"
          />
        </div>
        <div className="border border-[#DFDFDF] rounded-md">
          <input
            type="password"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            placeholder="New password"
            className="w-full bg-transparent outline-none pl-[21px] py-[15px]"
          />
        </div>
        <div className="border border-[#DFDFDF] rounded-md">
          <input
            type="password"
            name="reNewPassword"
            value={formData.reNewPassword}
            onChange={handleChange}
            placeholder="Re-type password"
            className="w-full bg-transparent outline-none pl-[21px] py-[15px]"
          />
        </div>
        <div className="flex flex-end justify-end">
          <button
            type="submit"
            className="w-full rounded-md h-[52px] font-semibold bg-[#C7C7C7] text-[#898989] py-2"
          >
            Change password
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChangePasswordForm;
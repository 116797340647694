import React from 'react';
import MainAdProfileForm from '../MainAdProfileForm';
import MainChangePassword from '../MainChangePassword';

export default function MainAdminHesab() {
  return (
    <div className="flex flex-col px-5 md:px-10 justify-center bg-gray-100" >
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 ">
        <MainAdProfileForm />
        <MainChangePassword />
      </div>
    </div>
  );
}

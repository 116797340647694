const Title = ({ name }) => {
  return (
    <div className="border-b w-[675px] mb-8 border-[#BEBEBE] ">
      <p className="font-bold    text-[#05AC58] text-[32px] mb-[8px] ">
        {name}
      </p>
    </div>
  );
};

export default Title;

import { useEffect, useRef, useState } from "react";

const Agreement = () => {
  const [isOpen, setIsOpen] = useState(true);
  const popupRef = useRef(null);

  // Function to close the popup
  const closePopup = () => {
    setIsOpen(false);
  };

  // Close popup if clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    // New function to handle Esc key press
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        closePopup();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
      document.addEventListener("keydown", handleEscKey); // Add event listener for keydown
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscKey); // Remove event listener on cleanup
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 px-4">
      <div
        ref={popupRef}
        className="relative bg-white shadow-lg max-w-[982px] w-full p-6 overflow-y-auto h-[600px]"
      >
        <button
          onClick={closePopup}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 text-3xl"
          aria-label="Close"
        >
          &times;
        </button>
        <h2 className="text-2xl font-bold text-[#05AC58] mb-4 pl-4">
        ÜZVLÜK MÜQAVİLƏSİ
        </h2>
        <div className=" p-4  mb-4">
          <p className="text-sm text-[#0C0404] mb-4">
                      Zəhmət olmasa, bizim saytımızdan istifadə etməzdən əvvəl bu 'saytın istifadə şərtlərini' diqqətlə oxuyun
            sayt. Bu alış-veriş saytından istifadə edən və alış-veriş edən müştərilərimizdir
            aşağıdakı şərtləri qəbul etdiyi güman edilir: Bizim veb-səhifələr
            sayt və onunla əlaqəli bütün səhifələr (“sayt”) məxsusdur və idarə olunur
            tərəfindən ……………………………….şirkət (“Şirkət”) ………………………. Siz ('İstifadəçi')
            Saytda təklif olunan bütün xidmətlərdən istifadə etmək, sizə tabedir
            aşağıdakı şərtlərdən faydalanaraq və istifadə etməyə davam edir
            saytda xidmət; Siz haqqa, səlahiyyətə və səlahiyyətə malik hesab edilirsiniz
            qanunlara uyğun olaraq müqavilə imzalamaq hüquq qabiliyyəti
            18 yaşdan yuxarı olduğunuza və sizdə olanlara bağlıdır
            bu müqaviləni oxuyub başa düşmüsünüz və bununla bağlısınız
            müqavilədə yazılmış şərtlər.<br></br> <br></br> Bu müqavilədir
            müddətsizdir və tərəflərə hüquq və vəzifələr qoyur
            müqavilənin mövzusu olan saytla bağlı və tərəflər nə vaxt
            bu müqaviləni onlayn və ya yazılı şəkildə qəbul edir/təsdiq edir, bildirirlər və
            qeyd olunan hüquqları yerinə yetirəcəklərini və
            öhdəlikləri tam, dəqiq, vaxtında və daxilində
            bu müqavilədə tələb olunan şərtlər.{" "}
                      </p>
                      <p className="text-sm text-[#0C0404]">
                      Bu müqavilə müddətsizdir və üzərinə hüquq və öhdəliklər qoyur
            Müqavilənin mövzusu olan saytla əlaqədar tərəflər və nə vaxt
            tərəflər bu müqaviləni onlayn və ya yazılı şəkildə qəbul edərlər/təsdiq edərlər
            qeyd olunan hüquqları yerinə yetirəcəklərini bəyan edir və öhdələrinə götürürlər
            və öhdəlikləri tam, dəqiq, vaxtında və daxilində
            bu müqavilədə tələb olunan şərtlər. <br></br>
                        <br></br> 1. MƏSULİYYƏTLƏR <br></br>
                        <br></br>Şirkət həmişə dəyişikliklər etmək hüququnu özündə saxlayır
            qiymətlər və təklif olunan məhsul və xidmətlər. Şirkət qəbul edir
            və üzvün xidmətlərdən faydalanacağını öhdəsinə götürür
            texniki nasazlıqlar istisna olmaqla, müqavilə əsasında. İstifadəçi
            istifadəsini tərsinə çevirməyəcəyini əvvəlcədən qəbul edir
            sayta daxil olun və ya mənbə kodunu tapmaq və ya əldə etmək üçün hər hansı digər hərəkətə keçin
            sayt, əks halda ola biləcək hər hansı zərərə görə məsuliyyət daşıyacaq
            üçüncü şəxslərə baş verərsə, qanuni və cinayət işi açılacaqdır
            ona qarşı alındı. İstifadəçi yalnız olacağını qəbul edir
            natamam və ya səbəb ola biləcəyi hər hansı ziyana görə məsuliyyət daşıyır
            sayta üzv olarkən verilən yanlış məlumatlar və
            ki, yanlış məlumat verildikdə və olduqda
            Üzv tərəfindən bu müqavilənin pozulması, şirkət ola bilər
            heç bir ehtiyac olmadan üzvlüyünə birtərəfli qaydada xitam verir
            xəbərdarlıq və ya xəbərdarlıq. Adı və İnternet Protokolu (IP) ünvanı
            şirkətin sayta daxil olmaq üçün istifadə etdiyi İnternet xidmət provayderi
            vebsaytın təkmilləşdirilməsi və inkişafı üçün və/və ya daxilində
            hüquqi qanunvericilik çərçivəsi, saytın tarixi və vaxtı idi
            giriş, saytda olarkən daxil olan səhifələr və İnternet
            sayta birbaşa qoşulmağa imkan verən veb-sayt bağlantısı.
            Ünvan kimi bəzi məlumatlar toplana bilər. İstifadəçi razılaşır
            bu məlumatların toplanması üçün. İstifadəçi istehsal etməməlidir
            ümumi əxlaqa və gözəl əxlaqa zidd olan məzmun,
            qeyri-qanuni, üçüncü şəxslərin hüquqlarına zərər vuran, aldadıcı, təhqiredici,
            ədəbsiz, pornoqrafik, şəxsi hüquqlara zərər vurur, müəllif hüquqlarını pozur,
            və ya sayt daxilində fəaliyyətində qeyri-qanuni fəaliyyətləri təşviq edən,
            saytın istənilən hissəsində və ya onun kommunikasiyalarında. , razılaşır ki, o
            paylaşmayacaq. Əks halda, o, tamamilə məsuliyyət daşıyır
            meydana gələ biləcək zərər və bu vəziyyətdə 'Sayt' səlahiyyətliləri ola bilər
            belə hesabları dayandırmaq və ya ləğv etmək və başlamaq hüququnu özündə saxlayır
            hüquqi prosedurlar. Bu səbəblə məhkəmə orqanları tələb edərsə
            fəaliyyətləri və ya istifadəçi hesabları ilə bağlı məlumatları özündə saxlayır
            bu məlumatı səlahiyyətlilərlə bölüşmək hüququ. üzvləri
            sayt bir-biri ilə və ya olan münasibətlərinə görə məsuliyyət daşıyır
            üçüncü şəxslər.<br></br>
                        <br></br> 2. ƏQLİ MÜLKİYYƏT HÜQUQLARI <br></br>
                        <br></br> 2.1. Bütün qeydiyyatdan keçmiş və ya qeydiyyatdan keçməmiş əqli mülkiyyət
            başlıq, biznes adı, ticarət nişanı, patent, loqo,
            Bu Saytda yer alan dizayn, məlumat və metod
            sayt operatoru və sahibi şirkət və ya göstərilən şəxs və var
            milli və beynəlxalq hüququn mühafizəsi altında. Ziyarət
            bu Sayt və ya bu Saytdakı xidmətlərdən istifadə sizə heç bir şey vermir
            bu əqli mülkiyyət hüquqları ilə bağlı hüquqlar. 2.2. The
            saytdakı məlumatların təkrar istehsalı, dərc edilməsi, kopyalanması,
            hər hansı bir şəkildə təqdim edilmiş və/və ya köçürülmüşdür. Tam və ya bir hissəsi
            sayt icazəsiz başqa internet saytında istifadə edilə bilməz. halda
            belə bir pozuntunun ört-basdır edilməsindən istifadəçi məsuliyyət daşıyacaq
            dəymiş ziyana görə şirkətdən tələb olunan təzminat məbləği
            üçüncü tərəflər və bütün digər öhdəliklər, o cümlədən, lakin
            məhkəmə xərcləri və vəkil haqqı ilə məhdudlaşmır.<br></br>
                        <br></br> 3. MƏXfi MƏLUMAT <br></br>
                        <br></br> 3.1. Şirkət şəxsi məlumatları açıqlamayacaq
            istifadəçilər tərəfindən sayt vasitəsilə üçüncü şəxslərə ötürülür. Bu
            şəxsi məlumat; Bu, hər cür digər məlumatları ehtiva edir
            Şəxsin adı-soyadı kimi İstifadəçini müəyyən etmək üçün nəzərdə tutulmuşdur.
            ünvanı, telefon nömrəsi, cib telefonu, e-poçt ünvanı və olacaq
            qısaca “Məxfi Məlumat” adlandırılır. 3.2. İstifadəçi,
            tanıtım, reklam, kampaniya, tanıtım, elan və s.. O
            sahibi olan şirkətə razılıq verdiyini qəbul edir və bildirir
            Sayt onun əlaqə, portfel statusu və demoqrafik məlumatlarını paylaşır
            onun filialları və ya törəmə qrup şirkətləri ilə bağlı məlumatlar və
            özü və ya onun üçün bu kontekstdə elektron mesajlar almaq
            filialları, marketinq çərçivəsində istifadəsi ilə məhdudlaşır
            fəaliyyətləri. Bu şəxsi məlumat şirkət daxilində istifadə edilə bilər
            müştəri profilini müəyyən etmək, promosyonlar və kampaniyalar təklif etmək
            müştəri profilinə uyğun və statistik məlumatların aparılması
            təhsil alır. 3.3. İstifadəçinin verdiyi razılığı ləğv etmək hüququ vardır
            heç bir səbəb izah etmədən bu razılaşma. Şirkət emal edir
            dərhal ləğv edir və istifadəçinin qəbul etməsinə mane olur
            elektron mesajlar 3 (üç) iş günü ərzində. 3.4.
            Məxfi məlumat yalnız rəsmi şəxslərə açıqlana bilər
            bu məlumat vəzifəli şəxs tərəfindən tələb olunarsa, səlahiyyətli orqanlar
            səlahiyyətli orqanlara vaxtında və açıqlandığı hallarda
            Rəsmi səlahiyyətlilərə uyğun olaraq məcburidir
            qüvvədə olan qanunvericilik.<br></br>
                        <br></br> 4. ZƏMANƏT YOXDUR:<br></br>
                        <br></br> BU MÜQAVİLƏNİN MADDƏSİ MAKSİMUM MÜDDƏTİNƏ QƏDƏRDİR
            MÜVARİCİ QANUNUN İCAZƏ VERİLƏN HƏRÇƏLƏR. TARAFINDAN TƏKLİF EDİLƏN XİDMƏTLƏR
            ŞİRKƏT "OLDUĞU KİMİ" VƏ "OLDUĞU KİMİ" ƏSASDA VƏ İLƏ TƏMİN EDİLİR
            XİDMƏTLƏRƏ VƏ YA TƏTBİQİYƏ HÖRMƏT
            SATIŞA QARANTİYALAR, XÜSUSİ MƏQSƏD ÜÇÜN UYĞUNLUQ VƏ YA
            POZUNMAMAQ. AÇIQ VƏ GÖZƏLƏNMİŞ, O cümlədən, heç bir ZƏMANƏT VERMİR
            HƏR NÖVLƏR, QANUNİ VƏ YA BAŞQA.<br></br>
            <br></br> 5. QEYDİYYAT VƏ TƏHLÜKƏSİZLİK<br></br>
            <br></br> İstifadəçi dəqiq, tam və aktual məlumat verməlidir
            qeydiyyat məlumatları. Əks halda, bu Müqavilə hesab ediləcək
            pozulmuşdur və hesab olmadan bağlana bilər
            İstifadəçini məlumatlandırmaq. İstifadəçi parol və hesab üçün məsuliyyət daşıyır
            saytda və üçüncü tərəf saytlarında təhlükəsizlik. Əks halda Şirkət
            hər hansı məlumat itkisinə, təhlükəsizlik pozuntularına və ya məsuliyyət daşıya bilməz
            baş verə biləcək aparat və cihazlara zərər.<br></br>
            <br></br> 6. FOSS-MAJOR<br></br>
            <br></br> Tərəflərin nəzarəti altında deyil; -dən yaranır
            təbii fəlakətlər, yanğınlar, partlayışlar kimi səbəblərə görə müqavilə
            vətəndaş müharibələri, döyüşlər, iğtişaşlar, ictimai hərəkətlər, elan
            səfərbərlik, tətillər, lokavtlar və epidemiyalar, infrastruktur və
            internet xətaları, elektrik kəsilməsi (birlikdə "Force
            Major" aşağıdakı).
            tərəflər, tərəflər bundan məsuliyyət daşımır. Bu müddət ərzində
            müddət, bundan irəli gələn Tərəflərin hüquq və vəzifələri
            Müqavilə dayandırılıb.<br></br>
            <br></br> 7. MÜQAVİLƏNİN BÜTÜNLÜĞÜ VƏ TƏTBİQİ<br></br>
            <br></br> Bu müqavilənin şərtlərindən biri qismən olarsa və ya
            müqavilənin qalan hissəsi tamamilə etibarsız olmağa davam edir
            etibarlıdır.<br></br>
            <br></br> 8. MÜQAVİLƏDƏ EDİLƏCƏK DƏYİŞİKLİKLƏR<br></br>
            <br></br> Şirkət saytda təklif olunan xidmətləri dəyişə bilər
            və bu müqavilənin şərtləri istənilən vaxt qismən və ya tamamilə.
            Dəyişikliklər saytda dərc edildiyi gündən qüvvəyə minəcək. Bu
            dəyişikliklərə riayət etmək İstifadəçinin məsuliyyətidir. İstifadəçidir
            faydalanmağa davam edərək bu dəyişiklikləri qəbul etmiş sayılır
            təklif olunan xidmətlər.<br></br>
            <br></br> 9. BİLDİRİŞ<br></br>
            <br></br> Bununla bağlı bütün bildirişlər tərəflərə göndəriləcək
            Müqavilə Şirkətin məlum e-poçt ünvanı vasitəsilə həyata keçiriləcək
            və istifadəçinin üzvlük formasında göstərdiyi e-poçt ünvanı.
            İstifadəçi qəbul edir ki, qeydiyyat zamanı göstərilən ünvan
            etibarlı bildiriş ünvanı və dəyişərsə, o, xəbərdar edəcək
            digər tərəfə 5 gün ərzində yazılı şəkildə bildiriş göndərir
            bu ünvan etibarlı sayılacaq. <br></br>
            <br></br>10. DƏLİT MÜQAVİLƏSİ<br></br>
            <br></br> Tərəflər arasında yarana biləcək hər hansı mübahisələrdə
            bu müqavilə ilə bağlı əməliyyatlarla bağlı Tərəflərin
            kitablar, qeydlər və sənədlər, kompüter qeydləri və faks qeydləri olacaq
            Mülki Məcəlləyə uyğun olaraq sübut kimi qəbul edilməlidir
            6100 saylı prosedur və istifadəçi etiraz etməyəcəyi ilə razılaşır
            bu qeydlər.<br></br>
            <br></br> 11. MÜBAHİSƏLƏRİN HƏLLİ<br></br>
            <br></br> İstanbul (Mərkəzi) Ədliyyə Məhkəmələri və İcra
            Ofislər, yaranan mübahisələri həll etmək səlahiyyətinə malikdir
            bu Sazişin həyata keçirilməsi və ya təfsiri.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Agreement;

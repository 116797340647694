import React, { useEffect, useState } from "react";
import { useContextApp } from "../context/AppContext";
// import leftarrow from "../assets/images/leftarrow.svg";
// import rightarrow from "../assets/images/rightarrow.svg";
import ProductCard from "./ProductCard";
import Title from "./Title";

const AllProducts = () => {
  const { products } = useContextApp();
  const [cartItems, setCartItems] = useState([]);
  // const [startIndex, setStartIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(20);
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  // const itemsPerRow = isMobile ? 2 : 4;

  useEffect(() => {
    if (Array.isArray(products)) {
      setCartItems(products);
    } else {
      console.error("Products is not an array:", products);
      setCartItems([]);
    }
  }, [products]);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth < 640);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  function handleShowMore() {
    setItemsToShow((prev) => prev + 4); 
  }

  return (
    <div className="flex flex-col mx-auto px-4 max-w-7xl">
      <Title
        name={
          <span className="relative inline-block">
            Bütün məhsullarımız
            <span className="absolute bottom-[-11px] left-0 w-full h-[3px] bg-[#2aae21]" />
          </span>
        }
      />

      <div className="relative mt-8 mb-12">
        {/* PRODUCTS ROW */}
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
          {cartItems.slice(0, itemsToShow).map((item, index) => (
            <ProductCard key={index} item={item} />
          ))}
        </div>
        <div className="w-[1000px] flex justify-center">
          <button
            onClick={handleShowMore}
            className="mt-4 bg-green-600 text-white flex items-center  justify-center py-2 px-4 rounded hover:bg-green-800"
          >
            Daha Çox Göstər
          </button>
        </div>
      </div>
    </div>
  );
};

export default AllProducts;

import React, { useState } from "react";
import { useMainContextAdmin } from "../context/MainAdminContext";
import searchIcon from "../assets/images/searchicon.png";

export default function MescidAdd() {
  const {
    showAddModal,
    handleAddClick,
    newMescid,
    handleNewMescidChange,
    handleCloseModal,
    handleSaveNewMescid,
  } = useMainContextAdmin();

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    // You can add additional logic here to filter mescids based on the search query
  };

  return (
    <div className="mescid-add-container">
      <div
        className="header-container"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: "20px",
          marginTop:"20px",
          gap: "20px",
        }}
      >
        <div className="search-container">
          <div
            className="search-wrapper"
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "5px 10px",
              width: "183px",
            }}
          >
            <img
              src={searchIcon}
              alt="Search Icon"
              className="search-icon"
              style={{ width: "20px", marginRight: "10px" }}
            />
            <input
              type="text"
              placeholder="Axtar"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{
                border: "none",
                outline: "none",
                width: "100%",
                fontSize: "14px",
                padding: "5px",
                marginLeft:"20px",
                backgroundColor: "inherit",
              }}
            />
          </div>
        </div>
        <button
          className="hidden md:block whitespace-nowrap  ml-[-10px]  w-[183px] h-[40px] rounded-[4px] bg-blue-500 text-white"
          onClick={handleAddClick}
        >
          Məscid əlavə et
        </button>
      </div>

      {showAddModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-modal" onClick={handleCloseModal}>
              ×
            </button>
            <h2>Add New Mescid</h2>
            <form onSubmit={handleSaveNewMescid}>
              <div>
                <label>Məscid adı:</label>
                <input
                  type="text"
                  name="name"
                  value={newMescid.name}
                  onChange={handleNewMescidChange}
                />
              </div>
              <div>
                <label>Ünvan:</label>
                <input
                  type="text"
                  name="address"
                  value={newMescid.address}
                  onChange={handleNewMescidChange}
                />
              </div>
              <div>
                <label>Şəhər:</label>
                <input
                  type="text"
                  name="city"
                  value={newMescid.city}
                  onChange={handleNewMescidChange}
                />
              </div>
              <div>
                <label>Link:</label>
                <input
                  type="text"
                  name="googleMapLink"
                  value={newMescid.googleMapLink}
                  onChange={handleNewMescidChange}
                />
              </div>
              <div className="modal-buttons">
                <button
                  style={{ backgroundColor: "#05AC58", width: "350px" }}
                  type="submit"
                >
                  Add Mescid
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

import React from "react";
import ProfileForm from "./ProfileForm";
import ChangePasswordForm from "./ChangePasswordForm";

function AccountForm() {
  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
      <ProfileForm />
      <ChangePasswordForm />
    </div>
  );
}

export default AccountForm;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ianeCard.css';
import defaultMosqueImage from '../../assets/images/mosque.png';

const MosquePayment = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [mosqueImage, setMosqueImage] = useState(defaultMosqueImage);
  const location = useLocation();
  const navigate = useNavigate();

  const amount = location.state?.amount ? location.state.amount : '20';
  const mosqueId = location.state?.mosqueId || 'default';

  useEffect(() => {
    const fetchMosqueImage = async () => {
      try {
        await new Promise(resolve => setTimeout(resolve, 1000));
        const imageUrl = `https://example.com/mosque-image-${mosqueId}.jpg`;
        const img = new Image();
        img.onload = () => setMosqueImage(imageUrl);
        img.onerror = () => console.error('Error loading mosque image');
        img.src = imageUrl;
      } catch (error) {
        console.error('Error fetching mosque image:', error);
      }
    };

    fetchMosqueImage();
  }, [mosqueId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Payment submitted:', { cardNumber, expiryDate, cvv, amount, mosqueId });
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className="mosque-payment-container">
        <div className="mosque-selecter-container-forX">
      <div className="mosque-payment">
        <button className="close-button" onClick={handleClose}>
          X
        </button>
        <div className="image-container">
          <img src={mosqueImage} alt="Mosque" />
        </div>
        <div className="form-container">
          <h2>Kart məlumatlarınızı daxil edin</h2>
          <form onSubmit={handleSubmit}>
            <input
              className='input'
              type="text"
              placeholder="Kart nömrəsi"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
            <div className="card-details">
              <input
                type="text"
                placeholder="MM/YY"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
              />
              <input
                type="text"
                placeholder="CVV2"
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
              />
            </div>
            <div className="amount-display">
              <span>Ödənilən məbləğ</span>
              <span className="amount">{amount}₼</span>
            </div>
            <div className="button-group">
              <button type="submit" className="submit-button">Ödəniş et</button>
              <button type="button" className="cancel-button" onClick={handleClose}>İmtina et</button>
            </div>
          </form>
        </div>
      </div>
      </div>
    </div>
  );
};

export default MosquePayment;
const ProductImages = ({ productDetail }) => {
  return (
    <>
      <div className="main-image max-w-lg mx-auto p-4  border-gray-200 rounded-lg">
        <img
          id="mainImage"
          src={productDetail.imageUrl}
          alt={productDetail.name}
          className="w-full h-auto object-cover rounded-lg transition-transform duration-300 hover:scale-105"
        />
      </div>
    </>
  );
};

export default ProductImages;

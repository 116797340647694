import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import bin from "../../assets/images/bin.png"
import Cookies from 'js-cookie';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-lg">
        <p className="mb-4">{message}</p>
        <div className="flex justify-end">
          <button
            className="bg-green-300 text-black px-4 py-2 rounded mr-2"
            onClick={onClose}
          >
           Geri dön
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={onConfirm}
          >
            Sil
          </button>
        </div>
      </div>
    </div>
  );
};

export default function MainAdminSatici() {
  const [pendingSellers, setPendingSellers] = useState([]);
  const [approvedSellers, setApprovedSellers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('sorgular');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sellerToDelete, setSellerToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSellers();
  }, []);

  const fetchSellers = async () => {
    const accessToken = Cookies.get('accessToken');
    setLoading(true);
    try {
      const pendingResponse = await fetch('https://api.jumma.app/api/Seller/Pended', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      if (pendingResponse.ok) {
        const pendingData = await pendingResponse.json();
        setPendingSellers(pendingData.sellerListItemDto || []);
      } else {
        console.error('Failed to fetch pending sellers:', await pendingResponse.text());
      }

      const approvedResponse = await fetch('https://api.jumma.app/api/Seller/Approved', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      if (approvedResponse.ok) {
        const approvedData = await approvedResponse.json();
        setApprovedSellers(approvedData.approvedListItemDtos || []);
      } else {
        console.error('Failed to fetch approved sellers:', await approvedResponse.text());
      }
    } catch (err) {
      setError('Failed to fetch sellers data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const openDeleteModal = (seller) => {
    setSellerToDelete(seller);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setSellerToDelete(null);
  };

  const confirmDelete = async () => {
    if (!sellerToDelete) return;

    const accessToken = Cookies.get('accessToken');
    try {
      const response = await fetch(`https://api.jumma.app/api/Seller/Pending/${sellerToDelete.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      if (response.ok) {
        await fetchSellers();
        closeDeleteModal();
      } else {
        console.error('Failed to delete seller:', await response.text());
      }
    } catch (err) {
      setError('Failed to delete seller');
      console.error(err);
    }
  };

  const viewSellerDetails = (sellerId, isPending) => {
    if (isPending) {
      navigate(`/mainAdmin-panel/satici/${sellerId}`);
    } else {
      navigate(`/mainAdmin-panel/detailsapproved/${sellerId}`);
    }
  };

  const renderSellerTable = (sellers, isPending = false) => (
    <table className="border-collapse">
      <thead>
        <tr>
          <th className="border p-2">ID</th>
          <th className="border p-2">Ad</th>
          <th className="border p-2">Soyad</th>
          <th className="border p-2">Email</th>
          <th className="border p-2">Nömrə</th>
          <th className="border p-2">VÖEN</th>
          <th className="border p-2">Description</th>
          <th className="border p-2">Detallar</th>
        </tr>
      </thead>
      <tbody>
        {sellers.map((seller) => (
          <tr key={seller.id}>
            <td className="border p-2">{seller.id}</td>
            <td className="border p-2">{seller.firstname}</td>
            <td className="border p-2">{seller.lastname}</td>
            <td className="border p-2">{seller.email}</td>
            <td className="border p-2">{seller.phoneNumber}</td>
            <td className="border p-2">{seller.voen}</td>
            <td className="border p-2">{seller.description}</td>
            <td className="border p-2">
              <div className="flex space-x-2">
                <button
                  className="view-button text-gray-500 px-4 py-2 border-2 border-[#959595]-300 rounded-[4px]"
                  onClick={() => viewSellerDetails(seller.id, isPending)}
                >
                  View
                </button>
                <button
                  className="text-white px-2 py-1 rounded"
                  onClick={() => openDeleteModal(seller)}
                >
                  <img style={{height:"24px", width:"24px"}} src={bin} alt="" />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="p-4">
      <div className="mb-4">
        <button
          className={`mr-2 px-4 py-2 ${activeTab === 'sorgular' ? 'bg-[#C3E5D4] text-white rounded-[42px] ' : 'bg-gray-200 rounded-[42px]'}`}
          onClick={() => setActiveTab('sorgular')}
        >
          Sorğular
        </button>
        <button
          className={`px-4 py-2 ${activeTab === 'saticilar' ? 'bg-[#C3E5D4] text-white rounded-[42px]' : 'bg-gray-200 rounded-[42px]'}`}
          onClick={() => setActiveTab('saticilar')}
        >
          Satıcılar
        </button>
      </div>
      {activeTab === 'sorgular' ? (
        renderSellerTable(pendingSellers, true)
      ) : (
        renderSellerTable(approvedSellers, false)
      )}
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
        message={`Siz ${sellerToDelete?.firstname} ${sellerToDelete?.lastname} silmək istədiyinizə əminsiniz?`}
      />
    </div>
  );
}
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

const SellerDetails = ({ seller }) => {
  return (
    <div className="seller-details border p-4 mb-4 rounded">
      <table className="seller-info w-full">
        <tbody style={{ display: "flex" }}>
          <tr style={{ display: "flex", flexDirection: "column" }}>
            <th className="text-left">Ad</th>
            <td>{seller.firstName}</td>
          </tr>
          <tr style={{ display: "flex", flexDirection: "column" }}>
            <th className="text-left">Soyad</th>
            <td>{seller.lastName}</td>
          </tr>
          <tr style={{ display: "flex", flexDirection: "column" }}>
            <th className="text-left">Email</th>
            <td>{seller.email}</td>
          </tr>
          <tr style={{ display: "flex", flexDirection: "column" }}>
            <th className="text-left">Nömrə</th>
            <td>{seller.phoneNumber}</td>
          </tr>
          <tr style={{ display: "flex", flexDirection: "column" }}>
            <th className="text-left">VÖEN</th>
            <td>{seller.voen}</td>
          </tr>
        </tbody>
      </table>

      <div style={{ backgroundColor: "#F5F5F5", height: "111px", width: "387px", border: "1px solid #DFDFDF" }}>
        <p style={{ padding: "13px" }}>{seller.description || 'No description provided'}</p>
      </div>

      <div className="product-photo mt-4">
        <div className="photo-container" style={{ backgroundColor: "#F5F5F5", height: "111px", width: "387px", border: "1px solid #DFDFDF" }}>
          {seller.imageUrl ? (
            <img style={{ height: "96px", padding: "13px" }} src={seller.imageUrl} alt="Product" className="max-w-xs" />
          ) : (
            <div className="no-image">No image uploaded</div>
          )}
        </div>
      </div>
    </div>
  );
};

const SingleSellerDetails = () => {
  const [seller, setSeller] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { sellerId } = useParams();

  const fetchSellerDetails = useCallback(async () => {
    const accessToken = Cookies.get('accessToken');
    setLoading(true);
    try {
      const response = await fetch(`https://api.jumma.app/api/Seller/Approved/${sellerId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setSeller(data);
      } else {
        throw new Error('Failed to fetch seller details');
      }
    } catch (err) {
      setError('Failed to fetch seller data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [sellerId]);

  useEffect(() => {
    fetchSellerDetails();
  }, [fetchSellerDetails]);

  if (loading) {
    return <div className="text-center mt-8">Loading seller data...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">Error: {error}</div>;
  }

  if (!seller) {
    return <div className="text-center mt-8">No seller data found</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <SellerDetails seller={seller} />
    </div>
  );
};

export default SingleSellerDetails;
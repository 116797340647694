import React from 'react';

const ReviewItem = ({ email, date, rating, review }) => {
  return (
    <div className="review-item">
      <div className="email-date-container">
        <div className="reviewer-email"><p className="reviewer-email">{email}</p></div>
        <div className="review-date"><p className="review-date">{date}</p></div>
      </div>
      <div className="review-rating">
        {[...Array(rating)].map((_, i) => (
          <span key={i} className="star">
            ★
          </span>
        ))}
      </div>
      <p className="review-text">{review}</p>
    </div>
  );
};

export default ReviewItem;
import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useContextApp } from "../context/AppContext";

const Login = () => {
  const { loginUser, handleLogin, handleLoginSubmit, loginErrors, setIsLoggedIn } = useContextApp();
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();

  async function onSubmit(data) {
    setLoginError('');
    const success = await handleLogin(data);
    if (success) {
      setIsLoggedIn(true);
      navigate('/account/accountform');
    } else {
      setLoginError('ⓘ Yanlış mail və ya parol. Yenidən cəhd edin və ya sıfırlamaq üçün Parolu unutmuşam seçiminə klikləyin');
    }
  }

  return (
    <div className="flex flex-col mx-auto justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="w-[475px] space-x-[24px] rounded-[8px] bg-[#FFFfFF] mx-auto p-[15px]">
        <div className="flex items-center justify-center mb-[22px]">
          <div>
            <p className="font-normal text-[28px] text-[#0c0404] mb-[14px]">
              Xoş gəlmisiniz!
            </p>
            <p className="font-normal text-[16px] text-[#4b4b4b] mb-[30px]">
              Xoş gəlmisiniz! Zəhmət olmasa, məlumatlarınızı daxil edin.
            </p>
          </div>
          <div className="size-[113px]">
            <img src="../../logo.png" alt="logo" className="object-cover" />
          </div>
        </div>

        <form onSubmit={handleLoginSubmit(onSubmit)} className="flex flex-col">
          <div className="w-full h-[52px] rounded-[4px] border border-[#f5f5f5] mb-[8px]">
            <input
              type="email"
              {...loginUser("email", {
                required: "Zəhmət olmasa, e-poçtu doldurun",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Düzgün e-poçt formatı yazın",
                },
              })}
              placeholder="E-poçt*"
              className="w-full h-full pl-[25px] font-normal text-[14px] text-[#545454] outline-none border-none"
            />
            {loginErrors.email && (
              <p className="text-red-500 text-sm mt-1">
                {loginErrors.email.message}
              </p>
            )}
          </div>

          <div className="w-full h-[52px] rounded-[4px] border border-[#f5f5f5] mb-[8px]">
            <input
              type="password"
              {...loginUser("password", {
                required: "Boş saxlamayın",
                minLength: {
                  value: 8,
                  message: "Şifrə minimum 8 simvoldan ibarət olmalıdır",
                },
              })}
              placeholder="Şifrə*"
              className="w-full h-full pl-[25px] font-normal text-[14px] text-[#545454] outline-none border-none"
            />
            {loginErrors.password && (
              <p className="text-red-500 text-sm mt-1">
                {loginErrors.password.message}
              </p>
            )}
          </div>

          {loginError && (
            <p className="text-red-500 text-sm mt-1 mb-2">
              {loginError}
            </p>
          )}

          <Link
            to="/forgetpassword"
            className="text-[#05AC9B] text-end text-[12px] font-normal mt-[19px]"
          >
            Şifrəni unutmusan?
          </Link>
          <button
            type="submit"
            className="w-full bg-[#05ac58] rounded-[4px] text-center font-bold text-[20px] text-white py-[10px] mt-[33px] mb-[33px]"
          >
         Daxil Olun
          </button>
        </form>

        <div className="flex space-x-[14px] items-center justify-center mb-[71px]">
          <p className="font-normal text-[14px] text-[#545454]">
            Artıq hesabınız var?
          </p>
          <Link
            to="/register"
            className="text-[#05ac58] font-bold text-[14px] border-b border-[#05ac58]"
          >
            Qeydiyyatdan keç
          </Link>
        </div>
      </div>
      <div className="w-[475px] mx-auto rounded-[4px] mt-[22px] border-[#05AC58] border text-center py-[10px] bg-[#FFFFFF]">
        <Link to="/tezlikle" className="text-[#05AC58] font-bold text-[20px]">
          Arxaya qayıt
        </Link>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
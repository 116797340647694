import { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import spinner from "../assets/images/spinner.svg"

const MainAdminContext = createContext();

const MainAdminContextProvider = ({ children }) => {
  const [mescidData, setMescidData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedMescids, setSelectedMescids] = useState([]);
  const [editData, setEditData] = useState({
    id: "",
    name: "",
    address: "",
    city: "",
    googleMapLink: "",
  });

  const [newMescid, setNewMescid] = useState({
    name: "",
    address: "",
    city: "",
    googleMapLink: "",
  });

  useEffect(() => {
    fetchMescids();
  }, []);

  const fetchMescids = async () => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      console.error("No access token found");
      toast.error("Authentication error. Please log in again.");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`https://api.jumma.app/api/Mosque`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      setLoading(false);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Fetched mosque data:", data);

      if (Array.isArray(data)) {
        setMescidData(data);
      } else if (data && data.mosques && Array.isArray(data.mosques)) {
        setMescidData(data.mosques);
      } else {
        console.error("Received data is not an array:", data);
        setMescidData([]);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching mosques:", error);
      toast.error("Failed to fetch mosques. Please try again.");
    }
  };
  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <img
          src={spinner}
          alt="Loading..."
          style={{ width: "100px", height: "100px" }}
        />
      </div>
    );



  const handleSwitchToggle = async (id, currentState) => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      console.error("No access token found");
      toast.error("Authentication error. Please log in again.");
      return;
    }

    setMescidData((prevMescids) =>
      prevMescids.map((mescid) =>
        mescid.id === id ? { ...mescid, isActive: !currentState } : mescid
      )
    );

    try {
      const endpoint = `https://api.jumma.app/api/Mosque/${id}`;
      const body = JSON.stringify({ isActive: !currentState });

      console.log("Request Details:", {
        endpoint,
        method: "PATCH",
        body,
      });

      const response = await fetch(endpoint, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: body,
      });

      console.log("Response status:", response.status);

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Server responded with ${response.status}: ${errorText}`);
        throw new Error(`Failed to update status: ${response.statusText}`);
      }

      let updatedMosque;
      const responseText = await response.text();
      if (responseText) {
        try {
          updatedMosque = JSON.parse(responseText);
        } catch (error) {
          console.warn(
            "Response is not JSON, using default value",
            responseText
          );
          updatedMosque = { isActive: !currentState };
        }
      } else {
        console.warn("Empty response, using default value");
        updatedMosque = { isActive: !currentState };
      }

      setMescidData((prevMescids) =>
        prevMescids.map((mescid) =>
          mescid.id === id
            ? { ...mescid, isActive: updatedMosque.isActive }
            : mescid
        )
      );

      toast.success(`Mosque status updated successfully`);
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update mosque status. Please try again.");

      setMescidData((prevMescids) =>
        prevMescids.map((mescid) =>
          mescid.id === id ? { ...mescid, isActive: currentState } : mescid
        )
      );
    }
  };

  const deleteMescid = async (id) => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      console.error("No access token found");
      toast.error("Authentication error. Please log in again.");
      return;
    }

    try {
      const response = await fetch(`https://api.jumma.app/api/Mosque/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setMescidData((prevData) =>
        prevData.filter((mescid) => mescid.id !== id)
      );
      toast.success("Mosque deleted successfully");
    } catch (error) {
      console.error("Error deleting mosque:", error);
      toast.error("Failed to delete mosque. Please try again.");
    }
  };

  const handleSaveNewMescid = async (e) => {
    e.preventDefault();
    const accessToken = Cookies.get("accessToken");
    try {
      const response = await fetch(`https://api.jumma.app/api/Mosque`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(newMescid),
      });
      if (response.ok) {
        const savedMescid = await response.json();
        setMescidData([...mescidData, savedMescid]);
        setShowAddModal(false);
        await fetchMescids();
      } else {
        console.error("Failed to save mosque");
      }
    } catch (error) {
      console.error("Error saving new mosque:", error);
    }
  };

  const confirmDeleteMescids = async () => {
    const accessToken = Cookies.get("accessToken");
    try {
      const idsToDelete = selectedMescids.map((index) => mescidData[index].id);
      await Promise.all(
        idsToDelete.map((id) =>
          fetch(`https://api.jumma.app/api/Mosque/${id}`, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
        )
      );
      setMescidData((prevData) =>
        prevData.filter((_, i) => !selectedMescids.includes(i))
      );
      setSelectedMescids([]);
      setShowConfirmDeleteModal(false);
    } catch (error) {
      console.error("Error deleting mosques:", error);
    }
  };

  const handleEditClick = (id) => {
    const mosqueToEdit = mescidData.find((mosque) => mosque.id === id);

    if (!mosqueToEdit) {
      console.error("Selected mosque does not exist");
      return;
    }

    setEditData({
      id: mosqueToEdit.id,
      name: mosqueToEdit.name,
      address: mosqueToEdit.address,
      googleMapLink: mosqueToEdit.googleMapLink,
      city: mosqueToEdit.city,
    });
    setShowEditModal(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault();

    if (!editData.id) {
      console.error("Cannot save. No valid mosque selected for editing.");
      return;
    }

    const accessToken = Cookies.get("accessToken");

    try {
      const response = await fetch(
        `https://api.jumma.app/api/Mosque/${editData.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(editData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      let updatedMescid;
      const responseText = await response.text();
      if (responseText) {
        try {
          updatedMescid = JSON.parse(responseText);
        } catch (error) {
          console.warn("Response is not JSON, using editData", responseText);
          updatedMescid = editData;
        }
      } else {
        console.warn("Empty response, using editData");
        updatedMescid = editData;
      }

      setMescidData((prevData) =>
        prevData.map((mescid) =>
          mescid.id === editData.id ? { ...mescid, ...updatedMescid } : mescid
        )
      );
      setShowEditModal(false);
      toast.success("Mosque updated successfully");
    } catch (error) {
      console.error("Error updating mosque:", error);
      toast.error("An error occurred while updating the mosque.");
    }
  };

  const handleCheckboxChange = (id) => {
    const updatedSelection = selectedMescids.includes(id)
      ? selectedMescids.filter((mescidId) => mescidId !== id)
      : [...selectedMescids, id];
    setSelectedMescids(updatedSelection);
  };

  const handleNewMescidChange = (e) => {
    setNewMescid((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAddClick = () => {
    setNewMescid({
      name: "",
      address: "",
      city: "",
      googleMapLink: "",
    });
    setShowAddModal(true);
  };

  const handleDeleteClick = () => {
    setShowConfirmDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
    setShowAddModal(false);
    setShowConfirmDeleteModal(false);
  };

  const values = {
    mescidData,
    setMescidData,
    handleAddClick,
    handleCloseModal,
    deleteMescid,
    showConfirmDeleteModal,
    confirmDeleteMescids,
    selectedMescids,
    setSelectedMescids,
    handleEditChange,
    handleEditClick,
    editData,
    showEditModal,
    setShowEditModal,
    showAddModal,
    handleSaveNewMescid,
    newMescid,
    handleNewMescidChange,
    setNewMescid,
    handleSaveEdit,
    handleCheckboxChange,
    handleDeleteClick,
    handleSwitchToggle,
    loading,
    setLoading,
  };

  return (
    <MainAdminContext.Provider value={values}>
      {children}
    </MainAdminContext.Provider>
  );
};

export default MainAdminContextProvider;

export const useMainContextAdmin = () => useContext(MainAdminContext);

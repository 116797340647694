import React, { useState } from "react";
import { useMainContextAdmin } from "../context/MainAdminContext";
import editImg from "../assets/images/edit.png";
import deleteImg from "../assets/images//bin.png";

export default function MescidList() {
  const {
    mescidData,
    handleEditClick,
    showEditModal,
    handleSaveEdit,
    editData,
    handleEditChange,
    handleSwitchToggle,
    handleCloseModal,
    deleteMescid
  } = useMainContextAdmin();

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [mescidToDelete, setMescidToDelete] = useState(null);

  if (!mescidData || mescidData.length === 0) {
    return <div>No mosques available. Please add some mosques.</div>;
  }

  const handleDeleteClick = (mescid) => {
    setMescidToDelete(mescid);
    setShowDeleteConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    if (mescidToDelete) {
      deleteMescid(mescidToDelete.id);
      setShowDeleteConfirmModal(false);
      setMescidToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmModal(false);
    setMescidToDelete(null);
  };

  return (
    <div>
      <div className="table-container-mescid">
        <table className="mescidtable">
          <thead>
            <tr>
              <th>ID</th>
              <th>Məscid adı</th>
              <th>Ünvan</th>
              <th>Link</th>
              <th>Şəhər</th>
              <th>Aktivdir</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {mescidData.map((mescid) => (
              <tr key={mescid.id}>
                <td>{mescid.id}</td>
                <td>{mescid.name}</td>
                <td>{mescid.address}</td>
                <td>
                  <a 
                    style={{ color: "#517FF2" }} 
                    href={mescid.googleMapLink} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    Link
                  </a>
                </td>
                <td>{mescid.city}</td>
                <td>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={mescid.isActive}
                      onChange={() => handleSwitchToggle(mescid.id, mescid.isActive)}
                    />
                    <span className="slider"></span>
                  </label>
                </td>
                <td>
                  <button onClick={() => handleEditClick(mescid.id)}>
                    <img
                      src={editImg}
                      alt="Edit"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                </td>
                <td>
                  <button onClick={() => handleDeleteClick(mescid)}>
                    <img
                      src={deleteImg}
                      alt="Delete"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showEditModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button className="close-modal" onClick={handleCloseModal}>
                ×
              </button>
              <h2>Edit Mescid</h2>
              <form onSubmit={handleSaveEdit}>
                <div>
                  <label>Məscid adı:</label>
                  <input
                    type="text"
                    name="name"
                    value={editData.name}
                    onChange={handleEditChange}
                  />
                </div>
                <div>
                  <label>Ünvan:</label>
                  <input
                    type="text"
                    name="address"
                    value={editData.address}
                    onChange={handleEditChange}
                  />
                </div>
                <div>
                  <label>Link:</label>
                  <input
                    type="text"
                    name="googleMapLink"
                    value={editData.googleMapLink}
                    onChange={handleEditChange}
                  />
                </div>
                <div>
                  <label>Şəhər:</label>
                  <input
                    type="text"
                    name="city"
                    value={editData.city}
                    onChange={handleEditChange}
                  />
                </div>
                <div className="modal-buttons">
                  <button
                    style={{ backgroundColor: "#05AC58", width: "350px" }}
                    type="submit"
                  >
                    Dəyişiklikləri saxla
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {showDeleteConfirmModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2>Silmək istədiyinizə əminsiniz?</h2>
              
              <div className="modal-buttons">
                <button
                  style={{
                    backgroundColor: "#FF0000",
                    marginRight: "10px",
                    width: "320px",
                    color: "white",
                  }}
                  onClick={handleConfirmDelete}
                >
                  Sil
                </button>
                <button
                  style={{
                    border: "1px solid #05AC58",
                    width: "320px",
                    color: "#05AC58",
                    backgroundColor: "white",
                  }}
                  onClick={handleCancelDelete}
                >
                  Geri qayıt
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
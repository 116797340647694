import React from 'react';
import { Link } from "react-router-dom";
import { FaRegTrashCan } from "react-icons/fa6";
import { useContextApp } from "../context/AppContext";

const ProductCard = ({ item }) => {
  const { addToCart, removeFromCart } = useContextApp();

  if (!item) {
    return <p className="text-center text-gray-500">Product not found</p>;
  }

  return (
    <div className="relative group bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1">
      <div className="relative w-full h-[220px] bg-gray-100">
        <Link to={`/product-card/${item.id}`} className="absolute inset-0 z-10">
          <img
            src={item?.imageUrl || "/default-image.jpg"}
            alt={item?.name || "Default Title"}
            className="object-cover w-full h-full transition-transform duration-300 group-hover:scale-105"
          />
        </Link>
        <div className="absolute inset-0 bg-black bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      </div>
      <div className="p-4 bg-white">
        <h3 className="font-bold text-lg text-gray-800 truncate mb-2">
          {item?.name || "Default Title"}
        </h3>
        <div className="flex justify-between items-center">
          <p className="font-bold text-xl text-gray-800">
            {item?.price ? `${item.price} AZN` : "Default Price"}
          </p>
          <div className="relative">
            {item.isBasket ? (
              <button
                onClick={() => removeFromCart(item.id)}
                className="w-10 h-10 bg-red-500 text-white flex justify-center items-center rounded-full transition-all duration-300 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                aria-label="Remove from cart"
              >
                <FaRegTrashCan className="w-5 h-5" />
              </button>
            ) : (
              <button
                onClick={() => addToCart(item.id)}
                className="w-10 h-10 bg-[#05AC9B] text-white flex justify-center items-center rounded-full transition-all duration-300 hover:bg-[#048F82] focus:outline-none focus:ring-2 focus:ring-[#05AC9B] focus:ring-opacity-50"
                aria-label="Add to cart"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
import React from "react";
import { useParams } from "react-router-dom";


const CategoryPage = () => {
  const { category } = useParams();

  const products = {
    food: [
      "Legumes",
      "Liquid oil",
      "Ketchup, Mayonnaise, Sauce",
      "Sugar, Salt, Spices",
      "Pasta",
      "Soup, Bouillon",
      "Tomato Paste, Canned, Pickled Sauce",
      "Prepared foods",
      "Flour and Bakery Products",
      "Cake ingredients",
    ],
    breakfast: [
      "Olives, Dates",
      "Cheese",
      "Honey, Jam",
      "Yogurt",
      "Egg",
      "Butter",
      "Corn Flakes, Oats",
      "Cream, Cream, Sauce",
      "Cream Chocolate, Spread",
      "Tahini, Molasses, Halva",
    ],
    snack: [
      "Biscuits, Chocolate",
      "Chips",
      "Confectionery, Pismaniye",
      "Bar, Coverings",
      "Nuts",
      "Snack",
      "Cracker",
    ],
    meat: ["Processed Meat", "White Meat", "Red Meat"],
    drinks: [
      "Fizzy Drinks",
      "Tea Coffee",
      "Water",
      "Still Drinks",
      "Powdered Drinks",
      "Milk",
    ],
    personalcare: [
      "Shower, Bath",
      "Hair Care",
      "Health Products",
      "Dental Care",
      "Hand, Body Care",
      "Shave Products",
      "Perfume, Deodorant",
    ],
    cleaning: ["Washing", "House Cleaning", "Washing Up"],
    homelife: [
      "Kitchen Helpers",
      "Paper Products",
      "Glass Ware",
      "Home Textiles",
      "Electronic",
    ],
    herbalproducts: [],
    campaigns: [],
  };

  const categoryItems = products[category] || [];
  return (
    <main className="flex-1 px-4 flex flex-col items-center">
      <div>
        <h1>{category.charAt(0).toUpperCase() + category.slice(1)}</h1>
        <ul>
          {categoryItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
  
    </main>
  );
};

export default CategoryPage;

import React, { useState, useEffect } from "react";
import "../components/Card/Card.css";
import truck from "../assets/images/truck.png";
import bag from "../assets/images/bag.png";
// import shampoo from "../assets/images/shampoo.png";
// import heart from "../assets/images/heart.png";
import bin from "../assets/images/bin.png";
import left from "../assets/images/left.png";
import { Link } from "react-router-dom";
import { useContextApp } from "../context/AppContext";
import "../components/Card/Card.css";
import cart from "../assets/images/cart.svg";
const ShoppingCart = () => {
  // const { products, deleteFromCart } = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);

  const [products, setProducts] = useState([]);
  const { fetchCartProducts, removeFromCart } = useContextApp();
  useEffect(() => {
    setProducts(fetchCartProducts());
  }, [fetchCartProducts]);

  console.log(products);

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));
  };

  const calculateTotalPrice = () => {
    return (products.basePrice * quantity).toFixed(2);
  };

  const calculateTax = (price) => {
    return (price * 0.2).toFixed(2);
  };

  const calculateTotalWithTax = (price) => {
    return (price + parseFloat(calculateTax(price))).toFixed(2);
  };

  const totalPrice = calculateTotalPrice();
  const totalWithTax = calculateTotalWithTax(parseFloat(totalPrice));

  return (
    <div className="card-container flex flex-col">
      <div className="flex ">
        <div className="left-container h-[300px] overflow-auto">
          <div className="header">
            <div className="image-holder">
              <img src={truck} alt="Shipping" />
            </div>
            <p>
              KARDINIZA DƏYƏRİ{" "}
              <span className="bold-text">{products?.shippingThreshold} ₼</span>{" "}
              OLAN DAHA ÇOX MƏHSUL ƏLAVƏ EDİN <br />
              <span className="bold-text"> {products?.shippingFeeMessage}</span>
            </p>
          </div>
          <div className="sec-container">
            {products?.length === 0 ? (
              <div className="flex flex-col items-center justify-center py-10">
                {/* Cart Icon */}
                <img
                  src={cart}
                  alt="Cart"
                  className="w-7 h-7 mb-[20px] object-cover"
                />

                <p className="text-[#878686] text-[14px] font-bold mb-[6px]">
                  Səbətiniz hazırda boşdur.
                </p>
                <p className="text-[#878686] text-[14px] font-bold">
                  Davam etmək üçün məhsul əlavə edin.
                </p>
              </div>
            ) : (
              products.map((cartItem, index) => (
                <div key={cartItem.id} className="cart-item-wrapper border">
                  {/* Safely access properties */}
                  <div className="store-name">
                    <input
                      style={{
                        marginLeft: "20px",
                        color: "black",
                        marginTop: "19px",
                        width: "20px",
                        height: "20px",
                      }}
                      type="checkbox"
                    />
                    <h3>
                      <img className="bag" src={bag} alt="" />{" "}
                      {cartItem?.storeName || ""}
                    </h3>
                  </div>
                  <div className="cart-item">
                    <div className="img-cont">
                      <input
                        style={{
                          marginRight: "20px",
                          color: "black",
                          marginTop: "40px",
                          width: "20px",
                          height: "20px",
                        }}
                        type="checkbox"
                      />
                      <div className="img-cont flex-shrink-0 relative overflow-hidden w-32 h-32 rounded-md border border-gray-200">
                        <img
                          src={cartItem?.imageUrl || ""}
                          className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
                          alt={cartItem?.name || "Product"}
                        />
                      </div>
                    </div>
                    <div className="product-info">
                      <p>{cartItem?.title || ""}</p>
                      <div className="product-quantity">
                        <span className="bold-text">
                          {cartItem?.price || ""} ₼
                        </span>
                        <div className="quantity">
                          <button onClick={handleDecrease}>-</button>
                          <input type="text" value={quantity} readOnly />
                          <button onClick={handleIncrease}>+</button>
                        </div>
                      </div>
                      <div className="cart-item-actions">
                        {/* <button
                          className="btn add-to-favorites-section"
                          onClick={() => addToFavorites(cartItem.id)}
                        >
                          <img className="btn-img" src={heart} alt="" /> Add to
                          favorites
                        </button> */}
                        <button
                          className="btn-delete"
                          onClick={() => removeFromCart(cartItem.id)}
                        >
                          <img className="btn-img" src={bin} alt="" /> Delete
                          item
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="right-container">
          <div style={{ marginTop: "-17px" }} className="order-summary">
            <h2>Sifarişin Xülasəsi</h2>
            <div className="item">
              <span>Ara Toplam</span>
              <span>{totalPrice} ₼</span>
            </div>
            <div className="item">
              <span>KDV</span>
              <span>{calculateTax(parseFloat(totalPrice))} ₼</span>
            </div>
            <div className="item last-item">
              <span>KDV Dahil</span>
              <span>{totalWithTax} ₺</span>
            </div>
            <div className="total">
              <span>Toplam</span>
              <span style={{ fontWeight: "700" }}>{totalWithTax} ₺</span>
            </div>
            <Link to="/add-address" className="checkout">
              <button>İNDİ YOXLA</button>
            </Link>
          </div>
        </div>
      </div>

      <Link to="/tezlikle" id="green" className="btn green-btn ">
        <img src={left} alt="" /> Alış-verişə davam et
      </Link>
    </div>
  );
};

export default ShoppingCart;

import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/images/logo.png";
import upload from "../assets/images/cloud.png";
import VerifyCode from '../components/VerifyCodeSeller';

const SellerRegistrationForm = () => {
  const formRef = useRef(null);
  const fileInputRef = useRef(null);
  
  const initialFormState = {
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    StoreName: "",
    Password: "",
    VOEN: "",
    Description: "",
  };

  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [showVerifyCode, setShowVerifyCode] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (showVerifyCode) {
      setIsLoading(false);
    }
  }, [showVerifyCode]);

  const validateForm = () => {
    let newErrors = {};
    
    // Validate FirstName
    if (!formData.FirstName.trim()) {
      newErrors.FirstName = "Ad tələb olunur";
    } else if (formData.FirstName.length < 2) {
      newErrors.FirstName = "Ad ən azı 2 simvol olmalıdır";
    }

    // Validate LastName
    if (!formData.LastName.trim()) {
      newErrors.LastName = "Soyad tələb olunur";
    } else if (formData.LastName.length < 2) {
      newErrors.LastName = "Soyad ən azı 2 simvol olmalıdır";
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.Email.trim()) {
      newErrors.Email = "Email tələb olunur";
    } else if (!emailRegex.test(formData.Email)) {
      newErrors.Email = "Düzgün email formatı daxil edin";
    }

    // Validate PhoneNumber
    const phoneRegex = /^\+994 \d{2} \d{3} \d{2} \d{2}$/;
    if (!formData.PhoneNumber.trim()) {
      newErrors.PhoneNumber = "Telefon nömrəsi tələb olunur";
    } else if (!phoneRegex.test(formData.PhoneNumber)) {
      newErrors.PhoneNumber = "Düzgün telefon nömrəsi formatı daxil edin";
    }

    // Validate StoreName
    if (!formData.StoreName.trim()) {
      newErrors.StoreName = "Mağaza adı tələb olunur";
    }

    // Validate VOEN
    const voenRegex = /^\d{10}$/;
    if (!formData.VOEN.trim()) {
      newErrors.VOEN = "VOEN tələb olunur";
    } else if (!voenRegex.test(formData.VOEN)) {
      newErrors.VOEN = "VOEN 10 rəqəmdən ibarət olmalıdır";
    }

    // Validate Password
    if (!formData.Password) {
      newErrors.Password = "Şifrə tələb olunur";
    } else if (formData.Password.length < 8) {
      newErrors.Password = "Şifrə ən azı 8 simvol olmalıdır";
    } else if (!/[A-Z]/.test(formData.Password)) {
      newErrors.Password = "Şifrədə ən azı bir böyük hərf olmalıdır";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.Password)) {
      newErrors.Password = "Şifrədə ən azı bir xüsusi simvol olmalıdır";
    }
    

    // Validate Description
    if (!formData.Description.trim()) {
      newErrors.Description = "Məhsul məlumatı tələb olunur";
    }

    // Validate Image
    if (!selectedImage) {
      newErrors.Image = "Məhsul şəkli tələb olunur";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear the error for this field as the user types
    setErrors({ ...errors, [name]: "" });
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d+]/g, '');
    const phoneNumberLength = phoneNumber.length;
    
    if (phoneNumberLength <= 4) return phoneNumber;
    if (phoneNumberLength <= 6) {
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4)}`;
    }
    if (phoneNumberLength <= 9) {
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(6)}`;
    }
    if (phoneNumberLength <= 11) {
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(6, 9)} ${phoneNumber.slice(9, 11)}`;
    }
    if (phoneNumberLength <= 13) {
      return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(6, 9)} ${phoneNumber.slice(9, 11)} ${phoneNumber.slice(11, 13)}`;
    }
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 6)} ${phoneNumber.slice(6, 9)} ${phoneNumber.slice(9, 11)}`;
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setFormData({ ...formData, PhoneNumber: formattedPhoneNumber });
    // Clear the error for PhoneNumber as the user types
    setErrors({ ...errors, PhoneNumber: "" });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      // Clear the error for Image when an image is selected
      setErrors({ ...errors, Image: "" });
    }
  };

  const resetForm = () => {
    setFormData(initialFormState);
    setSelectedImage(null);
    setErrors({});
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white w-[550px] h-[300px] rounded-lg shadow-xl flex flex-col justify-center items-center p-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-red-500 mb-4">E-poçt artıq qeydiyyatdan keçib</h2>
            <p className="mb-4 w-[450px] h-[42px] text-center text-[#8A8A8A] text-[16px] leading-[20.83px] font-normal">
              Artıq bu e-poçtla qeydiyyatdan keçmisiniz. Qeydiyyatınızı tamamlamaq üçün başqa e-poçt ünvanından istifadə edin.
            </p>
          </div>
          <button
            onClick={onClose}
            className="w-[320px] border border-red-700 text-red-700 py-3 px-4 rounded-md"
          >
            Bağla
          </button>
        </div>
      </div>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return; 
    if (!validateForm()) {
   
      return;
    }

    setIsLoading(true);
    const accessToken = Cookies.get("accessToken");
  
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });
    if (selectedImage) {
      formDataToSend.append("FormFile", selectedImage);
    }
  
    try {
      const response = await fetch(`https://api.jumma.app/api/Seller`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formDataToSend,
      });
  
      if (response.ok) {
        setShowVerifyCode(true);
      } else {
        const errorData = await response.json();
        if (errorData && errorData.message) {
          setErrorMessage(errorData.message);
        } else {
          setErrorMessage("E-poçt artıq qeydiyyatdan keçib. Yenidən cəhd edin.");
        }
        setShowErrorPopup(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorMessage("E-poçt artıq qeydiyyatdan keçib. Yenidən cəhd edin.");
      setShowErrorPopup(true);
      setIsLoading(false);
    }
  };


  return (
    <div className="max-[475px] mx-auto p-6 bg-white rounded-lg shadow-md"
    style={{
      width: "475px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "50px",
      marginTop: "50px",
    }}>
    <ToastContainer />
    <div className="p-4 rounded-lg">
      <div className="w-[445px] flex items-center justify-between mb-4"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "45px",
        }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2 className="text-xl font-bold">Satıcı olun!</h2>
          <p style={{ marginTop: "-15px", width: "280px" }} className="mb-4 text-sm">
            Satıcı olun! Zəhmət olmasa, məlumatlarınızı daxil edin.
          </p>
        </div>
        <img
          style={{ width: "113px", height: "113px", marginTop: "-30px" }}
          src={logo}
          alt="Logo"
        />
      </div>

      <form ref={formRef} onSubmit={handleSubmit}>
        <div className="mb-2">
          <input
            name="FirstName"
            value={formData.FirstName}
            onChange={handleChange}
            className={`w-full h-[52px] p-2 border outline-none border-[#DFDFDF] rounded bg-white ${
              errors.FirstName ? 'border-red-500' : ''
            }`}
            type="text"
            placeholder="Ad*"
          />
          {errors.FirstName && <p className="text-red-500 text-sm mt-1">{errors.FirstName}</p>}
        </div>

        <div className="mb-2">
          <input
            name="LastName"
            value={formData.LastName}
            onChange={handleChange}
            className={`w-full h-[52px] p-2 border outline-none border-[#DFDFDF] rounded bg-white ${
              errors.LastName ? 'border-red-500' : ''
            }`}
            type="text"
            placeholder="Soyad*"
          />
          {errors.LastName && <p className="text-red-500 text-sm mt-1">{errors.LastName}</p>}
        </div>

        <div className="mb-2">
          <input
            name="Email"
            value={formData.Email}
            onChange={handleChange}
            className={`w-full h-[52px] p-2 border outline-none border-[#DFDFDF] rounded bg-white ${
              errors.Email ? 'border-red-500' : ''
            }`}
            type="email"
            placeholder="Email*"
          />
          {errors.Email && <p className="text-red-500 text-sm mt-1">{errors.Email}</p>}
        </div>

        <div className="mb-2">
          <input
            name="PhoneNumber"
            value={formData.PhoneNumber}
            onChange={handlePhoneChange}
            className={`w-full h-[52px] p-2 border outline-none border-[#DFDFDF] rounded bg-white ${
              errors.PhoneNumber ? 'border-red-500' : ''
            }`}
            type="tel"
            placeholder="Telefon Nömrəsi* (+994 xx xxx xx xx)"
          />
          {errors.PhoneNumber && <p className="text-red-500 text-sm mt-1">{errors.PhoneNumber}</p>}
        </div>

        <div className="mb-2">
          <input
            name="StoreName"
            value={formData.StoreName}
            onChange={handleChange}
            className={`w-full h-[52px] p-2 border outline-none border-[#DFDFDF] rounded bg-white ${
              errors.StoreName ? 'border-red-500' : ''
            }`}
            type="text"
            placeholder="Mağaza Adı*"
          />
          {errors.StoreName && <p className="text-red-500 text-sm mt-1">{errors.StoreName}</p>}
        </div>

        <div className="mb-2">
          <input
            name="VOEN"
            value={formData.VOEN}
            onChange={handleChange}
            className={`w-full h-[52px] p-2 border outline-none border-[#DFDFDF] rounded bg-white ${
              errors.VOEN ? 'border-red-500' : ''
            }`}
            type="text"
            placeholder="VOEN*"
          />
          {errors.VOEN && <p className="text-red-500 text-sm mt-1">{errors.VOEN}</p>}
        </div>

        <div className="mb-2">
            <input
              name="Password"
              value={formData.Password}
              onChange={handleChange}
              className={`w-full h-[52px] p-2 border outline-none border-[#DFDFDF] rounded bg-white ${
                errors.Password ? 'border-red-500' : ''
              }`}
              type="password"
              placeholder="Şifrə*"
            />
            {errors.Password && <p className="text-red-500 text-sm mt-1">{errors.Password}</p>}
          </div>

          <div className="mb-2">
            <input
              name="Description"
              value={formData.Description}
              onChange={handleChange}
              className={`w-full h-[52px] p-2 border outline-none border-[#DFDFDF] rounded bg-white ${
                errors.Description ? 'border-red-500' : ''
              }`}
              type="text"
              placeholder="Məhsul Məlumatı*"
            />
            {errors.Description && <p className="text-red-500 text-sm mt-1">{errors.Description}</p>}
          </div>

          <div className={`h-[142px] border ${errors.Image ? 'border-red-500' : 'border-[#DFDFDF]'} flex items-center mb-4`}>
            <div className="flex flex-col gap-2">
              <span className="ml-2 text-sm text-gray-500">
                Məhsul Şəklini Yükləyin*
              </span>
              <div className="flex items-center gap-2">
                <label className="cursor-pointer">
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }} 
                  />
                  <img
                    className="outline-none w-[31px] h-[31px]"
                    src={upload}
                    alt="Upload icon"
                    style={{marginLeft:"45px"}}
                  />
                </label>
                {selectedImage && (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      marginLeft: "150px",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {errors.Image && <p className="text-red-500 text-sm mt-1 mb-2">{errors.Image}</p>}


          <button
            className={`w-full bg-green-500 text-white py-2 rounded hover:bg-green-600 flex items-center justify-center ${
              isLoading ? 'opacity-75 cursor-not-allowed' : ''
            }`}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Yüklənir...
              </>
            ) : (
              'Sorğunu göndərin'
            )}
          </button>
        </form>
      </div>
      <Link to='/tezlikle'>
        <button className="w-[445px] mt-4 bg-white text-green-500 py-2 rounded border border-green-500 hover:bg-green-50">
          Geri qayıt
        </button>
      </Link>

      {showVerifyCode && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <VerifyCode
              firstName={formData.FirstName}
              email={formData.Email}
              onClose={() => {
                setShowVerifyCode(false);
                resetForm();
                toast.success("Qeydiyyat uğurla tamamlandı!", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              }}
            />
          </div>
        </div>
      )}
      
      {showErrorPopup && (
        <ErrorPopup
          message={errorMessage}
          onClose={() => setShowErrorPopup(false)}
        />
      )}
    </div>
  );
};

export default SellerRegistrationForm;
import React, { useState } from 'react';
import './AdminView.css';
import redbin from '../../assets/images/redbin.png';
import productImg from '../../assets/images/boxProduct.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useContextAdmin } from '../../context/AdminContext';

export default function AdminView() {
  const { adminOrders, handleDeleteOrder } = useContextAdmin();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { orderId } = useParams();
const navigate=useNavigate()
  const item = adminOrders.find((order) => order.id === orderId);

  if (!item) return <p>Order not found</p>;

  
  const handleDeleteClick = () => {
    setShowConfirmModal(true);
  };

 
  function handleConfirmDelete ()  {
    handleDeleteOrder(item.id); 
    navigate('/admin-panel/orders')
    setShowConfirmModal(false);
  };

 
  const handleCancelDelete = () => {
    setShowConfirmModal(false);
  };

  return (
    <div className="admin-view">
      <h2>Order id: #{item.id}</h2>
      <h3>Order details</h3>
      <table className="order-details">
        <thead>
          <tr>
            <th>Product name</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {item.items.map((product, index) => (
            <tr key={index}>
              <td>
                <div className="product-info">
                  <img src={productImg} alt="Product" className="product-image" />
                  <div className="productdetails">
                    <span>{product.quantity} </span>
                    <p>{product.name}</p>
                  </div>
                </div>
              </td>
              <td>
                <span className="discounted-price">{product.discountedPrice} ₼</span>
                <span className="original-price">{product.originalPrice} ₼</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="delete-order-button" onClick={handleDeleteClick}>
        <img src={redbin} alt="Delete" className="delete-icon" /> Delete order
      </button>

      {showConfirmModal && (
        <div className="confirm-delete-overlay">
          <div className="confirm-delete-content">
            <h3>Are you sure you want to delete this order?</h3>
            <div className="confirm-delete-buttons">
              <button className="confirm-btn" onClick={handleConfirmDelete}>
                Delete
              </button>
              <button className="cancel-btn" onClick={handleCancelDelete}>
                Go back
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React from "react";
import Register from "../../components/Register";

const SignInPage = () => {
  return (
    <div className="w-full max-w-screen-xl mt-4 space-y-8">
      <Register></Register>
    </div>
  );
};

export default SignInPage;

import React, { useState, useEffect } from "react";
import "./MainAdminIstifadeciler.css";
import MainAdminAddUser from "../MainAdminAddUser";
import MainAdminUserList from "../MainAdminUserList";
import Cookies from "js-cookie";
import { useMainContextAdmin } from "../../context/MainAdminContext";

export default function MainAdminIstifadeciler() {
  const { mescidData } = useMainContextAdmin();
  const [userData, setUserData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    rePassword: "",
    phoneNumber: "",
    roles: [],
    mescid: "",
  });
  const [selectedRoles, setSelectedRoles] = useState({
    role1: "",
    role2: "",
    mescid: "",
  });
  const [userToDelete, setUserToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAllUsers = async () => {
    const accessToken = Cookies.get("accessToken");
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch("https://api.jumma.app/api/User", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data && Array.isArray(data.users)) {
        const normalizedUsers = data.users.map((user) => ({
          ...user,
          roles: Array.isArray(user.roles) ? user.roles : [],
        }));
        setUserData(normalizedUsers);
      } else {
        throw new Error("Received data is not in the expected format");
      }
    } catch (error) {
      console.error("Error details:", error);
      setError(`Failed to fetch users: ${error.message}`);
      setUserData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  // const handleEditClick = (userId) => {
  //   const userIndex = userData.findIndex((user) => user.id === userId);
  //   if (userIndex !== -1) {
  //     const user = userData[userIndex];
  //     setEditIndex(userIndex);
  //     setEditData(user);
  //     setShowEditModal(true);
  //   } else {
  //     console.error("User not found for editing:", userId);
  //     alert("Error: Unable to edit this user. Please try again.");
  //   }
  // };

  const handleRoleClick = (userId) => {
    const user = userData.find((user) => user.id === userId);
    if (user) {
      setSelectedRoles({
        role1: user.roles[0] || "",
        role2: user.roles[1] || "",
        mescid: user.mescid || "",
      });
      setEditIndex(userData.findIndex((u) => u.id === userId));
      setShowRoleModal(true);
    } else {
      console.error("User not found for userId:", userId);
      alert("Error: Unable to find user data. Please try again or contact support.");
    }
  };
  
  const handleSaveRoles = async () => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      console.error("Access token is missing");
      alert("Authentication error. Please log in again.");
      return;
    }
  
    const { role1, mescid } = selectedRoles;
    if (!role1) {
      console.error("No role selected");
      alert("Please select a role before saving.");
      return;
    }
  
    const userIndex = editIndex;
    if (userIndex === null || userIndex === undefined) {
      console.error("Edit index is null or undefined");
      alert("Error: No user selected for role update.");
      return;
    }
  
    const user = userData[userIndex];
    if (!user) {
      console.error("User data not found for index:", userIndex);
      alert("Error: User data not found.");
      return;
    }
  
    const userId = user.id;
    if (!userId) {
      console.error("User ID is undefined");
      alert("Failed to update user roles. User ID is missing.");
      return;
    }
  
    try {
      let response;
  
      if (role1 === "Imam") {
        if (!mescid) {
          console.error("Mosque ID is missing for Imam role");
          alert("Failed to update user roles. Mosque ID is required for Imam role.");
          return;
        }
  
        const mosqueId = parseInt(mescid, 10);
        if (isNaN(mosqueId)) {
          console.error("Invalid Mosque ID:", mescid);
          alert("Failed to update user roles. Invalid Mosque ID.");
          return;
        }
  
        const imamData = {
          isImam: true,
          
          mosqueId: mosqueId,
        };
  
        response = await fetch(`https://api.jumma.app/api/User/Imam/${userId}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(imamData),
        });
      } else {
        const patchData ={
          isImam: false,
         
        };
  
        response = await fetch(`https://api.jumma.app/api/User/Imam/${userId}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(patchData),
        });
      }
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response:", errorText);
        throw new Error(`Failed to update user roles: ${response.statusText}`);
      }
  
      const updatedData = [...userData];
      updatedData[userIndex] = {
        ...updatedData[userIndex],
        roles: [role1],
        isImam: role1 === "Imam",
        mescid: role1 === "Imam" ? mescid : "",
      };
  
      setUserData(updatedData); 
      setShowRoleModal(false);
      alert("User roles updated successfully!");
    } catch (error) {
      console.error("Error updating user roles:", error);
      alert(`Failed to update user roles. ${error.message}`);
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    const accessToken = Cookies.get("accessToken");
    try {
      const response = await fetch(
        `https://api.jumma.app/api/User/${editData.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(editData),
        }
      );

      if (response.ok) {
        const updatedData = [...userData];
        updatedData[editIndex] = editData;
        setUserData(updatedData);
        setShowEditModal(false);
        alert("User updated successfully!");
      } else {
        const errorData = await response.json();
        console.error("Error response data:", errorData);
        throw new Error("Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Failed to update user. Please try again.");
    }
  };


  const handleAddUserClick = () => {
    resetFormData();
    setShowAddUserModal(true);
  };

  const handleSaveNewUser = async () => {
    const accessToken = Cookies.get("accessToken");
  
    try {
      if (!editData.firstName || !editData.lastName || !editData.email || !editData.password || !editData.rePassword || !editData.phoneNumber) {
        throw new Error("All fields are required.");
      }
  
      // Validate that password and rePassword match
      if (editData.password !== editData.rePassword) {
        throw new Error("Passwords do not match.");
      }
  
      // Construct the new user data
      const newUser = {
        firstName: editData.firstName,
        lastName: editData.lastName,
        email: editData.email,
        password: editData.password,
        rePassword: editData.rePassword,
        phoneNumber: editData.phoneNumber,
      };
  
      // Send a POST request to the API
      const response = await fetch(`https://api.jumma.app/api/User`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(newUser),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error: ${errorData.message || "Failed to add new user."}`);
      }
  
      const createdUser = await response.json();
  
      setUserData([...userData, createdUser]);
  
      setShowAddUserModal(false);
  
      resetFormData();
  
      alert("New user added successfully!");
    } catch (error) {
      console.error("Error saving new user:", error);
  
      alert(`Failed to save new user: ${error.message}`);
    }
  };
  

  const resetFormData = () => {
    setEditData({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      rePassword: "",
      phoneNumber: "",
      roles: [],
      mescid: "",
    });
    setSelectedRoles({
      role1: "",
      role2: "",
      mescid: "",
    });
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
    setShowRoleModal(false);
    setShowDeleteConfirmModal(false);
    setShowAddUserModal(false);
    resetFormData();
  };

  const handleDeleteClick = (userId) => {
    setUserToDelete(userId);
    setShowDeleteConfirmModal(true);
  };

  const confirmDeleteUsers = async () => {
    const accessToken = Cookies.get("accessToken");
    try {
      const response = await fetch(`https://api.jumma.app/api/User/${userToDelete}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const updatedData = userData.filter(user => user.id !== userToDelete);
        setUserData(updatedData);
        setShowDeleteConfirmModal(false);
        setUserToDelete(null);
        alert("User deleted successfully!");
      } else {
        throw new Error("Failed to delete user");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      alert(`Failed to delete user: ${error.message}`);
    }
  };

  return (
    <div>
      <MainAdminAddUser
        editData={editData}
        handleAddUserClick={handleAddUserClick}
        handleCloseModal={handleCloseModal}
        handleSaveNewUser={handleSaveNewUser}
        handleEditChange={handleEditChange}
        selectedRoles={selectedRoles}
        setSelectedRoles={setSelectedRoles}
        mescidData={mescidData}
        showAddUserModal={showAddUserModal}
      />

      {isLoading ? (
        <p>Loading users...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <MainAdminUserList
          userData={userData}
          handleRoleClick={handleRoleClick}
         
          showEditModal={showEditModal}
          handleCloseModal={handleCloseModal}
          handleSaveEdit={handleSaveEdit}
          handleSaveRoles={handleSaveRoles}
          handleEditChange={handleEditChange}
          editData={editData}
          showRoleModal={showRoleModal}
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
          confirmDeleteUsers={confirmDeleteUsers}
          mescidData={mescidData}
          showDeleteConfirmModal={showDeleteConfirmModal}
          handleDeleteClick={handleDeleteClick}
        />
      )}
    </div>
  );
}
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ProductContent.css";
import { useContextAdmin } from "../../context/AdminContext";
import Cookies from "js-cookie";
import edit from "../../assets/images/edit.png";
import searchIcon from "../../assets/images/searchicon.png";

function ProductContent() {
  const { adminProducts, setAdminProducts, searchFilterProducts, query, setQuery } = useContextAdmin();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState({});
  const [toggleStates, setToggleStates] = useState({});

  useEffect(() => {
    const products = Array.isArray(adminProducts?.products)
      ? adminProducts.products
      : [];
    if (query) {
      setFilteredProducts(searchFilterProducts());
    } else {
      setFilteredProducts(products);
    }
    
    // Initialize toggle states
    const initialToggleStates = {};
    products.forEach(product => {
      initialToggleStates[product.id] = product.isActive;
    });
    setToggleStates(initialToggleStates);
  }, [query, adminProducts, searchFilterProducts]);

  const handleToggleActive = async (productId, currentStatus) => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      console.error("No access token found");
      return;
    }
  
    if (isLoading[productId]) return;
  
    try {
      setIsLoading(prev => ({ ...prev, [productId]: true }));
  
      // Call the API to update the status
      const response = await fetch(
        `https://api.jumma.app/api/Product/${productId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ isActive: !currentStatus }),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const updatedProduct = await response.json();
  
      // Update the adminProducts state
      setAdminProducts((prevState) => ({
        ...prevState,
        products: prevState.products.map((product) =>
          product.id === productId
            ? { ...product, isActive: updatedProduct.isActive }
            : product
        ),
      }));
  
      // Update the toggle state after successful API call
      setToggleStates(prev => ({ ...prev, [productId]: updatedProduct.isActive }));
  
      // Also update the filteredProducts if needed
      setFilteredProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId
            ? { ...product, isActive: updatedProduct.isActive }
            : product
        )
      );
  
      // Reload the page after the status is updated
      window.location.reload();
  
    } catch (error) {
      console.error("Failed to toggle product status:", error.message);
    } finally {
      setIsLoading(prev => ({ ...prev, [productId]: false }));
    }
  };
  

  return (
    <div className="product-content">
      <div className="search-container">
        <div
          className="search-wrapper"
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "5px 10px",
            width: "183px",
          }}
        >
          <img
            src={searchIcon}
            alt="Search Icon"
            className="search-icon"
            style={{ width: "20px", marginRight: "10px" }}
          />
          <input
            value={query || ''}
            onChange={(e) => setQuery(e.target.value)}
            style={{
              border: "none",
              outline: "none",
              width: "100%",
              fontSize: "14px",
              padding: "5px",
              backgroundColor: "inherit",
            }}
            type="text"
            placeholder="Search"
          />
        </div>
      </div>

      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Məhsul
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Kateqoriya
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Subkateqoriya
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Qiymət
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Details
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Edit
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <tr key={product.id} className="hover:bg-gray-50">
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <img
                      src={product.imageUrl}
                      alt={product.name || "Default Title"}
                      className="object-cover w-16 h-16 rounded"
                    />
                    <span className="ml-4 font-medium text-gray-900 truncate max-w-xs">
                      {product.name || "Default Title"}
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-gray-500 truncate max-w-xs">
                    {product.categoryName || "N/A"}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-gray-500 truncate max-w-xs">
                    {product.subCategoryName || "N/A"}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-gray-500">
                    {product.salePrice ? (
                      <>
                        <span className="font-bold text-lg text-primaryBlackDiff">
                          {product.salePrice} AZN
                        </span>
                        <span className="line-through text-gray-500 text-sm ml-2">
                          {product.price} AZN
                        </span>
                      </>
                    ) : (
                      <span className="font-bold text-lg text-primaryBlackDiff">
                        {product.price} AZN
                      </span>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={toggleStates[product.id] || false}
                      onChange={() => handleToggleActive(product.id, product.isActive)}
                      disabled={isLoading[product.id]}
                    />
                    <span className={`slider ${isLoading[product.id] ? 'opacity-50' : ''}`}></span>
                  </label>
                </td>
                <td className="px-6 py-4">
                  <Link
                    to={`/product-details/${product.id}`}
                    className="text-gray-400 border border-gray-400 px-4 py-2 rounded-sm hover:bg-gray-100 transition-colors duration-200"
                  >
                    View
                  </Link>
                </td>
                <td className="px-6 py-4">
                  <Link
                    to={`/editproduct/${product.subCategoryId}`}
                    className="flex justify-center items-center rounded"
                  >
                    <img className="w-6 h-6" src={edit} alt="Edit" />
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="px-6 py-4 text-center text-gray-500">
                No products available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ProductContent;

import React from "react";
import footerimage from "../../assets/images/footerimg.png";
// import google from "../../assets/images/googleplay.svg";
// import app from "../../assets/images/appstore.svg";
import mastercard from "../../assets/images/mastercard.svg";
import visa from "../../assets/images/visa.svg";
import bag from "../../assets/images/bag.svg";
import './Footer.css'

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-border">
        <div className="footer-container">
          {/* left part */}
          <div className="footer-left">
            <div className="footer-image">
              <img
                src={footerimage}
                className="object-cover w-full"
                alt="icon"
              />
            </div>
            <div className="footer-links">
              {/* <div className="footer-links-row">
                <div className="w-full lg:w-[162px]">
                  <ul>
                    <li className="footer-link-item">Food</li>
                    <li className="footer-link-item">Breakfast</li>
                    <li className="footer-link-item">Snack</li>
                    <li className="footer-link-item">Meat, Fish, Chicken</li>
                    <li className="footer-link-item">Drinks</li>
                  </ul>
                </div>
                <div className="w-full lg:w-[162px]">
                  <ul>
                    <li className="footer-link-item">Personal care</li>
                    <li className="footer-link-item">General cleaning</li>
                    <li className="footer-link-item">Home life</li>
                    <li className="footer-link-item">Herbal products</li>
                    <li className="footer-link-item">Campaigns</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          {/* right part */}
          <div className="footer-right"  >
            <div className="footer-mainTexts">
            <div className="contactUs">
              <h3>E-poçt vasitəsilə bizimlə əlaqə saxlayın</h3>
              <p>jummateam@gmail.com</p>
            </div>
            {/* <div className="footer-texts">
            <h3>Abunə olun!</h3>
          <p>
              Abunə olmaqla siz eksklüziv bazar yeniləmələri, xüsusi təkliflər və
              yeni halal məhsullara daha sürətli çıxış əldə edəcəksiniz.
          </p>
            </div> */}
            </div>
          
            {/* <div className="footer-subscribe">
              <input
                type="email"
                placeholder="Email address"
                className="footer-input"
              />
              <button className="footer-button">Subscribe</button>
            </div> */}

            {/* <div className="footer-apps">
              <div className="footer-app">
                <img src={google} alt="Google Play" className="object-cover" />
                <div className="footer-app-text">
                  <p className="footer-app-subtitle">Download now</p>
                  <p className="footer-app-title">Google Play</p>
                </div>
              </div>

              <div className="footer-app">
                <img src={app} alt="App Store" className="object-cover" />
                <div className="footer-app-text">
                  <p className="footer-app-subtitle">Download now</p>
                  <p className="footer-app-title">App Store</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div>
          <p className="footer-bottom-text"  style={{ color:"black"}}>
            © 2024 Jumma.az all rights reserved.
          </p>
        </div>

        <div className="footer-bottom-icons">
          <div className="footer-bottom-icon">
          <img src={mastercard} className="object-cover size-full" alt="Mastercard" style={{ filter: "grayscale(100%) brightness(0%)" }} />

          </div>
          <div className="footer-bottom-icon">
            <img src={visa} alt="Visa" className="object-cover size-full"  style={{ filter: "grayscale(100%) brightness(0%)" }} />
          </div>
          <div className="footer-bottom-icon">
            <img src={bag} alt="Bag" className="object-cover size-full"  style={{ filter: "grayscale(100%) brightness(0%)" }}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

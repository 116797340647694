import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useContextAdmin } from "../context/AdminContext";
import "../components/SettingsContent(Admin)/AddComment.css";

export default function AdminReviewDetail() {
  const [comment, setComment] = useState("");
  const [commentReplies, setCommentReplies] = useState([]); 
  const { adminReviews } = useContextAdmin();
  const { slug } = useParams();

 
  const product = adminReviews.find((review) => review.id === Number(slug));

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Comment submitted:", comment);
    setCommentReplies((prevReplies) => [...prevReplies, { reply: comment }]); 
    setComment(""); 
  };

  if (!product) {
    return <div>Review not found</div>; 
  }

  return (
    <div className="add-comment-container">
      <div className="comment-box">
        <div className="comment-details">
          <div className="comment-header">
            <span className="comment-author">{product.email}</span>
            <span className="comment-date">{product.date}</span>
          </div>
          <div className="comment-rating">
            <span>{"⭐".repeat(product.rating)}</span> 
          </div>
          <p className="comment-text">{product.review}</p>
          <div className="comment-actions">
            <span className="likes-count">{product.likes}</span>
            
          </div>
        </div>
        <button className="like-button">Like the comment</button>
      </div>
      


      {commentReplies.map((item, index) => (
        <p className="reply" key={index}>{item.reply}</p>
      ))}

      <div className="reply-section">
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          required
          placeholder="Write something"
          className="reply-textarea"
        />
        <button type="submit" className="send-button" onClick={handleSubmit}>
          Send
        </button>
      </div>
    </div>
  );
}

import { useState } from "react";
import Cookies from "js-cookie"; 

function MainChangePassword() {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    reNewPassword: "",
  });
  const [successMessage, setSuccessMessage] = useState(""); // For success message
  const [errorMessage, setErrorMessage] = useState(""); // Optional: For handling errors

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const accessToken = Cookies.get("accessToken");
    console.log(accessToken);
    const requestBody = {
      currentPassword: formData.currentPassword,
      newPassword: formData.newPassword,
      reNewPassword: formData.reNewPassword,
    };

    try {
      const response = await fetch("https://api.jumma.app/api/Profile/UpdatePassword", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to update the password");
      }

      const data = await response.json();
      console.log("Password updated successfully:", data);
      
  
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Parol uğurla yeniləndi!");
      setSuccessMessage(""); // Clear success message if any
    }
  };

  return (
    <div className="pt-[16px] pb-[31px] p-4 rounded-md border border-[#747474] flex flex-col mt-[40px]">
      <h2 className="font-bold text-lg mb-[19px]">Şifrəni Dəyiş</h2>
      
      {/* Success message */}
      {successMessage && <p className="text-green-500">{successMessage}</p>}
      
      {errorMessage && <p className="text-green-500 mb-4 p-4 bg-green-50 border border-green-200 rounded-m">{errorMessage}</p>}
   

      
      <form onSubmit={handleSubmit} className="space-y-4 flex flex-col flex-grow">
        <div className="border border-[#DFDFDF] rounded-md">
          <input
            type="password"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleChange}
            placeholder="Cari şifrə"
            className="w-full bg-transparent outline-none pl-[21px] py-[15px]"
          />
        </div>
        <div className="border border-[#DFDFDF] rounded-md">
          <input
            type="password"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            placeholder="Yeni şifrə"
            className="w-full bg-transparent outline-none pl-[21px] py-[15px]"
          />
        </div>
        <div className="border border-[#DFDFDF] rounded-md">
          <input
            type="password"
            name="reNewPassword"
            value={formData.reNewPassword}
            onChange={handleChange}
            placeholder="Şifrəni yenidən daxil et"
            className="w-full bg-transparent outline-none pl-[21px] py-[15px]"
          />
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="w-full rounded-md h-[52px] font-semibold bg-[#C7C7C7] text-[#898989] py-2"
          >
            Şifrəni Dəyiş
          </button>
        </div>
      </form>
    </div>
  );
}

export default MainChangePassword;

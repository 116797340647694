import React from "react";
import Services from "../../components/Services";
import Address from "../../components/Address Section/address";
const AddAddress = () => {
  return (
    <main className="flex-1 px-4 flex flex-col items-center">
      <div className="w-full max-w-screen-xl mt-4 space-y-8">
        <Address></Address>
        <Services />
      </div>
    </main>
  );
};

export default AddAddress;

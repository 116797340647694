import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const SimplePopup = ({ message, onClose }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
    navigate('/login');
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      style={{ zIndex: 100000 }}
    >
      <div className="bg-white p-6 rounded-lg shadow-lg w-[400px] max-w-[90%]">
        <h2 className="text-center text-xl font-semibold text-green-500 mb-4">
          Şifrəni Bərpa Etmək Üçün E-poçt
        </h2>
        <h3 className="text-center text-green-500 mb-6">{message}</h3>
        <button
          onClick={handleClose}
          className="w-full bg-white text-red-500 px-4 py-2 rounded hover:bg-red-50 transition-colors"
        >
          Bağla
        </button>
      </div>
    </div>
  );
};

const ForgetPassword = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);  

  const form = useForm();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  async function onSubmit(data) {
    const { email } = data;

    if (!email) {
      setPopupMessage("Zəhmət olmasa email daxil edin.");
      setShowPopup(true);
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(
        `https://api.jumma.app/api/Account/ForgotPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        setPopupMessage("Göndərildi");
      } else {
        setPopupMessage("Email tapılmadı və ya problem baş verdi.");
      }
      setShowPopup(true);
    } catch (error) {
      console.error("Link göndərmə zamanı xəta baş verdi:", error);
      setPopupMessage("Link göndərmə zamanı xəta baş verdi.");
      setShowPopup(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="min-h-screen flex justify-center items-center">
      <div className="w-[408px] my-[30px] bg-[#FFFFFF] mx-auto p-[15px] rounded-[8px]">
        <div className="flex justify-center items-center flex-col">
          <p className="font-normal text-[22px] text-[#0C0404] mb-[22px]">
            E-poçt ünvanınızı daxil edin
          </p>
          <p className="mb-[20px]">
            Onu sıfırlamaq üçün sizə link göndərəcəyik.
          </p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <div className="w-full h-[52px] rounded-[4px] border border-[#f5f5f5] mb-[8px]">
            <input
              type="email"
              {...register("email", {
                required: "Please fill the email",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Write correct email format",
                },
              })}
              placeholder="Email*"
              className="w-full h-full pl-[25px] font-normal text-[14px] bg-transparent text-[#545454] outline-none border"
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
            )}
          </div>

          <button
            type="submit"
            className="w-full bg-[#05ac58] rounded-[4px] text-center font-bold text-[20px] text-white py-[10px] mt-[33px] mb-[33px] flex justify-center items-center"
            disabled={isLoading} 
          >
            {isLoading ? (
              <>
                <div className="loader w-[20px] h-[20px] border-4 border-t-transparent border-white rounded-full animate-spin mr-2"></div>
                Göndərilir
              </>
            ) : (
              "Göndər"
            )}
          </button>
        </form>

        {showPopup && (
          <SimplePopup
            message={popupMessage}
            onClose={() => setShowPopup(false)}
          />
        )}
      </div>
    </div>
  );
};

export default ForgetPassword;

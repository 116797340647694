import React from "react";
// import searchIcon from "../assets/images/searchicon.png";
import "../components/Admin Panel/dashboard.css";
import AdminSidebar from "../components/AdminSidebar";
// import { useContextAdmin } from "../context/AdminContext";

export default function AdminLayout({ children }) {
  // const{query,setQuery}=useContextAdmin()
  return (
    <div>
      <div className="flex min-h-screen bg-gray-100 ">
        <AdminSidebar></AdminSidebar>
        <div className="content-area flex-1 flex flex-col overflow-hidden">
          <div className="upper-side shadow-sm z-10">
            {/* <h2 className="heading">
              {activeContent.charAt(0).toUpperCase() + activeContent.slice(1)}
            </h2> */}
          </div>
          {/* <div className="search-container">
            <div
              className="search-wrapper"
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "5px 10px",
                width: "183px",
              }}
            >
              <img
                src={searchIcon}
                alt="Search Icon"
                className="search-icon"
                style={{ width: "20px", marginRight: "10px" }}
              />
              <input
                value={query}
                onChange={(e)=>setQuery(e.target.value)}
                style={{
                  border: "none",
                  outline: "none",
                  width: "100%",
                  fontSize: "14px",
                  padding: "5px",
                  backgroundColor: "inherit",
                }}
                type="text"
                placeholder="Search"
              />
            </div>
          </div> */}
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
            {" "}
            <div className="max-w-7xl mx-auto py-6 ">
              {children}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { useLocation } from 'react-router-dom';
import banner from "../assets/images/banner.png";
import Header from "../components/Header";
import Footer from "../components/Footer/Footer"; // Import the Footer component

function AppLayout({ children }) {
  const location = useLocation();
  
  const pathsWithoutHeaderAndBanners = [
    '/becomeaseller',
    '/register',
    '/login',
    '/location',
    '/add-card-donation',
    '/forgetpassword'
    
  ];

  const isSimplePage = pathsWithoutHeaderAndBanners.includes(location.pathname);
  const isProductPage = location.pathname.startsWith('/product-card/');

  return (
    <div className="flex min-h-screen bg-gray-100 ">
      {/* Left Banner - Sticky */}
      {!isSimplePage && (
        <aside className="bg-gray-200 hidden lg:block sticky top-0 h-screen w-1/5">
          <img src={banner} alt="Reklam" className="object-cover w-full h-full" />
        </aside>
      )}
      
      {/* Middle Section */}
      <div className={`flex-1 flex flex-col items-center ${isSimplePage ? 'w-full' : ''}`}>
        <div className={`w-full ${isSimplePage ? 'max-w-[475px]' : 'max-w-[1000px]'}`}>
          {!isSimplePage && <Header />}
          <main className="flex-1 flex flex-col ">
            {children}
          </main>
          {isProductPage && <Footer />} 
        </div>
      </div>
      
      {/* Right Banner - Sticky */}
      {!isSimplePage && (
        <aside className="bg-gray-200 hidden lg:block sticky top-0 h-screen w-1/5">
          <img src={banner} alt="Reklam" className="object-cover w-full h-full" />
        </aside>
      )}
    </div>
  );
}

export default AppLayout;
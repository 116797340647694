import "./MainAdminMescid.css";

import bin from "../../assets/images/bin.png";
import { useMainContextAdmin } from "../../context/MainAdminContext";
import MescidList from "../MescidList";
import MescidAdd from "../MescidAdd";

export default function MainAdminMescid() {
  const { selectedMescids, handleDeleteClick } = useMainContextAdmin();

  return (
    <div>
      <MescidAdd></MescidAdd>
      {selectedMescids.length > 0 && (
        <button style={{marginTop:"-100px"}} className="deleteSelected" onClick={handleDeleteClick}>
          <img src={bin} alt="Delete"  />
        </button>
      )}
      <MescidList></MescidList>
    </div>
  );
}

import React, { useState } from 'react';
import plus from "../../assets/images/plus.png";
import close from "../../assets/images/close.png";
import './addcard.css';

const productData = {
  basePrice: 19, 
};

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cards, setCards] = useState([]);
  const [quantity, setQuantity] = useState(1); 
  const [formData, setFormData] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleQuantityChange = (e) => {
    const value = Math.max(1, e.target.value);
    setQuantity(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newCard = { ...formData };
    setCards(prevCards => [...prevCards, newCard]);
    setIsModalOpen(false);
    setFormData({
      cardNumber: '',
      expiryDate: '',
      cvv: ''
    });
  };

  const calculateTotalPrice = () => {
    return (productData.basePrice * quantity).toFixed(2);
  };

  const calculateTax = (price) => {
    return (price * 0.2).toFixed(2);
  };

  const calculateTotalWithTax = (price) => {
    return (price + parseFloat(calculateTax(price))).toFixed(2);
  };

  const totalPrice = calculateTotalPrice();
  const totalWithTax = calculateTotalWithTax(parseFloat(totalPrice));

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formatCardNumber = (number) => {
    const visibleStart = number.slice(0, 6);
    const visibleEnd = number.slice(-4);
    const maskedMiddle = number.slice(6, -4).replace(/\d/g, '*');
    const maskedNumber = `${visibleStart}${maskedMiddle}${visibleEnd}`;
    return maskedNumber.match(/.{1,4}/g).join(' ');
  };

  return (
    <div className="container">
      <div className="content-wrapper">
        <div className="right-container-card">
          <div className="small">
            <p>Kart məlumatları</p>
          </div>
          <div className="big" id="big-container">
            <div className="small-in-big" onClick={openModal}>
              <img src={plus} alt="Add a new card" />
              <p>Yeni kart əlavə et</p>
            </div>
            {cards.map((card, index) => (
              <div key={index} className="card-display">
                <div className="card-logo">VISA</div>
                <div className="card-number">{formatCardNumber(card.cardNumber)}</div>
                <div className="card-expiry">{card.expiryDate}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="left-container-card">
          <div className="order-summary">
            <h2>Sifariş Xülasəsi</h2>
            <div className="item">
              <span>Ara Toplam</span>
              <span>{totalPrice} ₼</span>
            </div>
            <div className="item">
              <span>KDV</span>
              <span>{calculateTax(parseFloat(totalPrice))} ₼</span>
            </div>
            <div className="item last-item">
              <span>KDV Dahil</span>
              <span>{totalWithTax} ₼</span>
            </div>
            <div className="total">
              <span>Toplam</span>
              <span style={{ fontWeight: "700" }}>{totalWithTax} ₼</span>
            </div>
            <input
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
              min="1"
              style={{ margin: "10px 0" }}
            />
            <button>İNDİ YOXLA</button>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="f-line">
              <h3 style={{ fontWeight: "700" }} className="title">Add a new card</h3>
              <span className="close" onClick={closeModal}>
                <img src={close} alt="Close modal" />
              </span>
            </div>
            <div className="separator"></div>
            <form onSubmit={handleSubmit}>
              <input type="text" id="cardNumber" placeholder="Card number*" value={formData.cardNumber} onChange={handleInputChange} />
              <input type="text" id="expiryDate" placeholder="Expiry date*" value={formData.expiryDate} onChange={handleInputChange} />
              <input type="text" id="cvv" placeholder="CVV*" value={formData.cvv} onChange={handleInputChange} />
              <button type="submit">Saxla</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import Language from "./Language";
import Search from "./Search";
import Navbar from "./Navbar/Navbar";
// import img from "../assets/images/headerimg.svg";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  return (
    <header
      className={`bg-white shadow-lg px-4 ${
        isScrolled ? "" : "sticky top-0 z-50"
      }`}
    >
      <div className="max-w-screen-xl mx-auto text-center flex flex-col space-y-4 md:space-y-0 md:justify-between items-center">
        <div
          className={`w-full max-w-screen-xl ${
            isScrolled ? "" : "sticky top-0 z-50"
          }`}
        >
          <div className="h-full  font-bold text-[25px] bg-gray-200 flex items-center justify-center text-center p-4 py-8">
            <p>Burada sizin reklamınız ola bilər!</p>
          </div>
        </div>

        <Language />
        <Search />
        <Navbar />
      </div>
    </header>
  );
};

export default Header;

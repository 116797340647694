import React from "react";
import EditProduct from "../../components/ProductContent(Admin)/EditProduct";

const Edit = () => {
  return (
            <EditProduct />
  );
};

export default Edit;

import React from "react";
import Services from "../../components/Services";
import ProductCard from "../../components/Product-Card/ProductCard";

const ProductPage = () => {
  return (
 
      <div className="w-full max-w-screen-xl mt-4 space-y-8">
        <ProductCard></ProductCard>
        <Services />
      </div>
 
  );
};

export default ProductPage;

import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useContextAdmin } from "../../context/AdminContext";
import upload from "../../assets/images/cloud.png";

export default function EditProductForm() {
  const {
    adminProducts,
    setAdminProducts,
    categories,
    subCategories,
    fetchSubCategories,
    handleSaveProduct,
    fetchAllProducts,
  } = useContextAdmin();
  const { slug } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [product, setProduct] = useState({
    id: "",
    subCategoryId: "",
    name: "",
    description: "",
    price: "",
    weight: "",
    width: "",
    height: "",
    imageUrl: null,
    categoryId: "",
  });


  const fetchProductData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      await fetchAllProducts();

      const findProduct = adminProducts.products.find(
        (p) => p.subCategoryId === parseInt(slug, 10)
      );

      if (findProduct) {
        setProduct({
          id: findProduct.id,
          subCategoryId: findProduct.subCategoryId,
          name: findProduct.name || "",
          description: findProduct.description || "",
          price: findProduct.price || "",
          weight: findProduct.weight || "",
          width: findProduct.width || "",
          height: findProduct.height || "",
          imageUrl: findProduct.imageUrl,
          categoryId: findProduct.categoryId || "",
        });
        setSelectedCategory(findProduct.categoryId || "");

        if (findProduct.categoryId) {
          await fetchSubCategories(findProduct.categoryId);
        }
      } else {
        setError("Product not found");
      }
    } catch (err) {
      console.error("Error fetching product data:", err);
      setError("Failed to fetch product data");
    } finally {
      setLoading(false);
    }
  }, [adminProducts.products, slug, fetchSubCategories,fetchAllProducts]);

  useEffect(() => {
    fetchProductData();
  }, [fetchProductData]); // Updated dependency array

  const handleCategoryChange = (e) => {
    const newCategoryId = e.target.value;
    setProduct((prev) => ({
      ...prev,
      categoryId: newCategoryId,
      subCategoryId: "",
    }));
    fetchSubCategories(newCategoryId);
    setSelectedCategory(newCategoryId);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'categoryId') {
      setSelectedCategory(value);
      setProduct(prev => ({
        ...prev,
        [name]: value,
        subCategoryId: ''
      }));
      fetchSubCategories(value);
    } else {
      setProduct(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setProduct((prev) => ({
        ...prev,
        imageUrl: URL.createObjectURL(file),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    const accessToken = Cookies.get('accessToken');
    if (!accessToken) {
      setError('No access token found');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('Id', product.id);
      formData.append('SubCategoryId', product.subCategoryId.toString());
      formData.append('Name', product.name);
      formData.append('Description', product.description);
      formData.append('Price', product.price.toString());
      formData.append('Weight', product.weight.toString());
      formData.append('Width', product.width.toString());
      formData.append('Height', product.height.toString());
      
      if (selectedFile) {
        formData.append('FormFile', selectedFile);
      }

      const response = await fetch(`https://api.jumma.app/api/Product/${product.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const updatedProduct = await response.json();
      
      await handleSaveProduct(updatedProduct);

      await fetchProductData();
      navigate('/admin-panel/product');
    } catch (err) {
      console.error('Error updating product:', err);
      setError(err.message);
    }
  };
  const handleDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this product?")) {
      return;
    }

    setError(null);

    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      setError("No access token found");
      return;
    }

    try {
      const response = await fetch(
        `https://api.jumma.app/api/Product/${product.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setAdminProducts((prevState) => ({
        ...prevState,
        products: prevState.products.filter((p) => p.id !== product.id),
      }));

      navigate("/admin-panel/product");
    } catch (err) {
      console.error("Error deleting product:", err);
      setError(err.message);
    }
  };

  if (loading) return <div className="p-4">Loading...</div>;
  if (error) return <div className="p-4 text-red-500">{error}</div>;

  return (
    <div className="max-w-4xl h-[100px] mx-auto p-6  ml-[15px]">
      <h2 className="text-2xl font-bold mb-6">{product.name}</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex space-x-6">
          <div className="w-1/3">
            <div className="mb-6">
              {product.imageUrl ? (
                <img
                  src={product.imageUrl}
                  alt="Product preview"
                  className="w-full h-64 object-cover rounded-lg"
                />
              ) : (
                <div className="w-full h-64 bg-gray-200 rounded-lg flex items-center justify-center">
                  No image
                </div>
              )}
            </div>
          </div>

          <div className="w-2/3 space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={product.name}
                onChange={handleInputChange}
                className="mt-1 block w-[484px] outline-none bg-white border border-gray-300"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                name="description"
                value={product.description}
                onChange={handleInputChange}
                className="mt-1 block w-[484px] outline-none bg-white border border-gray-300"
                rows="3"
              />
            </div>

            <div className="mt-1 mb-4 bg-white w-[40px] h-[120px] flex justify-center items-center ">
              <label
                htmlFor="image-upload"
                className="cursor-pointer bg-white w-[180px] h-[140px] flex justify-center items-center"
              >
                <img
                  className="w-[31px] h-[31px]"
                  src={upload}
                  alt="Upload icon"
                />
              </label>
              <input
                id="image-upload"
                type="file"
                onChange={handleFileChange}
                className="hidden outline-none"
                accept="image/*"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Category
              </label>
              <select
                name="categoryId"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="mt-1 block w-[484px] h-[30px] outline-none bg-white border border-gray-300"
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Subcategory
              </label>
              <select
                name="subCategoryId"
                value={product.subCategoryId}
                onChange={handleInputChange}
                className="mt-1 block w-[484px] outline-none h-[30px] bg-white border border-gray-300"
              >
                <option value="">Select sub-category</option>
                {subCategories.map((subCategory) => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Price
              </label>
              <input
                type="number"
                id="price"
                name="price"
                value={product.price}
                onChange={handleInputChange}
                className="outline-none block w-full max-w-[138px] h-[30px] px-2 bg-white border border-gray-300"
                required
              />
            </div>

            <div className="flex flex-col gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Width
                </label>
                <input
                  type="number"
                  name="width"
                  value={product.width}
                  onChange={handleInputChange}
                  className="block  outline-none w-full max-w-[138px] h-[30px] px-2 bg-white border border-gray-300"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Height
                </label>
                <input
                  type="number"
                  name="height"
                  value={product.height}
                  onChange={handleInputChange}
                  className="block w-full outline-none max-w-[138px] h-[30px] px-2 bg-white border border-gray-300"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Weight
                </label>
                <input
                  type="number"
                  name="weight"
                  value={product.weight}
                  onChange={handleInputChange}
                  className="block w-full outline-none max-w-[138px] h-[30px] px-2 bg-white border border-gray-300"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center space-x-4 pb-6 ">
          <button
            type="button"
            onClick={handleDelete}
            className="flex w-[123px] h-[37px] px-4 py-2  rounded	border-radius: 0.25rem; items-center justify-center  border border-red-400 text-red-400"
          >
            Delete
          </button>
          <button
            type="submit"
            className="w-[123px] h-[37px]  px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

import React, { useState, useEffect } from "react";
// import stars from "../../assets/images/stars.png";
import star from "../../assets/images/star.png";
import starFilled from "../../assets/images/starFilled.png";
import { useContextApp } from "../../context/AppContext";
import Cookies from "js-cookie";
const ProductDescription = ({ productDetail, activeTab, setActiveTab }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rating, setRating] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [reviewContent, setReviewContent] = useState("");
  const [submitting, setSubmitting] = useState(false);
const{    isLoggedIn}=useContextApp()
  const API_BASE_URL = "https://api.jumma.app";

  // Fetch product description
  useEffect(() => {
    const fetchProductDescription = async () => {
      if (!productDetail || !productDetail.id) {
        setError("Product ID is undefined");
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`${API_BASE_URL}/ActiveProduct/${productDetail.id}`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        if (data && data.description) {
          productDetail.description = data.description;
        } else {
          setError("No description found in the response");
        }
      } catch (error) {
        console.error("Failed to fetch product description:", error);
        setError("Failed to load product description. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchProductDescription();
  }, [productDetail]);

  // Fetch reviews
  useEffect(() => {
    const fetchReviews = async () => {
      if (!productDetail?.id) return;

      try {
        const response = await fetch(`${API_BASE_URL}/api/Review/Product/${productDetail.id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch reviews');
        }
        const data = await response.json();
        setReviews(data.reviews || []);
      } catch (error) {
        console.error("Failed to fetch reviews:", error);
        setError("Failed to load reviews. Please try again later.");
      }
    };

    fetchReviews();
  }, [productDetail?.id]);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleSubmitReview = async (e) => {
    const accessToken = Cookies.get("accessToken");
    e.preventDefault();
    if (!rating || !reviewContent || !productDetail?.id) {
      alert("Please provide both rating and review content");
      return;
    }

    if (!isLoggedIn) {
      alert("You must be logged in to submit a review.");
      return;
    }

    setSubmitting(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/api/Review`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          productId: productDetail.id,
          content: reviewContent,
          rating: rating
        })
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('Unauthorized. Please log in again.');
        }
        throw new Error('Failed to submit review');
      }

      const newReview = await response.json();

      // Update the reviews list with the new review
      setReviews(prevReviews => [newReview, ...prevReviews]);

      // Reset form
      setReviewContent("");
      setRating(0);
      alert("Review submitted successfully!");
    } catch (error) {
      console.error("Error submitting review:", error);
      setError(error.message || "Failed to submit review. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="under" style={{height:"full"}}>
      <div className="tabs">
        <h2
          style={{ textDecoration: "none" }}
          className={`underline ${activeTab === "description" ? "active" : ""}`}
          onClick={() => setActiveTab("description")}
        >
          Description
        </h2>
        <h2
          className={`reviews ${activeTab === "reviews" ? "active" : ""}`}
          onClick={() => setActiveTab("reviews")}
        >
          Reviews ({reviews.length})
        </h2>
        <div
          className={`underline-container ${
            activeTab === "reviews" ? "active-reviews" : "active-description"
          }`}
        />
      </div>

      {error && <div className="error-message">{error}</div>}

      {activeTab === "description" ? (
        <div className="product-description active">
          <p className="product-description-text">
            {productDetail.description || "No description available."}
          </p>
        </div>
      ) : (
        <div className="product-reviews active">
          <form onSubmit={handleSubmitReview}>
            <label className="review-label">Write a review</label>
            <textarea
              value={reviewContent}
              onChange={(e) => setReviewContent(e.target.value)}
              placeholder="Add your review..."
              required
            />
            <div className="rating-stars">
              <label className="rating">Rating:</label>
              <div  className="rating">
                <div className="stars">
                  {[1, 2, 3, 4, 5].map((starValue) => (
                    <img
                      key={starValue}
                      src={starValue <= rating ? starFilled : star}
                      alt={`Star ${starValue}`}
                      onClick={() => handleStarClick(starValue)}
                      style={{ cursor: "pointer" }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <button type="submit" >
              {submitting ? "Sending..." : "Send"}
            </button>
          </form>

          <div className="comment-separator" />
          <div className="example-comment">
            {reviews.map((review, index) => (
              <div key={index}>
                <div className="f-line">
                  <p className="user">{review.email || 'Anonymous'}</p>
                  <p className="date">
                    {new Date(review.createdAt).toLocaleDateString()}
                  </p>
                </div>
                <div style={{display:"flex"}} className="stars">
                  {[...Array(5)].map((_, i) => (
                    <img
                      key={i}
                      src={i < review.rating ? starFilled : star}
                      alt={`Star ${i + 1}`}
                      style={{height:"17px", width:"17px"}}
                    />
                  ))}
                </div>
                <p style={{marginTop:"5px"}} className="comment-content">{review.content}</p>
                
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDescription;
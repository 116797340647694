import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import "./Navbar.css";
import { useContextApp } from "../../context/AppContext";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [openCategoryId, setOpenCategoryId] = useState(null);
  const { handleSelectedSubcategory } = useContextApp();
  const navRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleCategory = (categoryId) => {
    console.log("Toggling category:", categoryId);
    setOpenCategoryId((prevId) => {
      const newId = prevId === categoryId ? null : categoryId;
      console.log("New open category ID:", newId);
      return newId;
    });
  };

  const fetchCategories = async () => {
    const accessToken = Cookies.get("accessToken");
    try {
      const response = await fetch(`https://api.jumma.app/Categories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched Categories:", data);
        setCategories(data);
      } else {
        console.error("Failed to fetch categories:", response.status);
      }
    } catch (error) {
      console.log("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    console.log("Current open category ID:", openCategoryId);
  }, [openCategoryId]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setOpenCategoryId(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <nav className="navbar " ref={navRef}>
      <div className="burger-menu" onClick={toggleMenu}>
        <div />
        <div />
        <div />
      </div>
      <ul className={isOpen ? "active" : ""}>
        {categories.map((category) => (
          <li key={category.id}>
            <Link
              to="#"
              className="p-[10px]"
              onClick={(e) => {
                e.preventDefault();
                toggleCategory(category.id);
              }}
            >
              {category.name}
            </Link>
            {openCategoryId === category.id &&
              category.subCategories &&
              category.subCategories.length > 0 && (
                <div className="dropdown-content" style={{ display: "block" }}>
                  <div className="flex flex-col left-0 items-start h-[400px]">
                    {category.subCategories.map((subCategory) => (
                      <div
                        className="flex flex-col border-b border-w-[500px] p-[10px]"
                        key={subCategory.id}
                      >
                        {/* subcategories/${subCategory.id} */}
                        <Link
                          onClick={() =>
                            handleSelectedSubcategory(subCategory.name)
                          }
                          to={`/mehsullar/:${encodeURIComponent(
                            subCategory.name
                          )}`}
                          className="font-semibold"
                        >
                          {subCategory.name}
                        </Link>
                        <span className="text-gray-500">
                          {subCategory.description}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;

import React from "react";

import "../components/Admin Panel/dashboard.css";
import MainAdminSidebar from "../components/MainAdminSidebar";

export default function MainAdminLayout({ children }) {
  return (
    <div>
      <div className="adcontainer">
        <MainAdminSidebar></MainAdminSidebar>

        <div className="content-area flex-1 ">
          <div className="upper-side">
            {/* <h2 className="heading">
              {activeContent.charAt(0).toUpperCase() + activeContent.slice(1)}
            </h2> */}
          </div>

          <main> {children}</main>
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import ContactUs from '../../components/ContactUs'

export default function ContactUsPage() {
  return (
    <div>
      <ContactUs></ContactUs>
    </div>
  )
}

import upload from "../../assets/images/uploadFile.png";
import "./AddProductContent.css";
import { useContextAdmin } from "../../context/AdminContext";

function AddProductContent() {
  const {
    handleSubmit,
    Name,
    setName,
    FormFile,
    setFormFile,
    SubCategoryId,
    setSubCategoryId,
    Description,
    setDescription,
    Price,
    setPrice,
    StockCount,
    setStockCount,
    Width,
    setWidth,
    Height,
    setHeight,
    Weight,
    setWeight,
    subCategories,
    categories,
    selectedCategory,
    setSelectedCategory,
  } = useContextAdmin();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormFile(file);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSubCategoryId(""); 
  };

  const filteredSubCategories = subCategories.filter(
    (subCategory) => subCategory.categoryId === selectedCategory
  );
  return (
    <div className="add-product-content">
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            id="Name"
            value={Name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Product name"
          />
        </div>
        <div>
          <select
            id="Category"
            value={selectedCategory}
            onChange={handleCategoryChange}
            required
          >
            <option value="" disabled>
              Select category
            </option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <select
            id="SubCategory"
            value={SubCategoryId}
            onChange={(e) => setSubCategoryId(e.target.value)}
            required
          >
            <option value="" disabled>
              Select sub-category
            </option>
            {filteredSubCategories.map((subCategory) => (
              <option key={subCategory.id} value={subCategory.id}>
                {subCategory.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-3">
          <textarea
            id="description"
            value={Description}
            onChange={(e) => setDescription(e.target.value)}
            required
            placeholder="Description"
          />
        </div>

        <div className="priceAndCurrency">
          <input
            type="number"
            id="price"
            value={Price}
            onChange={(e) => setPrice(e.target.value)}
            required
            placeholder="Price"
            style={{ width: "132px", height: "29px" }}
          />
          <span className="CurrencyofPrice">AZN</span>
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <label style={{ color: "#6F6F6F" }} htmlFor="productWidth">
            Width:
          </label>
          <input
            type="text"
            id="productWidth"
            value={Width}
            onChange={(e) => setWidth(e.target.value)}
            required
            placeholder="Width"
            style={{ width: "132px", height: "29px" }}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <label style={{ color: "#6F6F6F" }} htmlFor="productHeight">
            Height:
          </label>
          <input
            type="text"
            id="productHeight"
            value={Height}
            onChange={(e) => setHeight(e.target.value)}
            required
            placeholder="Height"
            style={{ width: "132px", height: "29px" }}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <label style={{ color: "#6F6F6F" }} htmlFor="productWeight">
            Weight:
          </label>
          <input
            type="text"
            id="productWeight"
            value={Weight}
            onChange={(e) => setWeight(e.target.value)}
            required
            placeholder="Weight"
            style={{ width: "132px", height: "29px" }}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <label style={{ color: "#6F6F6F" }} htmlFor="stock">
            Stock:
          </label>
          <input
            type="number"
            id="stock"
            value={StockCount}
            onChange={(e) => setStockCount(e.target.value)}
            required
            placeholder="Stock Quantity"
            style={{ width: "132px", height: "29px" }}
          />
        </div>

        <div style={{ marginLeft: "0px" }} className="button-group">
          <button
            type="button"
            onClick={() => {
              /* discard logic */
            }}
          >
            Discard
          </button>
          <button type="submit">Add Product</button>
        </div>
      </form>

      <div className="upload-container">
        <label htmlFor="productImage" className="custom-file-upload">
          <img
            src={upload}
            alt="Upload Icon"
            style={{ cursor: "pointer", width: "50px" }}
          />
        </label>
        <input
          type="file"
          id="productImage"
          onChange={handleImageChange}
          accept="image/*"
          style={{ display: "none" }}
        />
        {FormFile && (
          <img
            src={URL.createObjectURL(FormFile)}
            alt="Product Preview"
            style={{ width: "100px", marginTop: "10px" }}
          />
        )}
      </div>
    </div>
  );
}

export default AddProductContent;


// import React, { useState, useEffect } from "react";
// import upload from "../../assets/images/uploadFile.png";
// import "./AddProductContent.css";
// import { useContextAdmin } from "../../context/AdminContext";
// import Cookies from "js-cookie";

// function AddProductContent() {
//   const {
//     productName,
//     setProductName,
//     productImage,
//     setProductImage,
//     subCategory,
//     setSubCategory,
//     description,
//     setDescription,
//     price,
//     setPrice,
//     productWidth,
//     setProductWidth,
//     productHeight,
//     setProductHeight,
//     productWeight,
//     setProductWeight,
//   } = useContextAdmin();

//   const [subcategories, setSubcategories] = useState([]);
//   const [stockCount, setStockCount] = useState("");

//   useEffect(() => {
//     fetchSubcategories();
//   }, []);

//   const fetchSubcategories = async () => {
//     const accessToken = Cookies.get("accessToken");
//     if (!accessToken) {
//       console.error("No access token found");
//       return;
//     }
//     try {
//       const response = await fetch("https://jumma.svdev.me/api/SubCategory", {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });

//       if (!response.ok) throw new Error("Failed to fetch subcategories");

//       const data = await response.json();
//       setSubcategories(Array.isArray(data.subCategories) ? data.subCategories : data);
//     } catch (error) {
//       console.error("Failed to fetch subcategories", error);
//     }
//   };

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     setProductImage(file);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const accessToken = Cookies.get("accessToken");

//     if (!accessToken) {
//       console.error("No access token found");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("Name", productName);
//     formData.append("Description", description);
//     formData.append("Price", price);
//     formData.append("SubCategoryId", subCategory);
//     formData.append("Width", productWidth);
//     formData.append("Height", productHeight);
//     formData.append("Weight", productWeight);
//     formData.append("StockCount", stockCount);
//     if (productImage) {
//       formData.append("FormFile", productImage);
//     }

//     try {
//       const response = await fetch("https://jumma.svdev.me/api/Product", {
//         method: "POST",
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }

//       const result = await response.json();
//       console.log("Product added successfully:", result);
//       resetForm();
//     } catch (error) {
//       console.error("Error adding product:", error);
//     }
//   };

//   const resetForm = () => {
//     setProductName("");
//     setDescription("");
//     setPrice("");
//     setSubCategory("");
//     setProductWidth("");
//     setProductHeight("");
//     setProductWeight("");
//     setProductImage(null);
//     setStockCount("");
//   };

//   return (
//     <div className="add-product-content">
//       <form onSubmit={handleSubmit}>
//         <div>
//           <input
//             type="text"
//             id="productName"
//             value={productName}
//             onChange={(e) => setProductName(e.target.value)}
//             required
//             placeholder="Product name"
//           />
//         </div>
//         <div>
//           <select
//             id="subCategory"
//             value={subCategory}
//             onChange={(e) => setSubCategory(e.target.value)}
//             required
//           >
//             <option value="" disabled>Select sub-category</option>
//             {subcategories.map((subcat) => (
//               <option key={subcat.id} value={subcat.id}>{subcat.name}</option>
//             ))}
//           </select>
//         </div>
//         <div>
//           <textarea
//             id="description"
//             value={description}
//             onChange={(e) => setDescription(e.target.value)}
//             required
//             placeholder="Description"
//           />
//         </div>
//         <div className="priceAndCurrency">
//           <input
//             type="number"
//             id="price"
//             value={price}
//             onChange={(e) => setPrice(e.target.value)}
//             required
//             placeholder="Price"
//             style={{width:"132px",height:"29px"}}
//           />
//           <span style={{backgroundColor:"white"}} className="CurrencyofPrice">AZN</span>
//         </div>
//         <div style={{display:"flex", flexDirection:"column",gap:"5px"}}>
//           <label style={{color:"#6F6F6F"}} htmlFor="productWidth">Width:</label>
//           <input
//             type="text"
//             id="productWidth"
//             value={productWidth}
//             onChange={(e) => setProductWidth(e.target.value)}
//             required
//             placeholder="Width"
//             style={{width:"132px",height:"29px"}}
//           />
//         </div>
//         <div style={{display:"flex", flexDirection:"column",gap:"5px"}}>
//           <label style={{color:"#6F6F6F"}} htmlFor="productHeight">Height:</label>
//           <input
//             type="text"
//             id="productHeight"
//             value={productHeight}
//             onChange={(e) => setProductHeight(e.target.value)}
//             required
//             placeholder="Height"
//             style={{width:"132px",height:"29px"}}
//           />
//         </div>
//         <div style={{display:"flex", flexDirection:"column",gap:"5px"}}>
//           <label style={{color:"#6F6F6F"}} htmlFor="productWeight">Weight:</label>
//           <input
//             type="text"
//             id="productWeight"
//             value={productWeight}
//             onChange={(e) => setProductWeight(e.target.value)}
//             required
//             placeholder="Weight"
//             style={{width:"132px",height:"29px"}}
//           />
//         </div>
//         <div style={{display:"flex", flexDirection:"column",gap:"5px"}}>
//           <label style={{color:"#6F6F6F"}} htmlFor="stockCount">Stock Count:</label>
//           <input
//             type="number"
//             id="stockCount"
//             value={stockCount}
//             onChange={(e) => setStockCount(e.target.value)}
//             required
//             placeholder="Stock Count"
//             style={{width:"132px",height:"29px"}}
//           />
//         </div>

//         <div style={{marginLeft:"0px"}} className="button-group">
//           <button
//             type="button"
//             onClick={resetForm}
//           >
//             Discard
//           </button>
//           <button type="submit">Add Product</button>
//         </div>
//       </form>
//       <div className="upload-container">
//         <label htmlFor="productImage" className="custom-file-upload">
//           <img
//             src={upload}
//             alt="Upload Icon"
//             style={{ cursor: "pointer", width: "50px" }}
//           />
//         </label>
//         <input
//           type="file"
//           id="productImage"
//           onChange={handleImageChange}
//           accept="image/*"
//           style={{ display: "none" }}
//         />
//         {productImage && <p>{productImage.name}</p>}
//       </div>
//     </div>
//   );
// }

// export default AddProductContent;

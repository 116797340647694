import React from "react";
import safe from "../assets/images/safe.png";
import truck from "../assets/images/truckk.png";
import clock from "../assets/images/clock.png";

const Services = () => {
  return (
    <div className="w-full border-t border-gray-200 max-w-5xl mx-auto flex flex-col md:flex-row justify-between pt-8 md:pt-12 pb-20 md:pb-36 space-y-8 md:space-y-0 px-4 " style={{marginBottom:"-70px"}}>
      <div className="flex flex-col justify-center items-center space-y-4 border border-[#B2B2B2] p-4 w-[273px] h-[211px] ">
        <div className="w-12 h-12 md:w-16 md:h-16">
          <img src={safe} alt="Safe icon" className="object-cover w-[50px] h-[50px]" />
        </div>
        <p className="font-bold text-lg md:text-2xl text-[black]">Təhlükəsiz alış-veriş</p>
      </div>

      <div className="flex flex-col justify-center items-center space-y-4 border border-[#B2B2B2] p-4 w-[273px] h-[211px]">
        <div className="w-12 h-12 md:w-16 md:h-16">
          <img src={truck} alt="Truck icon" className="object-cover w-[50px] h-[50px]" />
        </div>
        <p className="font-bold text-lg md:text-2xl text-[black]">Etibarlı çatdırılma</p>
      </div>


      <div className="flex flex-col justify-center items-center space-y-4 border border-[#B2B2B2] p-4 w-[273px] h-[211px]">
        <div className="w-12 h-12 md:w-16 md:h-16">
          <img src={clock} alt="Clock icon" className="object-cover w-[50px] h-[50px]" />
        </div>
        <p className="font-bold text-lg md:text-2xl text-[black]">Sürətli çatdırılma</p>
      </div>
    </div>
  );
};

export default Services;

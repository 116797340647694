import logo from "../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dashboardIcon from "../assets/images/dashboard.png";
import productIcon from "../assets/images/product.png";
import ordersIcon from "../assets/images/orders.png";
import addSignIcon from "../assets/images/addsign.png";
// import reviewsIcon from "../assets/images/reviews.png";
import logoutIcon from "../assets/images/logout.png";
// import backto from "../assets/images/backto.png";
import accountIcon from "../assets/images/account.png";
import "../components/Admin Panel/dashboard.css";
import Cookies from "js-cookie";
import { notification } from "../utils/helper";

export default function AdminSidebar() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const activeContent = path || "dashboard";
  const navigate = useNavigate();
  async function handleLogOut() {
    const accessToken = Cookies.get("accessToken");
    try {
      const response = await fetch("https://api.jumma.app/api/Account/Logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        navigate("/login");
        notification("Uğurla çıxış edildi");
      } else {
        console.error("Failed to log out:", response.statusText);
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  }

  return (
    <div className="side-bar">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>

      <div className="options">
        <nav className="menu">
          <ul>
            <li>
              <Link
                to="/admin-panel/dashboard"
                className={`menu-item ${
                  activeContent === "dashboard" ? "active" : ""
                }`}
              >
                <img src={dashboardIcon} alt="Dashboard Icon" />
                <p>İdarə paneli</p>
              </Link>
            </li>
            <li>
              <Link
                to="/admin-panel/hesab"
                className={`menu-item ${
                  activeContent === "hesab" ? "active" : ""
                }`}
              >
                <img src={accountIcon} alt="Account Icon" />
                <p>Hesab</p>
              </Link>
            </li>
            <li>
              <Link
                to="/admin-panel/product"
                className={`menu-item ${
                  activeContent === "product" ? "active" : ""
                }`}
              >
                <img src={productIcon} alt="Product Icon" />
                <p>Məhsul</p>
              </Link>
            </li>
            <li>
              <Link
                to="/admin-panel/orders"
                className={`menu-item ${
                  activeContent === "orders" ? "active" : ""
                }`}
              >
                <img src={ordersIcon} alt="Orders Icon" />
                <p>Sifarişlər</p>
              </Link>
            </li>
            <li>
              <Link
                to="/admin-panel/add-product"
                className={`menu-item ${
                  activeContent === "add-product" ? "active" : ""
                }`}
              >
                <img src={addSignIcon} alt="Add Icon" />
                <p>Yeni məhsullar əlavə edin</p>
              </Link>
            </li>
            {/* <li>
              <Link
                to="/admin-panel/reviews"
                className={`menu-item ${
                  activeContent === "reviews" ? "active" : ""
                }`}
              >
                <img src={reviewsIcon} alt="Reviews Icon" />
                <p>Reviews</p>
              </Link>
            </li> */}
          </ul>
        </nav>
        <div className="logout-section">
          <button onClick={handleLogOut} className="logout-button">
            <img src={logoutIcon} alt="Logout Icon" className="logout-icon" />
            <p>Log Out</p>
          </button>
        </div>
      </div>
    </div>
  );
}

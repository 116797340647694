import React from "react";

import Services from "../../components/Services";
import Card from '../../components/Card'
import YouMayLike from "../../components/YouMayLike";
import Footer from "../../components/Footer/Footer";

const CardPage = () => {
  return (
    <div className="w-full max-w-screen-xl mt-4 space-y-8">
     <Card></Card>
      <YouMayLike></YouMayLike>
      <Services />
      <Footer></Footer>
    </div>
  );
};

export default CardPage;

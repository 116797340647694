import { useState, useEffect } from "react";
import Cookies from "js-cookie";

function MainAdProfileForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    fetchProfileData();
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName.trim()) {
      errors.firstName = "Ad tələb olunur";
    }
    if (!formData.lastName.trim()) {
      errors.lastName = "Soyad tələb olunur";
    }
    if (!formData.email.trim()) {
      errors.email = "Email tələb olunur";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Düzgün email daxil edin";
    }
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Telefon nömrəsi tələb olunur";
    }
    if (!formData.address.trim()) {
      errors.address = "Ünvan tələb olunur";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const fetchProfileData = async () => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      setError("No access token found. Please log in again.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch('https://api.jumma.app/api/Profile/GetProfile', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch profile data');
      }

      const data = await response.json();
      setFormData({
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        email: data.email || "",
        phoneNumber: data.phoneNumber || "",
        address: data.address || "",
      });
    } catch (error) {
      setError('Profil məlumatlarını yükləmək alınmadı. Zəhmət olmasa yenidən cəhd edin.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (validationErrors[name]) {
      setValidationErrors(prev => ({ ...prev, [name]: undefined }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setError(null);

    if (!validateForm()) {
      return;
    }

    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      setError("No access token found. Please log in again.");
      return;
    }

    setIsSaving(true);
    try {
      const response = await fetch(`https://api.jumma.app/api/Profile/UpdateProfile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to update profile");
      }
      
      setSuccess(true);
    } catch (error) {
      setError('Profili yeniləmək alınmadı. Zəhmət olmasa yenidən cəhd edin.');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin w-8 h-8 border-4 border-gray-300 border-t-blue-600 rounded-full"></div>
      </div>
    );
  }

  return (
    <div className="pt-[16px] pb-[31px] px-[34px] rounded-md border border-[#747474] mt-[40px]">
      <h2 className="font-bold text-lg mb-[12px]">Profil</h2>

      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-md">
          <p className="text-red-600">{error}</p>
        </div>
      )}
      
      {success && (
        <div className="mb-4 p-4 bg-green-50 border border-green-200 rounded-md">
          <p className="text-green-600">Profil uğurla yeniləndi!</p>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="form-group">
          <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px] relative">
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="bg-transparent outline-none w-full"
            />
            <legend className="block text-[#BABABA] text-[11px] absolute -top-2 left-2  px-1">
              Ad
            </legend>
          </fieldset>
          {validationErrors.firstName && (
            <p className="text-red-500 text-sm mt-1">{validationErrors.firstName}</p>
          )}
        </div>

        <div className="form-group">
          <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px] relative">
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="bg-transparent outline-none w-full"
            />
            <legend className="block text-[#BABABA] text-[11px] absolute -top-2 left-2  px-1">
              Soyad
            </legend>
          </fieldset>
          {validationErrors.lastName && (
            <p className="text-red-500 text-sm mt-1">{validationErrors.lastName}</p>
          )}
        </div>

        <div className="form-group">
          <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px] relative">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="bg-transparent outline-none w-full"
            />
            <legend className="block text-[#BABABA] text-[11px] absolute -top-2 left-2  px-1">
              Email
            </legend>
          </fieldset>
          {validationErrors.email && (
            <p className="text-red-500 text-sm mt-1">{validationErrors.email}</p>
          )}
        </div>

        <div className="form-group">
          <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px] relative">
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="bg-transparent outline-none w-full"
            />
            <legend className="block text-[#BABABA] text-[11px] absolute -top-2 left-2  px-1">
              Telefon Nömrəsi
            </legend>
          </fieldset>
          {validationErrors.phoneNumber && (
            <p className="text-red-500 text-sm mt-1">{validationErrors.phoneNumber}</p>
          )}
        </div>

        <div className="form-group">
          <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px] relative">
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="bg-transparent outline-none w-full"
            />
            <legend className="block text-[#BABABA] text-[11px] absolute -top-2 left-2  px-1">
              Ünvan
            </legend>
          </fieldset>
          {validationErrors.address && (
            <p className="text-red-500 text-sm mt-1">{validationErrors.address}</p>
          )}
        </div>

        <button
          type="submit"
          disabled={isSaving}
          className={`w-full py-2 h-[52px] mt-[19px] text-[20px] text-center rounded-md font-bold 
            ${isSaving 
              ? 'bg-gray-200 text-gray-500 cursor-not-allowed' 
              : 'bg-[#C7C7C7] text-[#898989] hover:bg-[#b8b8b8] transition-colors'
            }`}
        >
          {isSaving ? 'Yenilənir...' : 'Saxla'}
        </button>
      </form>
    </div>
  );
}

export default MainAdProfileForm;
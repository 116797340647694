import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const { register, handleSubmit, formState, watch } = useForm();
  const { errors } = formState;
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  useEffect(() => {
    if (!token || !email) {
      toast.error("Invalid or missing reset link. Redirecting to homepage...");
      navigate("/"); 
    } 
  }, [token, email, navigate])
    
  async function onSubmit(data) {
    try {
      const response = await fetch(`https://api.jumma.app/api/Account/ResetPassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json", 
        },
        body: JSON.stringify({
          email: data.email,
          token: data.token,
          newPassword: data.newPassword,
          confirmPassword: data.confirmPassword,
        }),
      });
      if (response.ok) {
        toast.success("Password reset successful!");
        navigate("/login"); 
      } else {
        const errorData = await response.json();
        toast.error(`Error: ${errorData.message || "Invalid verification code"}`);
      }
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to verify code");
    }
  }
  return (
    <div className="w-[408px] my-[30px] bg-[#FFFFFF] mx-auto p-[15px] rounded-[8px]">
      <p className="font-normal text-[22px] text-[#0C0404] mb-[22px]">
        Reset account password
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <input type="hidden" {...register("token")} value={token} />
        <input type="hidden" {...register("email")} value={email} />

        <div className="w-full h-[52px] rounded-[4px] border border-[#f5f5f5] mb-[20px]">
          <input
            type="password"
            placeholder="newPassword*"
            {...register("newPassword", {
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters long",
              },
            })}
            className="w-full h-full pl-[25px] font-normal text-[14px] text-[#545454] outline-none border bg-transparent border-[#DFDFDF]"
          />
          <p className="text-red-500 font-semibold text-sm mt-1 mb-2">
            {errors.newPassword?.message}
          </p>
        </div>

        <div className="w-full h-[52px] rounded-[4px] border border-[#f5f5f5] mb-[20px]">
          <input
            type="password"
            placeholder="Confirm password*"
            {...register("confirmPassword", {
              required: "Please confirm your password",
              validate: (value) =>
                value === watch("newPassword") || "Passwords do not match",
            })}
            className="w-full h-full pl-[25px] font-normal text-[14px] border bg-transparent border-[#DFDFDF] text-[#545454] outline-none"
          />
          <p className="text-red-500 font-semibold text-sm mt-1 mb-2">
            {errors.confirmPassword?.message}
          </p>
        </div>

        <button
          type="submit"
          className="w-full bg-[#05ac58] rounded-[4px] text-center font-bold text-[20px] text-white py-[10px] mt-[33px] mb-[33px]"
        >
          Confirm
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;

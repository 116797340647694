import React from "react";

import ChangePasswordForm from "./ChangePasswordForm";

import UserProfileForm from "./UserProfileForm";
import AdminProfileForm from './SellerAdminProfileForum'

function SaticiAdminHesab() {
  return (
    <div className="flex flex-col px-[20px] justify-center min-h-screen bg-gray-100">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 ">
        <AdminProfileForm></AdminProfileForm>

        {/* Change Password Section */}
        <ChangePasswordForm></ChangePasswordForm>
        {/* User Profile Section */}
        <UserProfileForm></UserProfileForm>
      </div>
    </div>
  );
}

export default SaticiAdminHesab;

import React from "react";
import editImg from "../assets/images/edit.png";
import binImg from "../assets/images/bin.png";

export default function MainAdminUserList({
  userData,
  handleRoleClick,
  // handleEditClick,
  showEditModal,
  handleCloseModal,
  handleSaveEdit,
  handleSaveRoles,
  handleEditChange,
  editData,
  showRoleModal,
  selectedRoles,
  setSelectedRoles,
  mescidData,
  showDeleteConfirmModal,
  confirmDeleteUsers,
  handleDeleteClick,
}) {
  return (
    <div className="table-container-user" >
      <table className="usertable" >
        <thead>
          <tr style={{border:"none"}}>
        <th style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>ID</th>
        <th style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>Ad</th>
        <th style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>Soyad</th>
        <th style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>Email</th>
        <th style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>Telefon</th>
        <th style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>Rollar</th>
        <th style={{ border: 'none',borderBottom:"1px solid #EAEAEA",width:"200px"}}></th>
        <th style={{border: 'none',borderBottom:"1px solid #EAEAEA",width:"200px"}}></th>

          </tr>
        </thead>
        <tbody>
          {userData.map((user) => (
            <tr key={user.id}>
              {/* Showing first 10 characters of user ID */}
              <td style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>{user.id.substring(0, 10)}</td>
              <td style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>{user.firstName}</td>
              <td style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>{user.lastName}</td>
              <td style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>{user.email}</td>
              <td style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>{user.phoneNumber}</td>
              <td style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>
                <button>
                  {user.isImam ? "Imam" : "User"}
                </button>
              </td>
              <td style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>
                <button onClick={() => handleRoleClick(user.id)}>
                  <img
                    src={editImg}
                    alt="Edit"
                    style={{ width: "20px", height: "20px" }}
                  />
                </button>
              </td>
              <td style={{ border: 'none', borderBottom:"1px solid #EAEAEA"}}>
                <button onClick={() => handleDeleteClick(user.id)}>
                  <img
                    src={binImg}
                    alt="Delete"
                    style={{ width: "20px", height: "20px" }}
                  />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Edit Modal */}
      {showEditModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-modal" onClick={handleCloseModal}>
              ×
            </button>
            <form onSubmit={handleSaveEdit}>
              <div>
                <label>Name:</label>
                <input
                  type="text"
                  name="firstName"
                  value={editData.firstName}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Surname:</label>
                <input
                  type="text"
                  name="lastName"
                  value={editData.lastName}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  value={editData.email}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Phone:</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={editData.phoneNumber}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Password:</label>
                <input
                  type="password"
                  name="password"
                  value={editData.password}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Confirm Password:</label>
                <input
                  type="password"
                  name="rePassword"
                  value={editData.rePassword}
                  onChange={handleEditChange}
                />
              </div>
              <div className="modal-buttons">
                <button
                  style={{ backgroundColor: "#05AC58", width: "350px" }}
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Role Modal */}
      {showRoleModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-modal" onClick={handleCloseModal}>
              ×
            </button>
            <h2>Edit Roles</h2>
            <form onSubmit={(e) => e.preventDefault()}>
              <div>
                <label>Role 1:</label>
                <select
                  name="role1"
                  value={selectedRoles.role1}
                  onChange={(e) =>
                    setSelectedRoles((prevRoles) => ({
                      ...prevRoles,
                      role1: e.target.value,
                    }))
                  }
                >
                  <option value="">Select Role</option>
                  <option value="Imam">Imam</option>
                  <option value="Istifadeci">Istifadeci</option>
                </select>
              </div>
              <div></div>
              {selectedRoles.role1 === "Imam" ? (
                <div>
                  <label>Mescid:</label>
                  <select
                    value={selectedRoles.mescid}
                    onChange={(e) =>
                      setSelectedRoles({
                        ...selectedRoles,
                        mescid: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Mescid</option>
                    {mescidData.map((mescid) => (
                      <option key={mescid.id} value={mescid.id}>
                        {mescid.name} - {mescid.city}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              <div className="modal-buttons">
                <button
                  style={{ backgroundColor: "#05AC58", width: "350px" }}
                  onClick={handleSaveRoles}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirmModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-modal" onClick={handleCloseModal}>
              ×
            </button>
            <p>Silmək istədiyinizə əminsiniz?</p>
            <div className="modal-buttons">
              <button
                style={{
                  backgroundColor: "#FF0000",
                  marginRight: "10px",
                  width: "320px",
                  color: "white",
                }}
                onClick={confirmDeleteUsers}
              >
                Sil
              </button>
              <button
                style={{
                  border: "1px solid #05AC58",
                  width: "320px",
                  color: "#05AC58",
                  backgroundColor: "white",
                }}
                onClick={handleCloseModal}
              >
                Geri qayıt
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

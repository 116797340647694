import React from "react";
import AddCard from "../../components/Add Card Section/addcard";

const AddCardPage = () => {
  return (
    <main className="flex-1  px-4  flex flex-col ">
      <AddCard />
    </main>
  );
};

export default AddCardPage;

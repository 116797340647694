import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import edit from "../../assets/images/edit.png";
import bin from "../../assets/images/bin.png";

export default function MainAdminIane() {
    const [ianeData, setIaneData] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [mosques, setMosques] = useState([]);
    const [selectedIane, setSelectedIane] = useState(null);
    const [editFormData, setEditFormData] = useState({
      mosqueId: '',
      problem: '',
      requiredAmount: ''
    });
    const [newIane, setNewIane] = useState({
      mosqueId: '',
      problem: '',
      requiredAmount: ''
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      fetchMosques();
      fetchIanes();
    }, []);

    const handleEdit = (id) => {
        const ianeToEdit = ianeData.find(item => item.id === id);
        setSelectedIane(ianeToEdit);
        setEditFormData({
          mosqueId: ianeToEdit.mosqueId,
          problem: ianeToEdit.problem,
          requiredAmount: ianeToEdit.requiredAmount
        });
        setIsEditModalOpen(true);
      };
    
      const handleUpdateIane = async () => {
        const accessToken = Cookies.get('accessToken');
        try {
          const response = await fetch(`https://api.jumma.app/api/Charity/${selectedIane.id}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(editFormData)
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
    
          // Update the local state with edited data
          setIaneData(ianeData.map(item =>
            item.id === selectedIane.id
              ? { ...item, ...editFormData }
              : item
          ));
    
          setIsEditModalOpen(false);
          alert('Iane updated successfully!');
        } catch (error) {
          console.error('Error updating iane:', error);
          alert(`Failed to update iane: ${error.message}`);
        }
      };

  const fetchMosques = async () => {
    const accessToken = Cookies.get('accessToken');
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://api.jumma.app/api/Mosque', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setMosques(data.mosques);
    } catch (error) {
      console.error('Error fetching mosques:', error);
      setError(`Failed to fetch mosques: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchIanes = async () => {
    const accessToken = Cookies.get('accessToken');
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://api.jumma.app/api/Charity', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setIaneData(data.charityListItemDtos);
    } catch (error) {
      console.error('Error fetching ianes:', error);
      setError(`Failed to fetch ianes: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  

  const handleToggleActive = async (id) => {
    const accessToken = Cookies.get('accessToken');
    try {
      const iane = ianeData.find(item => item.id === id);
      const response = await fetch(`https://api.jumma.app/api/Charity/${id}`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ isActive: !iane.isActive })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setIaneData(ianeData.map(item => 
        item.id === id ? { ...item, isActive: !item.isActive } : item
      ));
    } catch (error) {
      console.error('Error toggling iane active status:', error);
      alert(`Failed to update iane status: ${error.message}`);
    }
  };


  const handleDelete = async (id) => {
    const accessToken = Cookies.get('accessToken');
    try {
      const response = await fetch(`https://api.jumma.app/api/Charity/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setIaneData(ianeData.filter(item => item.id !== id));
      alert('Iane deleted successfully!');
    } catch (error) {
      console.error('Error deleting iane:', error);
      alert(`Failed to delete iane: ${error.message}`);
    }
  };

  const handleShare = async () => {
    const accessToken = Cookies.get('accessToken');
    try {
      const response = await fetch('https://api.jumma.app/api/Charity', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          mosqueId: parseInt(newIane.mosqueId),
          problem: newIane.problem,
          requiredAmount: parseFloat(newIane.requiredAmount)
        })
      });

      if (!response.ok) {
        throw new Error('Failed to add new iane');
      }

      await fetchIanes();
      setIsAddModalOpen(false);
      setNewIane({
        mosqueId: '',
        problem: '',
        requiredAmount: ''
      });
      alert('New iane added successfully!');
    } catch (error) {
      console.error('Error adding new iane:', error);
      alert(`Failed to add new iane: ${error.message}`);
    }
  };
  

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="relative">
      <style>
        {`
          .toggle-switch {
            position: relative;
            display: inline-block;
            width: 50px;
            height: 24px;
          }

          .toggle-switch input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .toggle-slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: .4s;
            border-radius: 24px;
          }

          .toggle-slider:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            transition: .4s;
            border-radius: 50%;
          }

          input:checked + .toggle-slider {
            background-color: #088446;
          }

          input:checked + .toggle-slider:before {
            transform: translateX(26px);
          }
        `}
      </style>

      <div className="m-[20px] flex justify-start">
        <button 
          onClick={() => setIsAddModalOpen(true)}
          className="bg-[#088446] w-[183px] text-white px-4 py-2 rounded text-sm"
        >
          İanə əlavə et
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border ">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">ID</th>
              <th className="py-2 px-4 border-b">Məscid</th>
              <th className="py-2 px-4 border-b">Səbəb</th>
              <th className="py-2 px-4 border-b">Məbləğ</th>
              <th className="py-2 px-4 border-b">Ödənilib</th>
              <th className="py-2 px-4 border-b">Status</th>
            </tr>
          </thead>
          <tbody>
            {ianeData.map((iane) => (
              <tr key={iane.id}>
                <td className="py-2 px-4 border-b">{iane.id}</td>
                <td className="py-2 px-4 border-b">{iane.mosqueName}</td>
                <td className="py-2 px-4 border-b">{iane.problem}</td>
                <td className="py-2 px-4 border-b">{iane.requiredAmount}</td>
                <td className="py-2 px-4 border-b">{iane.currentAmount}</td>
                <td className="py-2 px-4 border-b">
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      checked={iane.isActive}
                      onChange={() => handleToggleActive(iane.id)}
                    />
                    <span className="toggle-slider"></span>
                  </label>
                </td>
                <td className="py-2 px-4 flex gap-[30px]  border-b">
                  <button onClick={() => handleEdit(iane.id)} className="mr-2">
                    <img className="w-[20px] h-[20px] " src={edit} alt="Edit"  />
                  </button>
                  <button onClick={() => handleDelete(iane.id)}>
                    <img className="w-[20px] h-[20px]" src={bin} alt="Delete"  />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isEditModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-[403px] h-[290px] relative">
            <button 
              onClick={() => setIsEditModalOpen(false)}
              className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
            
            <div className="space-y-4 mt-5">
              <div className="relative">
                <select
                  value={editFormData.mosqueId}
                  onChange={(e) => setEditFormData({...editFormData, mosqueId: e.target.value})}
                  className="w-full p-2 border border-[#DFDFDF] appearance-none"
                >
                  <option value="">Məscid seç</option>
                  {mosques.map(mosque => (
                    <option key={mosque.id} value={mosque.id}>{mosque.name}</option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>

              <input
                type="text"
                placeholder="İanə səbəbini qeyd edin"
                value={editFormData.problem}
                onChange={(e) => setEditFormData({...editFormData, problem: e.target.value})}
                className="w-full p-2 border border-[#DFDFDF] bg-white"
              />

              <input
                type="number"
                placeholder="Qiymət qeyd edin"
                value={editFormData.requiredAmount}
                onChange={(e) => setEditFormData({...editFormData, requiredAmount: e.target.value})}
                className="w-full p-2 border border-[#DFDFDF] bg-white"
              />

              <button 
                onClick={handleUpdateIane}
                className="w-full bg-green-500 hover:bg-green-600 text-white py-2"
              >
                Yenilə
              </button>
            </div>
          </div>
        </div>
      )}

      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-[403px] h-[290px] relative">
            <button 
              onClick={() => setIsAddModalOpen(false)}
              className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
            
            <div className="space-y-4 mt-5">
              <div className="relative">
                <select
                  value={newIane.mosqueId}
                  onChange={(e) => setNewIane({...newIane, mosqueId: e.target.value})}
                  className="w-full p-2 border border-[#DFDFDF] appearance-none"
                >
                  <option value="">Məscid seç</option>
                  {mosques.map(mosque => (
                    <option key={mosque.id} value={mosque.id}>{mosque.name}</option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>

              <input
                type="text"
                placeholder="İanə səbəbini qeyd edin"
                value={newIane.problem}
                onChange={(e) => setNewIane({...newIane, problem: e.target.value})}
                className="w-full p-2 border border-[#DFDFDF] bg-white"
              />

              <input
                type="number"
                placeholder="Qiymət qeyd edin"
                value={newIane.requiredAmount}
                onChange={(e) => setNewIane({...newIane, requiredAmount: e.target.value})}
                className="w-full p-2 border border-[#DFDFDF] bg-white"
              />

              <button 
                onClick={handleShare}
                className="w-full bg-green-500 hover:bg-green-600 text-white py-2"
              >
                Paylaş
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
import React, { useState, useEffect } from "react";
import bin from "../../assets/images/bin.png";
import "./MainAdminKateqoriyalar.css";
import editImg from "../../assets/images/edit.png";
import searchIcon from "../../assets/images/searchicon.png";
import Cookies from "js-cookie";

export default function MainAdminKateqoriyalar() {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [categoryToDisable, setCategoryToDisable] = useState(null);
  const [view, setView] = useState("categories");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [addPopupType, setAddPopupType] = useState("");
  const [newCategory, setNewCategory] = useState({
    name: "",
    description: "",
    isActive: false,
  });
  const [newSubcategory, setNewSubcategory] = useState({
    name: "",
    description: "",
    categoryId: "",
  });
  const [editData, setEditData] = useState({
    name: "",
    description: "",
    id: null,
    categoryId: "",
  });

  useEffect(() => {
    fetchCategories();
    fetchSubcategories();
  }, []);

  const fetchCategories = async () => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      console.error("No access token found");
      return;
    }
    try {
      const response = await fetch("https://api.jumma.app/api/Category", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setCategories(Array.isArray(data.categories) ? data.categories : data);
    } catch (error) {
      console.error("Failed to fetch categories:", error.message);
    }
  };

  const fetchSubcategories = async () => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      console.error("No access token found");
      return;
    }
    try {
      const response = await fetch("https://api.jumma.app/api/SubCategory", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch subcategories");

      const data = await response.json();
      const subCategoriesWithCategory = await Promise.all(
        (Array.isArray(data.subCategories) ? data.subCategories : []).map(
          async (subCategory) => {
            const categoryResponse = await fetch(
              `https://api.jumma.app/api/Category/${subCategory.categoryId}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            if (categoryResponse.ok) {
              const categoryData = await categoryResponse.json();
              return { ...subCategory, category: categoryData.name };
            }
            return subCategory;
          }
        )
      );
      setSubcategories(subCategoriesWithCategory);
    } catch (error) {
      console.error("Failed to fetch subcategories", error);
    }
  };

  const handleSwitchToggle = async (
    id,
    currentState,
    isSubcategory = false
  ) => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) {
      console.error("No access token found");
      return;
    }

    try {
      let endpoint;
      let body;

      if (isSubcategory) {
        endpoint = `https://api.jumma.app/api/SubCategory/${id}`;
      } else {
        endpoint = `https://api.jumma.app/api/Category/${id}`;
      }

      body = JSON.stringify({ isActive: !currentState });

      console.log("Request Details:", {
        endpoint,
        method: "PATCH",
        body,
        isSubcategory,
      });

      const response = await fetch(endpoint, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: body,
      });

      console.log("Response status:", response.status);

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Server responded with ${response.status}: ${errorText}`);
        throw new Error(`Failed to update status: ${response.statusText}`);
      }

      let updatedData;
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        updatedData = await response.json();
        console.log("Server response:", updatedData);
      } else {
        const text = await response.text();
        console.log("Server response (non-JSON):", text);
        updatedData = { isActive: !currentState };
      }

      if (isSubcategory) {
        setSubcategories((prevSubcategories) =>
          prevSubcategories.map((subcategory) =>
            subcategory.id === id
              ? { ...subcategory, isActive: updatedData.isActive }
              : subcategory
          )
        );
        await fetchSubcategories();
      } else {
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category.id === id
              ? { ...category, isActive: updatedData.isActive }
              : category
          )
        );
        await fetchCategories();
      }

      console.log(
        `${
          isSubcategory ? "Subcategory" : "Category"
        } ${id} updated successfully`
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleDelete = async (id, isSubcategory = false) => {
    setShowDeletePopup(true);
    setCategoryToDisable({ id, isSubcategory });
  };

  const confirmDelete = async () => {
    const accessToken = Cookies.get("accessToken");
    const { id, isSubcategory } = categoryToDisable;

    try {
      const endpoint = isSubcategory
        ? `https://api.jumma.app/api/SubCategory/${id}`
        : `https://api.jumma.app/api/Category/${id}`;

      const response = await fetch(endpoint, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        if (isSubcategory) {
          setSubcategories(subcategories.filter((sub) => sub.id !== id));
        } else {
          setCategories(categories.filter((cat) => cat.id !== id));
        }
        setShowDeletePopup(false);
      } else {
        console.error("Failed to delete:", await response.text());
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  const cancelDelete = () => {
    setShowDeletePopup(false);
  };

  const openAddPopup = (type) => {
    setAddPopupType(type);
    setShowAddPopup(true);
    setNewCategory({ name: "", description: "", isActive: false });
    setNewSubcategory({ name: "", description: "", categoryId: "" });
  };

  const closeAddPopup = () => {
    setShowAddPopup(false);
  };

  const handleAddCategory = async () => {
    const accessToken = Cookies.get("accessToken");

    if (newCategory.name && newCategory.description) {
      try {
        const response = await fetch("https://api.jumma.app/api/Category", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(newCategory),
        });

        if (response.ok) {
          const addedCategory = await response.json();
          setCategories([...categories, addedCategory]);
          setView("categories");
          closeAddPopup();
          await fetchCategories();
        } else {
          console.error("Failed to add category:", await response.text());
        }
      } catch (error) {
        console.error("Error adding category:", error);
      }
    } else {
      console.error("Name and description are required for a new category");
    }
  };

  const handleAddSubcategory = async () => {
    const accessToken = Cookies.get("accessToken");

    if (
      newSubcategory.name &&
      newSubcategory.description &&
      newSubcategory.categoryId
    ) {
      try {
        const response = await fetch("https://api.jumma.app/api/SubCategory", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(newSubcategory),
        });

        if (response.ok) {
          const addedSubcategory = await response.json();
          const parentCategory = categories.find(
            (cat) => cat.id === addedSubcategory.categoryId
          );
          const newSubcategoryWithCategory = {
            ...addedSubcategory,
            category: parentCategory ? parentCategory.name : "Unknown",
          };
          setSubcategories((prevSubcategories) => [
            ...prevSubcategories,
            newSubcategoryWithCategory,
          ]);
          setView("subcategories");
          await fetchSubcategories();
          closeAddPopup();
        } else {
          console.error("Failed to add subcategory:", await response.text());
        }
      } catch (error) {
        console.error("Error adding subcategory:", error);
      }
    } else {
      console.error("Missing required fields for subcategory");
    }
  };

  const handleNewCategoryChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewCategory((prevCategory) => ({
      ...prevCategory,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleNewSubcategoryChange = (e) => {
    const { name, value } = e.target;
    setNewSubcategory((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    const accessToken = Cookies.get("accessToken");

    try {
      const endpoint =
        view === "categories"
          ? `https://api.jumma.app/api/Category/${editData.id}`
          : `https://api.jumma.app/api/SubCategory/${editData.id}`;

      const response = await fetch(endpoint, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(editData),
      });

      if (response.ok) {
        const updatedData = await response.json();
        if (view === "categories") {
          setCategories((prevData) =>
            prevData.map((category) =>
              category.id === updatedData.id ? updatedData : category
            )
          );
          await fetchCategories();
        } else {
          setSubcategories((prevData) =>
            prevData.map((subcategory) =>
              subcategory.id === updatedData.id
                ? {
                    ...updatedData,
                    category:
                      categories.find(
                        (cat) => cat.id === updatedData.categoryId
                      )?.name || "Unknown",
                  }
                : subcategory
            )
          );
          await fetchSubcategories();
        }
        setShowEditModal(false);
      } else {
        console.error("Failed to update category or subcategory");
      }
    } catch (error) {
      console.error("Error updating category or subcategory:", error);
    }
  };

  return (
    <div className="admin-kateqoriyalar-container">
      <div
        className="categoriesButtons"
        style={{ display: "flex", marginLeft: "-20px", marginTop: "20px" }}
      >
        <div className="search-container">
          <div
            className="search-wrapper"
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "5px 10px",
              width: "183px",
            }}
          >
            <img
              src={searchIcon}
              alt="Search Icon"
              className="search-icon"
              style={{ width: "20px", marginRight: "10px" }}
            />
            <input
              type="text"
              placeholder="Axtar"
              style={{
                border: "none",
                outline: "none",
                width: "100%",
                fontSize: "14px",
                padding: "5px",
                backgroundColor: "inherit",
              }}
            />
          </div>
        </div>

        <div className="action-buttons" >
          <button
             className="hidden md:block whitespace-nowrap  ml-[10px] w-[183px] h-[40px] rounded-[4px] bg-[#088446] text-white"
            onClick={() => openAddPopup("category")}
          >
            Kateqoriyaya əlavə et
          </button>
          <button
             className="hidden md:block whitespace-nowrap  ml-[10px] w-[213px] h-[40px] rounded-[4px] bg-blue-500 text-white"
            onClick={() => openAddPopup("subcategory")}
          >
            Sub Kateqoriyaya əlavə et
          </button>
        </div>
      </div>

      <div className="tabs">
        <button
          className={`tab ${view === "categories" ? "active" : ""}`}
          onClick={() => setView("categories")}
          style={{
            backgroundColor: view === "categories" ? "#C3E5D4" : "",
            color: view === "categories" ? "" : "",
          }}
        >
          Kateqoriya
        </button>
        <button
          className={`tab ${view === "subcategories" ? "active" : ""}`}
          onClick={() => setView("subcategories")}
          style={{
            backgroundColor: view === "subcategories" ? "#C3E5D4" : "",
            color: view === "subcategories" ? "" : "",
          }}
        >
          Subkateqoriya
        </button>
      </div>

      <table className="category-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Ad</th>
            <th>Təsvir</th>
            {view === "subcategories" && <th>Kateqoriya</th>}
            <th>Aktivdir</th>
            <th>Redaktə et</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          {view === "categories"
            ? categories.map((category) => (
                <tr key={category.id}>
                  <td>{category.id}</td>
                  <td>{category.name}</td>
                  <td>{category.description}</td>
                  <td>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={category.isActive}
                        onChange={() =>
                          handleSwitchToggle(category.id, category.isActive)
                        }
                      />
                      <span className="slider"></span>
                    </label>
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        setEditData({
                          id: category.id,
                          name: category.name,
                          description: category.description,
                        });
                        setShowEditModal(true);
                      }}
                    >
                      <img
                        style={{ width: "23px", height: "23px" }}
                        src={editImg}
                        alt="Edit"
                      />
                    </button>
                  </td>
                  <td>
                    <button onClick={() => handleDelete(category.id, false)}>
                      <img
                        style={{ width: "27px", height: "27px" }}
                        src={bin}
                        alt="Delete"
                      />
                    </button>
                  </td>
                </tr>
              ))
            : subcategories.map((subcategory) => (
                <tr key={subcategory.id}>
                  <td>{subcategory.id}</td>
                  <td>{subcategory.name}</td>
                  <td>{subcategory.description}</td>
                  <td>{subcategory.category}</td>
                  <td>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={subcategory.isActive}
                        onChange={() =>
                          handleSwitchToggle(
                            subcategory.id,
                            subcategory.isActive,
                            true
                          )
                        }
                      />
                      <span className="slider"></span>
                    </label>
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        setEditData({
                          id: subcategory.id,
                          name: subcategory.name,
                          description: subcategory.description,
                          categoryId: subcategory.categoryId,
                        });
                        setShowEditModal(true);
                      }}
                    >
                      <img
                        style={{ width: "23px", height: "23px" }}
                        src={editImg}
                        alt="Edit"
                      />
                    </button>
                  </td>
                  <td>
                    <button onClick={() => handleDelete(subcategory.id, true)}>
                      <img
                        style={{ width: "27px", height: "27px" }}
                        src={bin}
                        alt="Delete"
                      />
                    </button>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
      {showDeletePopup && (
        <div className="popup-overlay">
          <div className="popup">
            <button className="close-btn" onClick={cancelDelete}>
              &times;
            </button>
            <h2>Silmək istəyirsinizmi?</h2>
            <button className="confirm-btn" onClick={confirmDelete}>
              Bəli
            </button>
            <button className="cancel-btn" onClick={cancelDelete}>
              Xeyr
            </button>
          </div>
        </div>
      )}

      {showConfirmPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <button
              className="close-btn"
              onClick={() => setShowConfirmPopup(false)}
            >
              &times;
            </button>
            <h2>
              Kateqoriyanı və ya subkateqoriyanı deaktiv etmək istəyirsinizmi?
            </h2>
            <button
              className="confirm-btn"
              onClick={() => {
                handleSwitchToggle(
                  categoryToDisable.id,
                  true,
                  categoryToDisable.isSubcategory
                );
                setShowConfirmPopup(false);
              }}
            >
              Bəli
            </button>
            <button
              className="cancel-btn"
              onClick={() => setShowConfirmPopup(false)}
            >
              Xeyr
            </button>
          </div>
        </div>
      )}

      {showAddPopup && (
        <div className="popup-overlay">
          <div className="popup add-popup">
            <button className="close-btn" onClick={closeAddPopup}>
              &times;
            </button>
            {addPopupType === "category" ? (
              <>
                <input
                  type="text"
                  name="name"
                  value={newCategory.name}
                  onChange={handleNewCategoryChange}
                  placeholder="Ad"
                />
                <input
                  type="text"
                  name="description"
                  value={newCategory.description}
                  onChange={handleNewCategoryChange}
                  placeholder="Təsvir"
                />

                <button className="confirm-btn" onClick={handleAddCategory}>
                  Əlavə Et
                </button>
              </>
            ) : (
              <>
                <input
                  type="text"
                  name="name"
                  value={newSubcategory.name}
                  onChange={handleNewSubcategoryChange}
                  placeholder="Ad"
                />
                <input
                  type="text"
                  name="description"
                  value={newSubcategory.description}
                  onChange={handleNewSubcategoryChange}
                  placeholder="Təsvir"
                />
                <select
                  name="categoryId"
                  value={newSubcategory.categoryId}
                  onChange={handleNewSubcategoryChange}
                >
                  <option value="">Kateqoriya seçin</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <button className="confirm-btn" onClick={handleAddSubcategory}>
                  Əlavə Et
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {showEditModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-modal" onClick={handleCloseModal}>
              ×
            </button>
            <h2>
              Edit {view === "categories" ? "Kateqoriya" : "Subkateqoriya"}
            </h2>
            <form onSubmit={handleSaveEdit}>
              <div>
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={editData.name}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Description:</label>
                <input
                  type="text"
                  name="description"
                  value={editData.description}
                  onChange={handleEditChange}
                />
              </div>
              {view === "subcategories" && (
                <div>
                  <label>Category:</label>
                  <select
                    name="categoryId"
                    value={editData.categoryId}
                    onChange={handleEditChange}
                  >
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="modal-buttons">
                <button
                  style={{ backgroundColor: "#05AC58", width: "350px" }}
                  type="submit"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

import React from "react";
// import img1 from "../../assets/images/img1.svg";
// import img2 from "../../assets/images/img2.svg";
// import img3 from "../../assets/images/img3.svg";

import AllProducts from "../../components/AllProducts";
// import NewProducts from "../../components/NewProducts";
import Services from "../../components/Services";
// import Special from "../../components/Special";
// import BestSeller from "../../components/BestSeller";
import Footer from "../../components/Footer/Footer";
import jummareklam from '../../assets/images/jummareklam.png';
const Home = () => {
  return (
    <main className="flex-1 flex flex-col items-center">
      {/* <div className="hidden md:grid grid-cols-1 lg:grid-cols-3 gap-4  max-w-screen-xl w-[1000px] mx-auto">
        <div className="col-span-2">
          <img src={img1} alt="Main Content" className="w-full h-auto" />
        </div>
        <div className="flex flex-row lg:flex-col max-w-screen-xl gap-y-3 space-x-3 lg:space-x-0 lg:space-y-0">
          <img src={img2} alt="Ad 1" className="w-full h-auto size-full" />
          <img src={img2} alt="Ad 2" className="w-full h-auto size-full" />
        </div>
      </div> */}
      <div className=" px-4 sm:px-6 lg:px-4">
        <img src={jummareklam} alt='img'></img>
      </div>

      {/* Product Sections */}
      <div className="w-full max-w-screen-xl mt-4 space-y-8">
        {/* <Special />
         <BestSeller /> 
        <NewProducts title="Our New Product Ranges" />  */}
        <AllProducts title="All our products" />
        <Services />
        <Footer />
      </div>
    </main>
  );
};

export default Home;
import React from "react";
import Account from "../../components/Account";
import Footer from "../../components/Footer/Footer";

const AccountPage = () => {
  return (
    <div className="w-full max-w-screen-xl mt-4 space-y-8">
      <Account></Account>
      <Footer></Footer>
    </div>
  );
};

export default AccountPage;

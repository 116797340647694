import React from "react";
import { Link } from "react-router-dom";
import { useContextAdmin } from "../../context/AdminContext";
import "./OrderContent.css";

function OrdersContent() {
  const { adminOrders } = useContextAdmin();

  return (
    <div className="orders-content">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Items</th>
            <th>Date</th>
            <th>Status</th>
            <th>Price</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {adminOrders.map((order) => (
            <tr key={order.id}>
              <td>{order.id}</td>
              <td>{order.items.length}</td>
              <td>{order.date}</td>
              <td>{order.status}</td>
              <td>{order.price}</td>
              <td>
                <Link to={`/adminview/${order.id}`}>
                  <button className="view-btn">View</button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OrdersContent;
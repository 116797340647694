import React from 'react';
import './DashboardContent.css';

const DashboardContent = () => {
  const orderStats = [
    { title: 'Processing Orders', number: 127, description: 'Number of orders processing' },
    { title: 'Preparing Orders', number: 127, description: 'Number of orders preparing' },
    { title: 'Delivered Orders', number: 127, description: 'Number of orders delivered' },
    { title: 'Completed Orders', number: 127, description: 'Number of orders completed' },
  ];

  const salesStats = [
    { title: 'Total Sales', number: 127, description: 'Total sales' },
    { title: 'Total Earnings', number: '$1240', description: 'Total earnings' },
  ];

  const recentOrders = []; // Empty for example purposes
  const recentReviews = []; // Empty for example purposes

  return (
    <div className="dashboard-container">
      {/* Order Stats */}
      <div className="order-stats">
        {orderStats.map((stat, index) => (
          <div key={index} className="stat-box">
            <h3>{stat.title}</h3>
            <p className="stat-number">{stat.number}</p>
            <p className="stat-description">{stat.description}</p>
          </div>
        ))}
      </div>

      {/* Sales Stats */}
      <div className="sales-stats">
        {salesStats.map((stat, index) => (
          <div key={index} className="stat-box">
            <h3>{stat.title}</h3>
            <p className="stat-number">{stat.number}</p>
            <p className="stat-description">{stat.description}</p>
          </div>
        ))}
      </div>

      {/* Recent Orders */}
      <div className="recent-orders">
        <h2>Recent Orders</h2>
        {recentOrders.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Items</th>
                <th>Date</th>
                <th>Status</th>
                <th>Price</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {recentOrders.map((order, index) => (
                <tr key={index}>
                  <td>{order.id}</td>
                  <td>{order.items}</td>
                  <td>{order.date}</td>
                  <td className={`status ${order.status.toLowerCase()}`}>{order.status}</td>
                  <td>{order.price}</td>
                  <td><button className="view-btn">View</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Heleki sifariş yoxdur</p>
        )}
      </div>

      {/* Recent Reviews */}
      <div className="recent-reviews">
        <h2>Recent Reviews</h2>
        {recentReviews.length > 0 ? (
          <div className="review-cards">
            {recentReviews.map((review, index) => (
              <div key={index} className="review-card">
                <p className="reviewer-email">{review.email}</p>
                <p className="review-date">{review.date}</p>
                <div className="rating">{'★'.repeat(review.rating)}</div>
                <p className="review-text">{review.text}</p>
                <div className="detail-and-likes-container">
                  <p className="likes">👍 {review.likes}</p>
                  <button className="detail-btn">Detail</button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>Heleki rəy yoxdur</p> 
        )}
      </div>
    </div>
  );
};

export default DashboardContent;

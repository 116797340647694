import React, { useState, useEffect } from "react";
import "./ProductCard.css";
import { useParams } from "react-router-dom";
import ProductImages from "./ProductImages";
import ProductDetails from "./ProductDetails";
import ProductDescription from "./ProductDescription";
import { useContextApp } from "../../context/AppContext";
import Cookies from "js-cookie";

const ProductCard = () => {
  const [activeTab, setActiveTab] = useState("description");
  const { slug } = useParams();
  const { products } = useContextApp();
  const [productDetail, setProductDetail] = useState(null);
  const [rating, setRating] = useState(0);
  const [categoryName, setCategoryName] = useState("Loading...");
  const [error, setError] = useState(null);

  useEffect(() => {
    const product = products.find((item) => item.id === Number(slug));
    
    if (product) {
      setProductDetail(product);
      getCategory(slug);
    } else {
      setError("Product not found");
    }
  }, [slug, products]);
    
  const getCategory = async (id) => {
    const accessToken = Cookies.get("accessToken");
    try {
      const response = await fetch(`https://api.jumma.app/ActiveProduct/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch category");
      }
      const data = await response.json();
      setRating(data.averageRating);
      setCategoryName(data.categoryName);
    } catch (err) {
      setError("Failed to fetch category");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!productDetail) {
    return <div>{error || "Loading product..."}</div>;
  }

  return (
    <div className="product-card">
      <div className="product-card-content">
        <ProductImages productDetail={productDetail} />
        <ProductDetails 
  productDetail={productDetail} 
  categoryName={categoryName} 
  rating={rating} // Pass the rating here
/>
      </div>
      <ProductDescription
        rating={rating}
        setRating={setRating}
        productDetail={productDetail}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </div>
  );
};

export default ProductCard;
import React from "react";

export default function Tezlikle() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-[#147b47]">
      <div className="text-center w-[90%] max-w-[1000px] p-8 md:p-12 bg-green-300/30 backdrop-blur-md rounded-2xl shadow-xl">
        <h1 className="text-4xl md:text-6xl text-white mb-8 font-light tracking-wide">
          SAYT HAZIRLANIR
        </h1>
        <h2 className="text-3xl md:text-5xl text-green-100 font-light opacity-90">
          Tezliklə istifadəyə veriləcək.
        </h2>
      </div>
    </div>
  );
}

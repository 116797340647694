import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useContextApp } from "../context/AppContext";
import search from "../assets/images/search.svg";
import sign from "../assets/images/signin.svg";
import cart from "../assets/images/cart.svg";

const Search = () => {
  const { baskets, isLoggedIn, user,query, setQuery } = useContextApp();
  const navigate = useNavigate();

  const handleSignClick = () => {
    if (isLoggedIn) {
      // Navigate based on user role
      if (user.role === "SuperAdmin") {
        navigate("/mainAdmin-panel");
      } else if (user.role === "User") {
        navigate("/account/accountform");
      } else if (user.role === "Seller") {
        navigate("/admin-panel");
      }
    } else {
      // If not logged in, navigate to register page
      navigate("/register");
    }
  };

  return (
    <div className="w-full hidden sm:flex flex-col md:flex-row items-center justify-between space-y-2 md:space-y-0 py-2 border-t border-b border-[#F5F5F5]">
      <Link to="/tezlikle">
        <img src="/logo.png" alt="Logo" className="w-20 h-20 object-cover" />
      </Link>
      <div className="flex items-center md:w-[575px] h-[40px] border border-[#a1a1a1] rounded-[4px]">
        <input
          value={query}
          onChange={(e)=>setQuery(e.target.value)}
          type="text"
          placeholder="Search"
          className="bg-transparent outline-none flex-1 pl-3"
        />
        <img
          src={search}
          alt="search icon"
          className="w-6 h-6 mr-2 object-cover"
        />
      </div>
      <div className="flex items-center space-x-4 w-full md:w-auto">
        <div className="flex items-center space-x-6">
          <div onClick={handleSignClick} className="cursor-pointer">
            <div className="flex items-center space-x-2">
              <img src={sign} alt="Sign In" className="w-[20px] h-[20px] object-cover" />
            </div>
          </div>
          <Link to="/card" className="relative">
            <img src={cart} alt="Cart" className="w-6 h-6 object-cover" />
            {baskets?.length > 0 && (
              <span className="absolute bottom-3 left-4 bg-red-500 text-white text-xs font-semibold px-1 py-0 rounded-full">
                {baskets.length}
              </span>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Search;
import React from 'react';
import './SettingsContent.css';
import ReviewItem from '../../components/SettingsContent(Admin)/ReviewItem.jsx';
import { useContextAdmin } from '../../context/AdminContext.jsx';

const SettingsContent = () => {
  const { adminReviews } = useContextAdmin();

  return (
    <div className="settings-content">
      <div className="tab-buttons">
        <button className="tab-button active">
          All ({adminReviews.length})
        </button>
      </div>
      <div className="review-items">
        {adminReviews.map((review, index) => (
          <ReviewItem
            key={index}
            email={review.email}
            date={review.date}
            rating={review.rating}
            review={review.review}
          />
        ))}
      </div>
    </div>
  );
};

export default SettingsContent;
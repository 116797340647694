import React, { useState, useEffect } from "react";
import resend from "../assets/images/resend.png"
import { toast } from "react-toastify";

const VerifyCodeSeller = ({ firstName, email, onClose }) => {
  const [code, setCode] = useState(Array(6).fill(""));
  const [timer, setTimer] = useState(300); 
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [showResendPopup, setShowResendPopup] = useState(false); 

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    let countdown;
    if (isTimerRunning && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(countdown);
      setIsTimerRunning(false);
    }
    return () => clearInterval(countdown);
  }, [timer, isTimerRunning]);

  useEffect(() => {
    let resendCountdown;
    if (resendTimer > 0) {
      resendCountdown = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(resendCountdown);
  }, [resendTimer]);

  const handleChange = (value, index) => {
    const newCode = [...code];
    newCode[index] = value.slice(-1);
    setCode(newCode);

    if (value && index < 5) {
      document.getElementById(`code-${index + 1}`).focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const verificationCode = code.join("");

    try {
      const response = await fetch(`https://api.jumma.app/api/Account/Verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify({
          email: email,
          confirmationCode: verificationCode,
        }),
      });

      if (response.ok) {
        setShowSuccessPopup(true);
        toast.success("Verification successful!");
      } else {
        const errorData = await response.json();
        toast.error(`Verification failed: ${errorData.message || "Unknown error"}`);
      }
    } catch (error) {
      toast.error("Network error: Unable to verify code");
    }
  };

  const handleResend = async () => {
    if (resendTimer === 0) {
      setCode(Array(6).fill(""));
      setTimer(300); 
      setIsTimerRunning(true);
      setShowSuccessPopup(false);
      setResendTimer(120);

      try {
        const response = await fetch(`https://api.jumma.app/api/Account/ResendCode`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json-patch+json",
          },
          body: JSON.stringify({ email, firstName }),
        });

        if (response.ok) {
          toast.success("Verification code resent successfully");
          setShowResendPopup(true); 
        } else {
          const errorData = await response.json();
          toast.error(`Failed to resend code: ${errorData.message || "Unknown error"}`);
        }
      } catch (error) {
        toast.error("Network error: Unable to resend code");
      }
    }
  };

  const handleSuccessClose = () => {
    setShowSuccessPopup(false);
    if (onClose) onClose();
  };

  const handleResendClose = () => {
    setShowResendPopup(false); 
  };

  return (
    <div className="flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-2xl">
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 relative">
          <h2 className="text-center text-2xl font-bold mb-6">Doğrulama kodunu yoxlayın</h2>
          <p className="text-center text-gray-600 mb-2">Autentifikatorda 6 rəqəmli kodu daxil edin.</p>
          
          <p className="text-center text-lg font-semibold text-red-600 mb-6">
            {formatTime(timer)}
          </p>

          <div className="flex justify-center mb-6 space-x-2">
            {code.map((digit, index) => (
              <input
                key={index}
                id={`code-${index}`}
                type="text"
                value={digit}
                maxLength="1"
                onChange={(e) => handleChange(e.target.value, index)}
                className="w-12 h-12 text-center text-2xl border border-gray-300 rounded focus:outline-none focus:border-green-500"
              />
            ))}
          </div>

          <div className="flex justify-center mb-4">
            <button
              type="button"
              className={`text-sm text-gray-600 hover:text-gray-800`}
              onClick={handleResend}
              disabled={resendTimer > 0}>

              {resendTimer > 0 ? `Kodun yenidən göndərilməsi ${formatTime(resendTimer)}` : "Kodu yenidən göndərin"}
            </button>
          </div>

          <button
            type="submit"
            className="w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            Təsdiq edin
          </button>
        </form>

        {showSuccessPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
          <div className="bg-white rounded-lg p-8 max-w-md w-[450px]">  
            <div className="text-center">
              <h2 className="text-2xl font-bold text-green-500 mb-4">
                Qeydiyyatdan keçdiyiniz üçün təşəkkür edirik!
              </h2>
                <p className="text-gray-600 mb-4 leading-relaxed">
                  Platformamızda satıcı olmaq üçün növbəti addımları müzakirə etmək üçün komandamız tezliklə sizinlə şəxsən əlaqə saxlayacaq. Səyahətinizə bizimlə başlamağınıza kömək etməkdən məmnunuq!
                </p>
                <p className="text-gray-600 mb-8">
                  Bu arada saytımızı tədqiq etməkdən və funksiyalarla tanış olmaqdan çəkinməyin.
                </p>
                <button
                  onClick={handleSuccessClose}
                  className="w-full border border-green-600	border-color: rgb(22 163 74); text-green-600	color: rgb(22 163 74); py-3 px-4 rounded-md "
                >
                  Bağla
                </button>
              </div>
            </div>
          </div>
        )}

        {showResendPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4">
            <div className="bg-white rounded-lg p-8 max-w-md w-full">
            <div className="bg-white rounded-lg p-8 max-w-md w-full flex flex-col items-center">  {/* Added flex-col and items-center */}
            <img style={{ height: "94px", width: "94px" }} src={resend} alt="" className="mb-4" /> 
              <div className="text-center">
                
                <h2 className="text-2xl font-bold text-black-500 mb-4">
                  Yeni kod göndərildi!
                </h2>
                <p className="text-gray-600 mb-4">
                E-poçtunuzu yoxlayın və 5 dəqiqə ərzində daxil edin. Almadınız? Spamı yoxlayın və ya yenidən cəhd edin.
                </p>
                <button
                  onClick={handleResendClose}
                  className="w-full border border-green-600	border-color: rgb(22 163 74); text-green-600	color: rgb(22 163 74); py-3 px-4 rounded-md "
                >
                  Bağla
                </button>
              </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyCodeSeller;

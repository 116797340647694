import React from "react";
import img from "../assets/images/boxProduct.png";

function Orders() {
  return (
    <div className="border border-[#747474] flex-1 rounded-lg  h-[450px] overflow-y-auto p-4 md:p-6">
       <div className="border-b mb-4 w-full">
        <div className="flex flex-col md:flex-row space-x-[120px] mb-4">
          <div className=" flex md:flex-col flex-row justify-between md:justify-center items-center  mb-4 ">
            <p className="mb-1 font-normal text-sm text-[#000000]">Order placed</p>
            <p className="font-normal text-sm text-[#545454]">01/01/2023</p>
          </div>
          <div className="flex md:flex-col flex-row justify-between md:justify-center items-center  mb-4 ">
            <p className="mb-1 font-normal text-sm text-[#000000]">Order number</p>
            <p className="font-normal text-sm text-[#545454]">#71717-1212</p>
          </div>
          <div className="flex md:flex-col flex-row justify-between md:justify-center items-center  mb-4 ">
            <p className="mb-1 font-normal text-sm text-[#000000]">Actual Price</p>
            <p className="font-normal text-sm text-[#545454]">17 ₼</p>
          </div>
          {/* <div className="flex md:flex-col flex-row justify-between md:justify-center items-center  mb-4 ">
            <p className="mb-1 font-normal text-sm text-[#000000]">Status</p>
            <p className="font-normal text-sm text-[#545454]">Send</p>
          </div> */}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center gap-4">
          <div className="w-[65px] h-[105px] flex-shrink-0">
            <img src={img} alt="Product" className="object-cover w-full h-full" />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-normal text-sm text-[#545454]">1 item</p>
            <p className="font-bold text-sm text-[#000000]">
              Gülmarka Natural Rose water 250 ML
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <div className="w-[65px] h-[105px] flex-shrink-0">
            <img src={img} alt="Product" className="object-cover w-full h-full" />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-normal text-sm text-[#545454]">1 item</p>
            <p className="font-bold text-sm text-[#000000]">
              Gülmarka Natural Rose water 250 ML
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <div className="w-[65px] h-[105px] flex-shrink-0">
            <img src={img} alt="Product" className="object-cover w-full h-full" />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-normal text-sm text-[#545454]">1 item</p>
            <p className="font-bold text-sm text-[#000000]">
              Gülmarka Natural Rose water 250 ML
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orders;

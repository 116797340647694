import React from "react";
import { Route, Routes } from "react-router-dom";
import SidebarRouting from "../components/SidebarRouting";
import AccountForm from "../components/AccountForm";
import Orders from "../components/Orders";
import Logout from "../components/Logout";

const Account = () => {
  return (
    <div className="min-h-screen flex flex-col px-4 ">
      <div className="flex flex-row  space-x-3 lg:space-x-6 max-w-screen-xl w-full mx-auto">
        {/* Sidebar */}
        <div className=" flex-shrink-0 mb-6 md:mb-0">
          <SidebarRouting />
        </div>

        {/* Main Content */}
        <div className=" w-3/4">
          <Routes>
            <Route path="/accountform" element={<AccountForm />} />
            <Route path="/orders" element={<Orders />} />
            {/* <Route path="/favorites" element={<Favorites />} /> */}
          
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Account;

import React, { useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai"; 
import store from "../../assets/images/store.png";
import { useContextApp } from "../../context/AppContext";

const ProductDetails = ({ productDetail, categoryName, rating }) => {
  const { addToCart } = useContextApp();
  const [quantity, setQuantity] = useState(1); 

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        i <= rating ? (
          <AiFillStar key={i} style={{ color: "red", fontSize: "20px" }} /> 
        ) : (
          <AiOutlineStar key={i} style={{ color: "red", fontSize: "20px" }} /> 
        )
      );
    }
    return stars;
  };

  const totalPrice = productDetail.price * quantity;

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setQuantity(value < 1 ? 1 : value); 
  };

  return (
    <div className="product-details">
      <h1 className="product-title">{productDetail.name}</h1>
      <div className="product-rating">
        <span className="stars flex">
          {renderStars(rating)} 
        </span>
        <span className="reviews">{rating}</span>
      </div>
      <p className="product-category">Category: {categoryName}</p>

      <div className="product-actions">
        <h1 style={{fontSize:"28px", fontWeight:"700"}} className="total-price">{totalPrice} ₼</h1> 
      </div>
      <div className="product-add-to-cart">
        <input
          type="number"
          value={quantity} 
          min="1"
          onChange={handleQuantityChange} 
        />
        <button
          className="btn add-to-cart"
          onClick={() => addToCart(productDetail.id, quantity)} 
        >
          <img className="btn-img-store" src={store} alt="" /> Add to cart
        </button>
      </div>
    </div>
  );
};

export default ProductDetails;

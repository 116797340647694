import React, { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';

function StoreProfileComponent() {
  const [ setProfileData] = useState(null);
  const [formData, setFormData] = useState({
    storeName: '',
    address: '',
    addressUrl: '',
    voen: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchStoreProfile = useCallback(async () => {
    setIsLoading(true);
    setError('');

    const accessToken = Cookies.get('accessToken');
    if (!accessToken) {
      setError('No access token found. Please log in again.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch('https://api.jumma.app/api/Profile/GetStoreProfile', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'accept': '*/*'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setProfileData(data);
      setFormData({
        storeName: data.storeName || '',
        address: data.location || '',
        addressUrl: data.addressUrl || '',
        voen: data.voen || '',
      });
    } catch (error) {
      console.error('Error fetching store profile:', error);
      setError(`Error fetching store profile: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [setProfileData]); // Empty dependency array ensures fetchStoreProfile doesn't change unless needed.

  useEffect(() => {
    fetchStoreProfile();
  }, [fetchStoreProfile]); // Include fetchStoreProfile as a dependency

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    const accessToken = Cookies.get('accessToken');
    if (!accessToken) {
      setError('No access token found. Please log in again.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch('https://api.jumma.app/api/Profile/UpdateStore', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
      }

      setSuccess('Store profile updated successfully!');
      fetchStoreProfile(); // Refresh the profile data
    } catch (error) {
      console.error('Error updating store profile:', error);
      setError(`Error updating store profile: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pt-[16px] pb-[31px] px-[34px] rounded-md border border-[#747474]">
      <h2 className="font-bold text-lg mb-[12px]">Mağaza Profili</h2>
      
      {isLoading && <p>Loading...</p>}
      {error && <p className="bg-red-100 text-red-700 border border-red-500 rounded-md px-4 py-3 mb-4">{error}</p>}
      {success && <p className="bg-green-100 text-green-700 border border-green-500 rounded-md px-4 py-3 mb-4">{success}</p>}
      
      
      <form className="space-y-4" onSubmit={handleSubmit}>
        <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px]">
          <input
            type="text"
            name="storeName"
            value={formData.storeName}
            onChange={handleChange}
            className="bg-transparent outline-none w-full"
            placeholder="Mağaza adı"
          />
          <legend className="block text-[#BABABA] text-[11px]">Mağaza adı</legend>
        </fieldset>

        <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px]">
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="bg-transparent outline-none w-full"
            placeholder="Ünvan"
          />
          <legend className="block text-[#BABABA] text-[11px]">Ünvan</legend>
        </fieldset>

        <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px]">
          <input
            id="addressUrl"
            name="addressUrl"
            type="text"
            value={formData.addressUrl}
            onChange={handleChange}
            className="bg-transparent outline-none w-full"
            placeholder="Ünvanın xəritə linkini daxil edin"
          />
          <legend className="block text-[#BABABA] text-[11px]">Xəritə</legend>
        </fieldset>

        <fieldset className="w-full border border-[#DFDFDF] rounded-md pl-[21px] py-[13px]">
          <input
            id="voen"
            name="voen"
            type="text"
            value={formData.voen}
            onChange={handleChange}
            className="bg-transparent outline-none w-full"
            placeholder="123456789876"
          />
          <legend className="block text-[#BABABA] text-[11px]">VÖEN</legend>
        </fieldset>

        <div className="mt-[19px]">
          <button
            type="submit"
            className="w-full bg-[#C7C7C7] text-[#898989] py-2 h-[52px] mt-[19px] text-[20px] text-center rounded-md font-bold"
            disabled={isLoading}
          >
            {isLoading ? "Updating..." : "Update Profile"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default StoreProfileComponent;
import logo from "../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import dashboardIcon from "../assets/images/dashboard.png";
import logoutIcon from "../assets/images/logout.png";
import accountIcon from "../assets/images/account.png";
import satici from "../assets/images/satici.png";
import mescid from "../assets/images/mescidd.png";
import users from "../assets/images/istifadeciler.png";
import kateqoriyalar from "../assets/images/kateqoriyalar.png";
import iane from "../assets/images/iane.png"
import "../components/Admin Panel/dashboard.css";
import Cookies from "js-cookie";
import { notification } from "../utils/helper";
export default function MainAdminSidebar() {
  const location = useLocation();
  const navigate = useNavigate();  // <-- Make sure this is defined at the top
  
  const path = location.pathname.split("/")[2];
  const activeContent = path || "dashboard";
  async function handleLogout() {
    const accessToken = Cookies.get("accessToken");
    try {
      const response = await fetch("https://api.jumma.app/api/Account/Logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, 
        },
      });

      if (response.ok) {
        Cookies.remove("accessToken", { path: "/" });
        Cookies.remove("refreshToken", { path: "/" });
        navigate("/login"); // Ensure navigate is properly used
        notification('Uğurla çıxış edildi');
      } else {
    
        console.error("Failed to log out:", response.statusText);
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  }


  
  return (
    <div className="side-bar">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>

      <div className="options">
        <nav className="menu">
          <ul>
            <li>
              <Link
                to="/mainAdmin-panel/anasehife"
                className={`menu-item ${
                  activeContent === "dashboard" ? "active" : ""
                }`}
              >
                <img src={dashboardIcon} alt="Dashboard Icon" />
                <p>Ana səhifə</p>
              </Link>
            </li>
            <li>
              <Link
                to="/mainAdmin-panel/hesab"
                className={`menu-item ${
                  activeContent === "hesab" ? "active" : ""
                }`}
              >
                <img src={accountIcon} alt="Account Icon" />
                <p>Hesab</p>
              </Link>
            </li>
            <li>
              <Link
                to="/mainAdmin-panel/satici"
                className={`menu-item ${
                  activeContent === "product" ? "active" : ""
                }`}
              >
                <img src={satici} alt="Product Icon" />
                <p>Satıcılar</p>
              </Link>
            </li>
            <li>
              <Link
                to="/mainAdmin-panel/mescid"
                className={`menu-item ${
                  activeContent === "orders" ? "active" : ""
                }`}
              >
                <img src={mescid} alt="Orders Icon" />
                <p>Məscidlər</p>
              </Link>
            </li>

            <li>
              <Link
                to="/mainAdmin-panel/istifadeciler"
                className={`menu-item ${
                  activeContent === "reviews" ? "active" : ""
                }`}
              >
                <img src={users} alt="Reviews Icon" />
                <p>İstifadəçilər</p>
              </Link>
            </li>
            <li>
              <Link
                to="/mainAdmin-panel/kateqoriyalar"
                className={`menu-item ${
                  activeContent === "reviews" ? "active" : ""
                }`}
              >
                <img src={kateqoriyalar} alt="Categories Icon" />
                <p>Kateqoriyalar</p>
              </Link>
            </li>
            <li>
              <Link
                to="/mainAdmin-panel/Iane"
                className={`menu-item ${
                  activeContent === "reviews" ? "active" : ""
                }`}
              >
                <img src={iane} alt="Categories Icon" />
                <p>Ianə</p>
              </Link>
            </li>
          </ul>
        </nav>
        {/* <Link to='/' className="logout-section">
          <button
            className="logout-button"
           
          >
            <img src={logoutIcon} alt="Logout Icon" className="logout-icon" />
            <p>Back to Main Page</p>
          </button>
        </Link> */}
     <div className="logout-section">
          <button
            className="logout-button"
            onClick={handleLogout}  // Correct usage of logout handler
          >
            <img src={logoutIcon} alt="Logout Icon" className="logout-icon" />
            <p>Log Out</p>
          </button>
        </div>
      </div>
    </div>
  );
}

import {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";

import { notification } from "../utils/helper";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [favorites, setFavorites] = useState(
    localStorage.getItem("favorites")
      ? JSON.parse(localStorage.getItem("favorites"))
      : []
  );
  const [baskets, setBaskets] = useState(
    localStorage.getItem("baskets")
      ? JSON.parse(localStorage.getItem("baskets"))
      : []
  );

  // FILTER INPUT ITEMS
  const [query, setQuery] = useState("");
  const searchFilterMainProducts = useCallback(() => {
    const newProducts = Array.isArray(products) ? products : [];
    return newProducts.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
  }, [products, query]);

  // API FOR FETCHING ALL DATA

  const fetchCartProducts = () => {
    return products.filter((product) => baskets.includes(product.id));
  };

  const fetchAllData = async () => {
    try {
      const response = await fetch(`https://api.jumma.app/ActiveProduct`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json-patch+json",

        },
      });

      if (!response.ok) {
        console.log(`Error: ${response.status} - ${response.statusText}`);
        return;
      }

      const data = await response.json();
      console.log("Raw API response:", data); // Debug log

      if (data && data.activeProducts) {
        setProducts(data.activeProducts);
        console.log("Fetched products:", data.activeProducts);
      } else {
        console.log("No activeProducts found in the response");
      }
    } catch (error) {
      console.log(`An error occurred while fetching data: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const saveCart = (items) => {
    localStorage.setItem("baskets", JSON.stringify(items));
    setBaskets(items);
  };

  const addToCart = (id) => {
    const product = products.find((product) => product.id === id);
    if (product) {
      if (!baskets.includes(id)) {
        const updatedBaskets = [...baskets, id];
        saveCart(updatedBaskets);
        fetchProducts(updatedBaskets);
        notification(`${product.name} adli mehsul sebete elave olundu`);
      }
    } else {
      notification(`Mehsul tapilmadi`);
    }
  };

  const fetchProducts = (updatedBaskets) => {
    const items = products.map((product) => {
      const find = updatedBaskets.includes(product.id);
      if (find) product.isBasket = true;
      else product.isBasket = false;
      return product;
    });
    setProducts(items);
  };

  const removeFromCart = (id) => {
    const updatedBaskets = baskets.filter((item) => item !== id);
    const product = products.find((product) => product.id === id);

    if (product) {
      saveCart(updatedBaskets);
      fetchProducts(updatedBaskets);
      notification(`${product.name} adli mehsul sebetden silindi`);
    } else {
      notification(`Mehsul tapilmadi`);
    }
  };

  const saveFavorites = (items) => {
    localStorage.setItem("favorites", JSON.stringify(items));
    setFavorites(items);
  };

  const addToFavorites = (id) => {
    const product = products.find((product) => product.id === id);
    if (product) {
      let updatedFavorites;
      if (favorites.find((item) => item.id === id)) {
        updatedFavorites = favorites.filter((item) => item.id !== id);
        notification(`${product.title} adli mehsul favoritsden cixarildi`);
      } else {
        updatedFavorites = [...favorites, product];
        notification(`${product.title} adli mehsul favoritse elave olundu`);
      }
      saveFavorites(updatedFavorites);
    } else {
      notification(`Mehsul tapilmadi`);
    }
  };

  const isFavorited = (id) => {
    return favorites.some((item) => item.id === id);
  };

  // GET PROFILE
  const getUserProfile = async () => {
    const accessToken = Cookies.get("accessToken");
  
    try {
      const response = await fetch("https://api.jumma.app/api/Profile/GetProfile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json"
        }
      });
  
      if (response.ok) {
        const userData = await response.json();
        return userData;
      } else {
        throw new Error("Failed to fetch profile data");
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  

 
  // Login Form Hook
  const loginForm = useForm();

  // Destructure necessary values from the form hooks

  const {
    register: loginUser,
    handleSubmit: handleLoginSubmit,
    formState: loginFormState,
  } = loginForm;

 
  const { errors: loginErrors } = loginFormState;

  const [showVerifyCode, setShowVerifyCode] = useState(false);
  const navigate = useNavigate();


 

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isNavigated, setIsNavigated] = useState(false);

  // Login handler
  const handleLogin = async (data) => {
    try {
      const loginData = {
        email: data.email,
        password: data.password,
      };

      const response = await fetch(`https://api.jumma.app/api/Account/Login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify(loginData),
      });

      if (response.ok) {
        const result = await response.json();
        const { accessToken, refreshToken } = result;

        setIsLoggedIn(true);
        setIsNavigated(true);
        Cookies.set("accessToken", accessToken, {
          path: "/",
          secure: true,
          sameSite: "Strict",
          expires: 1,
        });

        Cookies.set("refreshToken", refreshToken, {
          path: "/",
          secure: true,
          sameSite: "Strict",
          expires: 7,
        });

        const decodedToken = jwtDecode(accessToken);
        const role = getRoleFromToken(decodedToken);

        if (!isNavigated) {
          setIsNavigated(true);

          if (role === "SuperAdmin") {
            navigate("/mainAdmin-panel");
          } else if (role === "User") {
            navigate("/account/accountform");
          } else if (role === "Seller") {
            navigate("/admin-panel");
          }
        }
      } else if (response.status === 400) {
        const errorData = await response.json();
        if (errorData.message === "Email is not confirmed.") {
          toast.error("Email is not confirmed");
        } else {
          toast.error(errorData.message || "Login failed");
        }
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("An error occurred during login. Please try again.");
    }
  };

  // Token role extraction helper
  const getRoleFromToken = (tokenObject) => {
    // Check if any value in the token object is "User"
    for (const value of Object.values(tokenObject)) {
      if (typeof value === "string" && value === "User") {
        return value;
      }
    }

    // Find the first key that includes "role" and return its value if it exists
    const roleKey = Object.keys(tokenObject).find((key) =>
      key.includes("role")
    );
    return roleKey && typeof tokenObject[roleKey] === "string"
      ? tokenObject[roleKey]
      : null;
  };

  // Token refresh effect
  useEffect(() => {
    const refreshAccessToken = async () => {
      try {
        const refreshToken = Cookies.get("refreshToken");
        const accessToken = Cookies.get("accessToken");
  
        if (!refreshToken) {
          console.error("Refresh token is missing from cookies.");
          throw new Error("Refresh token is missing.");
        }
  
        const response = await fetch(`https://api.jumma.app/api/Account/RefreshToken`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ refreshToken }),
        });
  
        if (response.ok) {
          const result = await response.json();
          const { accessToken:newAccessToken, refreshToken: newRefreshToken } = result;
  
          // Set cookies with new tokens
          Cookies.set("accessToken", newAccessToken, { path: "/", secure: true, sameSite: "Strict", expires: 1 });
          Cookies.set("refreshToken", newRefreshToken, { path: "/", secure: true, sameSite: "Strict", expires: 7 });
  
          const decodedToken = jwtDecode(accessToken);
          const role = getRoleFromToken(decodedToken);
  
          if (!isNavigated) {
            setIsNavigated(true);
            if (role === "SuperAdmin") {
              navigate("/mainAdmin-panel");
            } else if (role === "User") {
              navigate("/account/accountform");
            } else if (role === "Seller") {
              navigate("/admin-panel");
            }
          }
  
          console.log("Tokens refreshed successfully.");
        } else {
          const errorData = await response.json();
          console.error("Token refresh failed:", errorData);
          throw new Error("Failed to refresh tokens");
        }
      } catch (error) {
        console.error("Error refreshing tokens:", error);
  
      }
    };
  
    refreshAccessToken();
  }, [navigate, isNavigated]);
  
  const handleLogout = () => {
    Cookies.remove("accessToken", { path: "/" });
    Cookies.remove("refreshToken", { path: "/" });
    setIsLoggedIn(false);
    setIsNavigated(false);
    navigate("/");

    toast.success("Logged out successfully.");
  };

  // NAVBAR FILTER PRODUCTS

  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const handleSelectedSubcategory = (name) => {
    setSelectedSubcategory(name);
  };

  const values = {
    products,
    addToCart,
    removeFromCart,
    baskets,
    fetchCartProducts,
    addToFavorites,
    favorites,
    isFavorited,
    // REGISTER
    showVerifyCode,
    setShowVerifyCode,
 
  
    // LOGIN
    loginUser,
    handleLoginSubmit,
    handleLogin,
    loginErrors,
    isLoggedIn,
    setIsLoggedIn,
    handleLogout,


    // NAVBAR
    selectedSubcategory,
    setSelectedSubcategory,
    handleSelectedSubcategory,
    searchFilterMainProducts,
    setQuery,
    query,
    getUserProfile,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export const useContextApp = () => useContext(AppContext);

import React, { useState } from 'react';
import searchIcon from "../assets/images/searchicon.png";

export default function MainAdminAddUser({
  showAddUserModal,
  editData,
  handleAddUserClick,
  handleCloseModal,
  handleSaveNewUser,
  handleEditChange,
}) {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    // You can add additional logic here to filter users based on the search query
  };

  return (
    <div className="main-admin-container">
      <div className="header-container" style={{ display: 'flex', flexDirection:"row",marginTop:"20px"}}>
        <div className="search-container">
          <div className="search-wrapper" style={{ display: 'flex', alignItems: 'center', border: '1px solid #ccc', borderRadius: '4px', padding: '5px 10px', width: '183px' }}>
            <img src={searchIcon} alt="Search Icon" className="search-icon" style={{ width: '20px', marginRight: '10px' }} />
            <input
              type="text"
              placeholder="Axtar"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{
                border: 'none',
                outline: 'none',
                width: '100%',
                fontSize: '14px',
                padding: '5px',
                backgroundColor: 'inherit',
              }}
            />
          </div>
        </div>
        <button
          className="hidden md:block whitespace-nowrap  ml-[10px] w-[183px] h-[40px] rounded-[4px] bg-blue-500 text-white"
          onClick={handleAddUserClick}
        >
          Istifadəçi Elavə Et
        </button>

      </div>

      {showAddUserModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-modal" onClick={handleCloseModal}>
              ×
            </button>
            <h2>Add New User</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveNewUser();
              }}
            >
              <div>
                <label>First Name:</label>
                <input
                  type="text"
                  name="firstName"
                  value={editData.firstName}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Last Name:</label>
                <input
                  type="text"
                  name="lastName"
                  value={editData.lastName}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  value={editData.email}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Phone Number:</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={editData.phoneNumber}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Password:</label>
                <input
                  type="password"
                  name="password"
                  value={editData.password}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Confirm Password:</label>
                <input
                  type="password"
                  name="rePassword"
                  value={editData.rePassword}
                  onChange={handleEditChange}
                />
              </div>
              <div className="modal-buttons">
                <button
                  style={{ backgroundColor: "#05AC58", width: "350px" }}
                  type="submit"
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
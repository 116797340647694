import { Link, NavLink } from "react-router-dom";
import { IoPersonOutline } from "react-icons/io5";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { MdOutlineLogout } from "react-icons/md";
import { useState } from "react";
import { useContextApp } from "../context/AppContext";

const SidebarRouting = () => {
  const [showModal, setShowModal] = useState(false);
  const { handleLogout } = useContextApp();
  const sidebarItems = [
    {
      id: 1,
      to: "/account/accountform",
      icon: <IoPersonOutline className="w-6 h-6" />,
      label: "Hesab",
    },
    {
      id: 2,
      to: "/account/orders",
      icon: <HiOutlineShoppingBag className="w-6 h-6" />,
      label: "Sifarişlər",
    },
    // {
    //   id: 3,
    //   to: "/account/favorites",
    //   icon: <IoMdHeartEmpty className="w-6 h-6" />,
    //   label: "Favorites",
    // },
    // {
    //   id: 4,
    //   to: "/account/becomeSeller",
    //   icon: <FiGrid className="w-6 h-6" />,
    //   label: "Become a seller",
    // },
    {
      id: 5,
      icon: <MdOutlineLogout className="w-6 h-6" />,
      label: "Çıxış et",
      action: () => setShowModal(true),
    },
  ];

  return (
    <div className="w-full max-w-xs lg:w-40 border-r border-[#9A9A9A] h-full p-4 lg:py-6 lg:px-3">
      <ul className="space-y-4">
        {sidebarItems.map(({ id, to, icon, label, action }) => (
          <li key={id}>
            {to ? (
              <NavLink
                to={to}
                className={({ isActive }) =>
                  `flex items-center space-x-3 ${
                    isActive ? "text-[#3168E6]" : "text-gray-500"
                  }`
                }
              >
                <div className="w-6 h-6">{icon}</div>
                <span className="text-sm font-normal hidden sm:block">
                  {label}
                </span>
              </NavLink>
            ) : (
              <button
                onClick={action}
                className="flex items-center space-x-3 text-gray-500 hover:text-[#3168E6]"
              >
                <div className="w-6 h-6">{icon}</div>
                <span className="text-sm font-normal hidden sm:block">
                  {label}
                </span>
              </button>
            )}
          </li>
        ))}
      </ul>

      {/* POPUP */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="w-full max-w-sm mx-auto bg-white p-6 rounded-md shadow-md text-center">
            <p className="text-lg font-medium mb-6">Çıxmaq istəyirsiniz?</p>
            <div className="space-y-4">
              <Link to="/">
                <button
                  onClick={() => {
                    setShowModal(false);
                    handleLogout();
                  }}
                  className="w-full py-2 bg-red-600 text-white rounded-md font-semibold hover:bg-red-700"
                >
                  Çıxış edin
                </button>
              </Link>

              <button
                onClick={() => setShowModal(false)}
                className="w-full py-2 border border-green-500 text-green-500 rounded-md font-semibold hover:bg-green-100"
              >
                Arxaya qayıt
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarRouting;

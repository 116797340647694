import React, { useState } from "react";
import { Link } from "react-router-dom";
import location from "../../assets/images/location.png";
import plus from "../../assets/images/plus.png";
import user from "../../assets/images/user.png";
import phone from "../../assets/images/phone.png";
import close from "../../assets/images/close.png";
import shampoo from "../../assets/images/shampoo.png";
import "./address.css";

const productData = {
  id: 1,
  name: "Naturalive Tüm Saç Tipleri İçin Şampuan 500ml",
  image: shampoo,
  basePrice: 19,
  description: "A natural shampoo suitable for all hair types.",
  storeName: "Magazanin adi",
  shippingThreshold: 100,
  shippingFeeMessage: "DO NOT PAY SHIPPING FEE!",
};

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    address: "",
    district: "",
    city: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newAddress = { ...formData };
    setAddresses((prevAddresses) => [...prevAddresses, newAddress]);
    setIsModalOpen(false);
    setFormData({
      name: "",
      surname: "",
      phoneNumber: "",
      address: "",
      district: "",
      city: "",
    });
  };

  const calculateTotalPrice = () => {
    return (productData.basePrice).toFixed(2); // No longer multiplied by quantity
  };

  const calculateTax = (price) => {
    return (price * 0.2).toFixed(2);
  };

  const calculateTotalWithTax = (price) => {
    return (price + parseFloat(calculateTax(price))).toFixed(2);
  };

  const totalPrice = calculateTotalPrice();
  const totalWithTax = calculateTotalWithTax(parseFloat(totalPrice));

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="address-container">
      <div className="right-container-address">
        <div className="small">
          <img src={location} alt="" />
          <p>Addresə Çatdır</p>
        </div>
        <div className="big" id="big-container">
          <div className="small-in-big" onClick={openModal}>
            <img src={plus} alt="" />
            <p>Yani addres əlavə et</p>
          </div>
          {addresses.map((address, index) => (
            <div key={index} className="address-card">
              <div className="address-info">
                <div className="first-row">
                  <div className="address-header">
                    <img src={user} alt="" />
                    <p>
                      {address.name} {address.surname}
                    </p>
                  </div>
                  <div className="phone-number">
                    <img src={phone} alt="" />
                    <p>{address.phoneNumber}</p>
                  </div>
                </div>
                <p>{address.address}</p>
                <p>
                  {address.district}/{address.city}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="left-container-address">
        <div className="order-summary">
          <h2>Sifariş Xülasəsi</h2>
          <div className="item">
            <span>Ara Toplam</span>
            <span>{totalPrice} ₼</span>
          </div>
          <div className="item">
            <span>KDV</span>
            <span>{calculateTax(parseFloat(totalPrice))} ₼</span>
          </div>
          <div className="item last-item">
            <span>KDV Dahil</span>
            <span>{totalWithTax} ₼</span>
          </div>
          <div className="total">
            <span>Toplam</span>
            <span style={{ fontWeight: "700" }}>{totalWithTax} ₼</span>
          </div>
          <Link to="/add-card" className="checkout">
            <button>İNDİ YOXLA</button>
          </Link>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="f-line">
              <h3 style={{ fontWeight: "700" }} className="title">
                Yeni address əlavə et
              </h3>
              <span className="close" onClick={closeModal}>
                <img src={close} alt="" />
              </span>
            </div>
            <div className="separator"></div>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                id="name"
                placeholder="Name*"
                value={formData.name}
                onChange={handleInputChange}
              />
              <input
                type="text"
                id="surname"
                placeholder="Surname*"
                value={formData.surname}
                onChange={handleInputChange}
              />
              <input
                type="text"
                id="phoneNumber"
                placeholder="Phone number*"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
              <input
                type="text"
                id="address"
                placeholder="Address*"
                value={formData.address}
                onChange={handleInputChange}
              />
              <input
                type="text"
                id="district"
                placeholder="District*"
                value={formData.district}
                onChange={handleInputChange}
              />
              <input
                type="text"
                id="city"
                placeholder="City*"
                value={formData.city}
                onChange={handleInputChange}
              />
              <button type="submit">Saxla</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;

import React from "react";
import Services from "../../components/Services";
import IaneCard from "../../components/donation add card/ianeCard"; 

const IanePage = () => {
  return (
    <div className="w-full max-w-screen-xl mt-4 space-y-8">
      <IaneCard /> 
      <Services />
    </div>
  );
};

export default IanePage;

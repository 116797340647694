// import google from "../assets/images/google.svg";
// import facebook from "../assets/images/facebook.svg";
// import twitter from "../assets/images/twitter.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import VerifyCode from "../components/VerifyCode";
import { BsGenderMale } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useContextApp } from "../context/AppContext";
import { useState } from "react";
import Agreement from "./Agreement";
import { useForm } from "react-hook-form";

const Register = () => {
  const { showVerifyCode, setShowVerifyCode } = useContextApp();
  const [isOpen, setIsOpen] = useState(false);
  const [acceptAgreement, setAcceptAgreement] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const registerForm = useForm({
    defaultValues: {
      gender: 0,
      phoneNumber: "+994 ",
    },
  });
  const {
    register: registerUser,
    handleSubmit: handleRegisterSubmit,
    formState: registerFormState,
    watch: watchRegister,
  } = registerForm;
  const handleRegister = async (data) => {
    //phone number qarsisinda + isaresinin elave edilmesi ucun
    const formatPhoneNumber = (number) => {
      const digitsOnly = number.replace(/\D/g, "");
      const countryCodeLength =
        digitsOnly.length > 10 ? digitsOnly.length - 9 : 0;
      const countryCode = digitsOnly.slice(0, countryCodeLength);
      const restOfNumber = digitsOnly.slice(countryCodeLength);
      const formatted = `+${countryCode} ${restOfNumber.slice(
        0,
        2
      )} ${restOfNumber.slice(2, 5)} ${restOfNumber.slice(
        5,
        7
      )} ${restOfNumber.slice(7, 9)}`;
      return formatted.trim();
    };

    const requestBody = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: formatPhoneNumber(data.phoneNumber),
      password: data.password,
      rePassword: data.rePassword,
      gender: Number(data.gender),
    };

    try {
      const response = await fetch(
        `https://api.jumma.app/api/Account/Register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log('Response data:', responseData);
    
        // Make sure you are checking the correct property from the response
        return responseData.success || response.status === 200;
      } else {
        const errorData = await response.json();
        if (
          response.status === 400 ||
          errorData.message === "A user with this email already exists."
        ) {
          if (errorData.message === "A user with this email already exists.") {
            toast.error("The email is already in use");
          }
      
        } else {
          toast.error(`Error: ${errorData.message || "Something went wrong"}`);
        }
      }
    } catch (error) {
      // Network error or unexpected error handling
      if (error.response && error.response.status === 400) {
        toast.error("The email is already in use");
      } else {
        toast.error(error);
      }
    }
    return false;
  };
  const { errors: registerErrors } = registerFormState;
  const firstName = watchRegister("firstName");
  const email = watchRegister("email");
  const togglePopup = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  async function onSubmit(data) {
    setIsLoading(true);
    console.log("Submitting registration data:", data);

    const { success, message } = await handleRegister(data);
    console.log("Registration success:", success);
    console.log("Registration message:", message); 
  
    if (!success) {
      console.log("Setting showVerifyCode to true");
      setShowVerifyCode(true)
    } else {
      console.log("Registration failed:", message); 
      setIsLoading(false);
    }
  }
  

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="w-[475px] bg-[#FFFFFF] p-[16px] shadow-lg mx-auto mt-[38px] rounded-[4px]">
        <ToastContainer />
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <p className="font-normal text-[28px] text-[#0c0404] mb-[8px]">
              Hesab yaradın!
            </p>
            <p className="font-normal text-[16px] text-[#4b4b4b] mb-[30px]">
              Hesab yaradın! Zəhmət olmasa, məlumatlarınızı daxil edin.
            </p>
          </div>
          <div className="size-[113px] mb-4">
            <img src={logo} alt="logo" className="object-cover size-full" />
          </div>
        </div>

        <form
          onSubmit={handleRegisterSubmit(onSubmit)}
          className="flex flex-col"
        >
          {/* First Name Field */}
          <div className="w-full h-[52px] rounded-[4px] border border-[#DFDFDF] mb-[20px]">
            <input
              type="text"
              {...registerUser("firstName", {
                required: "Ad mütləqdir",
                minLength: {
                  value: 3,
                  message: "Ad ən azı 3 simvoldan ibarət olmalıdır",
                },
              })}
              placeholder="Ad*"
              className="w-full h-full bg-transparent border pl-[25px] font-normal text-[14px] text-[#545454] outline-none"
            />
            <p className="text-red-500 font-semibold text-sm mt-1 mb-2">
              {registerErrors.firstName?.message}
            </p>
          </div>

          {/* Last Name Field */}
          <div className="w-full h-[52px] rounded-[4px] border border-[#DFDFDF] mb-[20px]">
            <input
              type="text"
              {...registerUser("lastName", {
                required: "Soyad mütləqdir",
                minLength: {
                  value: 3,
                  message: "Soyad ən azı 3 simvoldan ibarət olmalıdır",
                },
              })}
              placeholder="Soyad*"
              className="w-full h-full pl-[25px] font-normal text-[14px] bg-transparent border text-[#545454] outline-none border-black"
            />
            <p className="text-red-500 font-semibold text-sm mt-1 mb-2">
              {registerErrors.lastName?.message}
            </p>
          </div>

          {/* Email Field */}
          <div className="w-full h-[52px] rounded-[4px] border border-[#DFDFDF] mb-[20px]">
            <input
              type="email"
              placeholder="Email*"
              {...registerUser("email", {
                required: "Email mütləqdir",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Düzgün email adresi daxil edin",
                },
              })}
              className="w-full h-full pl-[25px] font-normal bg-transparent border text-[14px] text-[#545454] outline-none"
            />
            <p className="text-red-500 font-semibold text-sm mt-1 mb-2">
              {registerErrors.email?.message}
            </p>
          </div>

          {/* Phone Number Field */}
          <div className="w-fullh-[52px] rounded-[4px]   mb-[13px]">
            <PhoneInput
              onlyCountries={["az"]}
              country={"az"}
              value={watchRegister("phoneNumber") || ""}
              onChange={(value) => registerForm.setValue("phoneNumber", value)}
              inputProps={{
                name: "phoneNumber",
                // required: true,
              }}
              placeholder="+994 (xx) xxx xx xx"
              inputStyle={{
                width: "100%",
                height: "52px",
                border: "1px solid #DFDFDF",
              }}
              containerStyle={{ width: "100%" }}
              inputClass="w-full h-full"
              buttonStyle={{
                border: "none",
                backgroundColor: "inherit",
              }}
            />
            <p className="text-red-500 font-semibold text-sm mt-1 mb-2">
              {registerErrors.phoneNumber?.message}
            </p>
          </div>

          {/* Password Field */}
          <div className="w-full h-[52px] rounded-[4px] border border-[#DFDFDF] mb-[20px]">
            <input
              type="password"
              placeholder="Şifrə*"
              {...registerUser("password", {
                required: "Şifrə mütləqdir",
                minLength: {
                  value: 8,
                  message: "Şifrə ən azı 8 simvoldan ibarət olmalıdır",
                },
              })}
              className="w-full h-full pl-[25px] font-normal text-[14px] text-[#545454] outline-none bg-transparent"
            />
            <p className="text-red-500 font-semibold text-sm mt-1 mb-2">
              {registerErrors.password?.message}
            </p>
          </div>

          {/* Re-Password Field */}
          <div className="w-full h-[52px] rounded-[4px] border border-[#DFDFDF] mb-[20px]">
            <input
              type="password"
              placeholder="Şifrəni təsdiq edin*"
              {...registerUser("rePassword", {
                required: "Şifrənizi təsdiq edin",
                validate: (value) =>
                  value === watchRegister("password") || "Şifrələr uyğun deyil",
              })}
              className="w-full h-full pl-[25px] font-normal text-[14px] text-[#545454] outline-none bg-transparent border"
            />
            <p className="text-red-500 font-semibold text-sm mt-1 mb-2">
              {registerErrors.rePassword?.message}
            </p>
          </div>

          {/* Gender Selection */}
          <div className="border w-full h-[52px] rounded-[4px] border-[#DFDFDF] mb-[20px]">
            <div className="flex items-center pt-[13px] justify-between w-[320px] mx-auto mb-[20px]">
              <BsGenderMale className="text-[24px]" />
              <label className="flex items-center">
                <input
                  type="radio"
                  value={0}
                  {...registerUser("gender", {
                    valueAsNumber: true,
                  })}
                  className="mr-2 text-[#9D9D9D] font-normal text-[16px]"
                />
                Kişi
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  value={1}
                  {...registerUser("gender", {
                    valueAsNumber: true,
                  })}
                  className="mr-2 text-[#9D9D9D] font-normal text-[16px]"
                />
                Qadın
              </label>
              {registerErrors.gender && (
                <p className="text-red-500">{registerErrors.gender.message}</p>
              )}
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <label className="flex items-center mb-2">
              <input type="checkbox" className="mr-2 text-[#545454]" />
              Sayta abunə olmaq və bildirişlər almaq istəyirəm.
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                className="mr-2 text-[#545454]"
                required
                checked={acceptAgreement}
                onChange={() => setAcceptAgreement(!acceptAgreement)}
              />
              <span
                onClick={togglePopup}
                className="cursor-pointer underline text-blue-500"
              >
                Üzvlük müqaviləsini qəbul edirəm
              </span>
            </label>
            <p className="text-red-500 hidden">Müqaviləni qəbul etməlisiniz.</p>
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full bg-[#05ac58] rounded-[4px] text-center font-bold text-[20px] mt-3 text-white py-[10px] mb-[33px] flex items-center justify-center ${
              isLoading ? "opacity-75 cursor-not-allowed" : ""
            }`}
          >
            {isLoading ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Yüklənir...
              </>
            ) : (
              "Qeydiyyat"
            )}
          </button>
        </form>

        {/* <div className="flex items-center justify-center">
          <div className="border-t border-[#dfdfdf] w-[129px]"></div>
          <div className="mx-2">or sign up with</div>
          <div className="border-t border-[#dfdfdf] w-[129px]"></div>
        </div>

        <div className="flex items-center justify-between w-[150px] mb-[30px] mx-auto mt-[23px]">
          <a
            href="https://www.google.com"
            target="_blank"
            rel="noopener noreferrer"
            className="mr-[8px]"
          >
            <img src={google} alt="google" />
          </a>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="mr-[8px]"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="mr-[8px]"
          >
            <img src={twitter} alt="twitter" />
          </a>
        </div> */}

        <p className="flex justify-center font-normal text-[#6e6e6e] text-[14px]">
          Artıq hesabınız varmı?{" "}
          <Link to="/login" className="ml-[6px] text-[#05ac58]">
            Daxil olun
          </Link>
        </p>

        {showVerifyCode && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-xl">
              <VerifyCode
                firstName={firstName}
                email={email}
                onClose={() => {
                  console.log("Closing VerifyCode component");
                  setShowVerifyCode(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="w-[475px] mx-auto rounded-[4px] mt-[22px] border-[#05AC58] border text-center py-[10px] bg-[#FFFFFF]">
        <Link to="/tezlikle" className="text-[#05AC58] font-bold text-[20px]">
          Ana Səhifəyə keç
        </Link>
      </div>
      {isOpen && (
        <Agreement
          onClose={() => setIsOpen(false)}
          onAccept={() => setAcceptAgreement(true)}
        />
      )}
    </div>
  );
};

export default Register;

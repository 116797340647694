import React, { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard";
import { useContextApp } from "../../context/AppContext";
import { useParams } from "react-router-dom";

export default function MehsullarPage() {
  const { selectedSubcategory, products, query } = useContextApp();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const { slug } = useParams();

  useEffect(() => {
    const allProducts = Array.isArray(products) ? products : [];

    const applyFilters = (productsToFilter) => {
      let result = productsToFilter;

     
      if (slug && slug.startsWith(':')) {
        const cleanSlug = slug.substring(1);
        result = result.filter(
          (item) => item.subCategoryName.toLowerCase() === decodeURIComponent(cleanSlug).toLowerCase()
        );
      }

      if (selectedSubcategory && (!slug || !slug.includes(selectedSubcategory))) {
        result = result.filter(
          (item) => item.subCategoryName.toLowerCase() === selectedSubcategory.toLowerCase()
        );
      }

     
      if (query) {
        result = result.filter((item) =>
          item.name.toLowerCase().includes(query.toLowerCase())
        );
      }

      return result;
    };

    const filteredResult = applyFilters(allProducts);
    setFilteredProducts(filteredResult);

  }, [selectedSubcategory, products, slug, query]);

  return (
    <div className="py-10 px-4">
      <div className="border-b pb-[72px] flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {filteredProducts.map((item) => (
          <ProductCard key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
}
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const VerifyCode = ({ firstName, email }) => {
  const [code, setCode] = useState(Array(6).fill(""));
  const [timer, setTimer] = useState(300); 
  const [resendCooldown, setResendCooldown] = useState(0); 
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const popupRef = useRef(null);

  useEffect(() => {
    let countdown;
    if (isTimerRunning && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(countdown);
      setIsTimerRunning(false);
    }

    return () => {
      clearInterval(countdown);
    };
  }, [timer, isTimerRunning]);

  useEffect(() => {
    let cooldownTimer;
    if (resendCooldown > 0) {
      cooldownTimer = setInterval(() => {
        setResendCooldown((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      clearInterval(cooldownTimer);
    };
  }, [resendCooldown]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const handleChange = (value, index) => {
    const newCode = [...code];
    newCode[index] = value.slice(-1);
    setCode(newCode);

    if (value && index < 5) {
      document.getElementById(`code-${index + 1}`).focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const verificationCode = code.join("");
    console.log("Verification Code:", verificationCode);

    try {
      const response = await fetch(`https://api.jumma.app/api/Account/Verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify({
          email: email,
          confirmationCode: verificationCode,
        }),
      });

      if (response.ok) {
        setShowPopup(true);
        toast.success("Verification successful!");
        navigate('/login');
      } else {
        const errorData = await response.json();
        console.error(
          `Verification failed: ${errorData.message || "Unknown error"}`
        );
        toast.error(
          `Verification failed: ${errorData.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to verify code");
    }
  };

  const handleResend = async () => {
    if (resendCooldown > 0) {
      toast.warning(`Please wait ${resendCooldown} seconds before resending.`);
      return;
    }

    setCode(Array(6).fill(""));
    setTimer(300); 
    setIsTimerRunning(true);
    setResendCooldown(120); 
    setShowPopup(false);

    try {
      const response = await fetch(
        `https://api.jumma.app/api/Account/ResendCode`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json-patch+json",
          },
          body: JSON.stringify({ email, firstName }),
        }
      );

      if (response.ok) {
        toast.success("Verification code resent successfully");
      } else {
        const errorData = await response.json();
        console.error(
          `Failed to resend code: ${errorData.message || "Unknown error"}`
        );
        toast.error(
          `Failed to resend code: ${errorData.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to resend code");
    }
  };

  return (
    <div className="flex items-center justify-center bg-gray-100">
      <div className="w-full max-w-2xl">
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 relative"
        >
          <h2 className="text-center text-2xl font-bold mb-6">
            Doğrulama kodunu yoxlayın
          </h2>
          <p className="text-center text-gray-600 mb-2">
            Autentifikatorda 6 rəqəmli kodu daxil edin.
          </p>
          <p className="text-center text-lg font-semibold text-red-600 mb-6">
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </p>

          <div className="flex justify-center mb-6 space-x-2">
            {code.map((digit, index) => (
              <input
                key={index}
                id={`code-${index}`}
                type="text"
                value={digit}
                maxLength="1"
                onChange={(e) => handleChange(e.target.value, index)}
                className="w-12 h-12 text-center text-2xl border border-gray-300 rounded focus:outline-none focus:border-green-500"
              />
            ))}
          </div>
          <div className="flex justify-center mb-4">
            <button
              type="button"
              className={`text-sm text-gray-600 hover:text-gray-800 ${
                resendCooldown > 0 && "cursor-not-allowed opacity-50"
              }`}
              onClick={handleResend}
              disabled={resendCooldown > 0}
            >
              {resendCooldown > 0
                ? `Resend in ${resendCooldown}s`
                : "Kodu yenidən göndərin"}
            </button>
          </div>
          <button
            type="submit"
            className="w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            Təsdiq edin
          </button>

          {showPopup && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div ref={popupRef} className="bg-white p-6 rounded-lg shadow-lg text-center">
                <div className="flex justify-center items-center mb-4">
                  <svg
                    className="h-12 w-12 text-green-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4"
                    />
                  </svg>
                </div>
                <p className="text-2xl font-semibold mb-2">Doğrulanıb</p>
                <p className="text-gray-600">Hesabınız uğurla təsdiqləndi</p>
                <button
                  onClick={() => setShowPopup(false)}
                  className="mt-4 w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded focus:outline-none"
                >
                  Bağlayın
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default VerifyCode;